import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { checkUserExists } from '../api'; // Import the checkUserExists function from api.js
import styles from './ClientLogin.module.css';
import logo from '../assets/logos/FerociousMEDIALogo_mark-01.png';

const ClientLogin = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isResetPassword, setIsResetPassword] = useState(false);
    const [rememberMe, setRememberMe] = useState(true);
    const [storedUser, setStoredUser] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        // Check if the user is already logged in
        const user = JSON.parse(localStorage.getItem('user') || sessionStorage.getItem('user'));
        if (user && user.token) {
            setStoredUser(user);
            setIsModalOpen(true);
        }

        const createWave = (canvasId, maxAmplitude, length, frequency, y, x, color, lineWidth) => {
            const canvas = document.getElementById(canvasId);

            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;

            class Wave {
                constructor(
                    canv,
                    maxAmplitude,
                    length,
                    frequency,
                    y,
                    x,
                    color,
                    lineWidth
                ) {
                    this.canvas = canv;
                    this.ctx = this.canvas.getContext('2d');
                    this.maxAmplitude = maxAmplitude;
                    this.amplitude = maxAmplitude;
                    this.length = length;
                    this.frequency = frequency;
                    this.increment = 0;
                    this.y = y;
                    this.x = x;
                    this.color = color;
                    this.lineWidth = lineWidth;

                    this.frameCallback = () => {
                        this.draw(this.ctx);
                        requestAnimationFrame(this.frameCallback);
                    };
                }

                draw(c) {
                    c.clearRect(0, 0, this.canvas.width, this.canvas.height);
                    c.save();
                    c.translate(this.x, this.canvas.height / 2);
                    c.rotate(100 * Math.PI / 180);
                    c.translate(-this.canvas.height / 2, -this.canvas.width / 2);

                    c.beginPath();
                    c.strokeStyle = this.color;
                    c.lineWidth = this.lineWidth;

                    c.moveTo(0, this.y);

                    for (let i = 0; i < this.canvas.width * 2; i += 1) {
                        c.lineTo(
                            i,
                            this.y + Math.sin(i / this.length + this.increment) * this.amplitude,
                        );
                    }

                    c.stroke();
                    c.closePath();

                    c.restore();

                    this.amplitude = Math.sin(this.increment) * this.maxAmplitude;
                    this.increment += this.frequency / 1000;
                }

                animate() {
                    this.frameCallback();
                }
            }

            const wave = new Wave(canvas, maxAmplitude, length, frequency, y, x, color, lineWidth);
            wave.animate();
        };

        createWave('canvas1', 100, 140, 2, 1700, 900, '#999', 12);
        createWave('canvas2', 100, 140, 2.4, 1800, 2500, '#999', 12);

    }, [navigate]);

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await checkUserExists(email);
            if (response.data.exists) {
                const auth = getAuth();
                const userCredential = await signInWithEmailAndPassword(auth, email, password);

                const user = {
                    name: userCredential.user.displayName,
                    email: userCredential.user.email,
                    token: await userCredential.user.getIdToken(),
                };

                if (rememberMe) {
                    localStorage.setItem('user', JSON.stringify(user));
                } else {
                    sessionStorage.setItem('user', JSON.stringify(user));
                }

                const companyIds = response.data.companyIds || [];
                console.log('Company IDs:', companyIds);

                if (companyIds.length === 1) {
                    navigate(`/client/${companyIds[0].url}`);
                } else {
                    navigate('/client/select-company', { state: { email } });
                }
            } else {
                setError('Invalid username or password. Please check your email for login information.');
            }
        } catch (error) {
            console.error('Error during login:', error);
            setError('Failed to login. Please check your credentials.');
        }
    };

    const handleResetPassword = async (e) => {
        e.preventDefault();
        try {
            const response = await checkUserExists(email);
            if (response.data.exists) {
                const auth = getAuth();
                await sendPasswordResetEmail(auth, email);
                setError('Password reset email sent!');
            } else {
                setError('Email not found. Please check your email and try again.');
            }
        } catch (error) {
            console.error('Error during password reset:', error);
            setError('Failed to send password reset email.');
        }
    };

    const handleModalLogin = () => {
        setIsModalOpen(false);
        navigate('/client/select-company', { state: { email: storedUser.email } });
    };

    const handleModalClose = () => {
        setStoredUser(null);
        setIsModalOpen(false);
    };

    return (
        <div className={styles.loginContainer}>
            <canvas id="canvas1"></canvas>
            <canvas id="canvas2"></canvas>
            <div className={styles.loginBox}>
                <img src={logo} alt="Ferocious Media" className={styles.logo} />
                <h1 className={styles.clientLoginTitle}>Client Login</h1>
                <p>Welcome to the Ferocious Client Information Portal!<br />
                {isResetPassword ? 'Enter your email to reset password' : 'Login to your account below'}</p>
                <form onSubmit={isResetPassword ? handleResetPassword : handleLogin}>
                    <input
                        type="email"
                        placeholder="Enter email..."
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className={styles.input}
                        required
                    />
                    {!isResetPassword && (
                        <>
                            <input
                                type="password"
                                placeholder="Enter password..."
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className={styles.input}
                                required
                            />
                            <div className={styles.checkboxContainer}>
                                <div className={styles.checkboxOption}>
                                    <input
                                        type="checkbox"
                                        id="rememberMe"
                                        checked={rememberMe}
                                        onChange={(e) => setRememberMe(e.target.checked)}
                                    />
                                    <label htmlFor="rememberMe">Remember Me</label>
                                </div>
                            </div>
                        </>
                    )}
                    <button type="submit" className={styles.loginButton}>
                        {isResetPassword ? 'Reset Password' : 'Login'}
                    </button>
                </form>
                {error && <p className={styles.error}>{error}</p>}
                <p className={styles.signupText}>
                    {isResetPassword ? (
                        <span onClick={() => setIsResetPassword(false)}>Back to Login</span>
                    ) : (
                        <span onClick={() => setIsResetPassword(true)}>Need to reset password?</span>
                    )}
                </p>
            </div>

            {isModalOpen && (
                <div className={styles.modalOverlay}>
                    <div className={styles.modal}>
                        <h2>Log in as {storedUser?.name}?</h2>
                        <div className={styles.modalButtons}>
                            <button onClick={handleModalLogin}>Yes</button>
                            <button onClick={handleModalClose}>No</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ClientLogin;
