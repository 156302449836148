// src/components/Footer.js
import React from 'react';
import styles from './Footer.module.css';
import logo from '../assets/logos/FerociousMEDIALogo_V_Knockout.png';

const Footer = ({ clientName }) => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className={styles.footer}>
            <img src={logo} alt="Ferocious Media Logo" className={styles.footerLogo} />
            <p>&copy; {currentYear} Ferocious Media | Built by Ferocious specifically for {clientName}</p>
        </footer>
    );
};

export default Footer;
