import React, { useState, useEffect } from 'react';
import styles from './AddUsers.module.css'; // Import the CSS module for AddUsers
import NavBar from './NavBar'; // Import NavBar component
import CustomDropdown from './CustomDropdown'; // Import CustomDropdown component
import { getUsers, addUser, deleteUser, updateUserType, createActivityLog } from '../api'; // Import the new API functions

const AddUsers = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [userType, setUserType] = useState('Account Manager'); // Default user type
    const [users, setUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null);
    const [userEmail, setUserEmail] = useState('');

    useEffect(() => {
        // Fetch users from the backend
        const fetchUsers = async () => {
            try {
                const response = await getUsers();
                setUsers(response.data);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        fetchUsers();

        // Get user email from local storage
        const storedUser = JSON.parse(localStorage.getItem('user'));
        if (storedUser && storedUser.email) {
            setUserEmail(storedUser.email);
        }
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newUser = { name, email, userType };

        try {
            const response = await addUser(newUser);
            setUsers([...users, response.data]);
            setName('');
            setEmail('');
            setUserType('Account Manager');
            
            // Log the addition of a new user
            await createActivityLog({
                user: userEmail,
                action: `Added ${name} (${email}) as ${userType}`,
                date: new Date().toISOString()
            });
        } catch (error) {
            console.error('Error adding user:', error);
        }
    };

    const handleUserTypeChange = async (index, newUserType) => {
        const updatedUsers = [...users];
        const oldUserType = updatedUsers[index].userType;
        updatedUsers[index].userType = newUserType;
        setUsers(updatedUsers);

        // Update the user type in the backend
        try {
            const userId = users[index]._id;
            await updateUserType(userId, { userType: newUserType });

            // Log the change of user type
            await createActivityLog({
                user: userEmail,
                action: `Changed ${users[index].name} (${users[index].email}) from ${oldUserType} to ${newUserType}`,
                date: new Date().toISOString()
            });
        } catch (error) {
            console.error('Error updating user type:', error);
        }
    };

    const handleDeleteUser = (index) => {
        setUserToDelete(users[index]);
        setShowModal(true);
    };

    const confirmDeleteUser = async () => {
        if (userToDelete) {
            try {
                await deleteUser(userToDelete._id);
                const updatedUsers = users.filter(user => user._id !== userToDelete._id);
                setUsers(updatedUsers);

                // Log the deletion of a user
                await createActivityLog({
                    user: userEmail,
                    action: `Deleted ${userToDelete.name} (${userToDelete.email})`,
                    date: new Date().toISOString()
                });
            } catch (error) {
                console.error('Error deleting user:', error);
            }
            setShowModal(false);
            setUserToDelete(null);
        }
    };

    const filteredUsers = users.filter(
        (user) =>
            user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            user.email.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <>
            <NavBar />
            <div className={styles.addUsersContainer}>
                <h1>Employee <span className={styles.secondaryTitle}>User Management</span></h1>
                <form onSubmit={handleSubmit} className={styles.addUsersForm}>
                    <div className={styles.formGroup}>
                        <label htmlFor="name">Name</label>
                        <input
                            type="text"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor="userType">User Type</label>
                        <CustomDropdown
                            options={['Account Manager', 'Admin', 'Super Admin']}
                            value={userType}
                            onChange={(value) => setUserType(value)}
                        />
                    </div>
                    <button type="submit" className={styles.submitButton}>Add User</button>
                </form>

                <h3>Users</h3>
                <div className={styles.searchContainer}>
                    <input
                        type="text"
                        placeholder="Search by Name or Email"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className={styles.searchInput}
                    />
                    <span className={`material-icons ${styles.searchIcon}`}>search</span>
                </div>
                {filteredUsers.length > 0 && (
                    <table className={styles.usersTable}>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>User Type</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredUsers.map((user, index) => (
                                <tr key={user._id}>
                                    <td>{user.name}</td>
                                    <td>{user.email}</td>
                                    <td>
                                        <CustomDropdown
                                            options={['Account Manager', 'Admin', 'Super Admin']}
                                            value={user.userType}
                                            onChange={(value) => handleUserTypeChange(index, value)}
                                        />
                                    </td>
                                    <td>
                                        <button onClick={() => handleDeleteUser(index)}>Delete</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
            {showModal && (
                <div className={styles.modal}>
                    <div className={styles.modalContent}>
                        <h3>Are you sure you want to delete {userToDelete?.name}'s access?</h3>
                        <p>This cannot be undone and will remove access immediately.</p>
                        <div className={styles.modalActions}>
                            <button onClick={confirmDeleteUser} className={styles.confirmButton}>Confirm</button>
                            <button onClick={() => setShowModal(false)} className={styles.cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default AddUsers;
