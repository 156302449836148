import React, { useState, useEffect, useRef } from 'react';
import {
    createClient,
    getOptions,
    getDefaultChecklistItems,
    uploadDocument,
    submitText,
    submitBusinessHours,
    submitMultipleChoice,
    submitCheckboxes,
    addContactInfo,
    submitMultipleText,
    deleteContactInfo,
    submitAdSchedule, // Add submitAdSchedule to the imports
    createActivityLog,
    submitChecklistText
} from '../api';
import CustomDropdown from './CustomDropdown';
import NavBar from './NavBar';
import Header from './Header';
import styles from './AdminForm.module.css';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import glassesLogo from '../assets/logos/FerociousGlasses.png';
import checkIcon from '../assets/icons/check_circle_40dp_FILL1_wght500_GRAD0_opsz40.svg';

const AdminForm = () => {
    const [name, setName] = useState('');
    const [contacts, setContacts] = useState([{ firstName: '', lastName: '', email: '' }]);
    const [ferociousEmailDistro, setFerociousEmailDistro] = useState('');
    const [ferociousProjectManager, setFerociousProjectManager] = useState('');
    const [ferociousAccountStrategist, setFerociousAccountStrategist] = useState('');
    const [strategistOptions, setStrategistOptions] = useState([]);
    const [managerOptions, setManagerOptions] = useState([]);
    const [items, setItems] = useState([]);
    const [message, setMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [emailStatuses, setEmailStatuses] = useState([]);
    const [modalStep, setModalStep] = useState('confirm');
    const [clientUrl, setClientUrl] = useState('');
    const textareaRefs = useRef([]);
    const [expandedSectionIndex, setExpandedSectionIndex] = useState(null);
    const [files, setFiles] = useState({});
    const [text, setText] = useState({});
    const [businessHours, setBusinessHours] = useState({});
    const [multipleChoice, setMultipleChoice] = useState({});
    const [checkboxes, setCheckboxes] = useState({});
    const [contactInfo, setContactInfo] = useState({});
    const [multipleText, setMultipleText] = useState({});
    const [adSchedule, setAdSchedule] = useState({});
    const [conditionalItems, setConditionalItems] = useState({});
    const [userEmail, setUserEmail] = useState('admin@ferociousmedia.com');
    const [previousMultipleChoice, setPreviousMultipleChoice] = useState({});
    const [checklistText, setChecklistText] = useState({}); // Add checklistText state


    useEffect(() => {
        const fetchOptionsAndDefaults = async () => {
            try {
                const [optionsResponse, defaultsResponse] = await Promise.all([getOptions(), getDefaultChecklistItems()]);
                
                setStrategistOptions(optionsResponse.strategists.map(strategist => strategist.name));
                setManagerOptions(optionsResponse.managers.map(manager => manager.name));
    
                const itemsWithDates = defaultsResponse.map(item => ({
                    ...item,
                    due_date: new Date(Date.now() + item.daysFromToday * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
                    options: item.options ? item.options : [],
                    items: item.items.map(subItem => ({
                        ...subItem,
                        conditionalItems: subItem.conditionalItems || [],
                        completed_info: subItem.completed_info || { completed_at: '', completed_by: '' },
                        edited_info: subItem.edited_info || [],
                        optional: subItem.optional || false,
                        text_input_type: subItem.text_input_type || 'plain'
                    })),
                    isCollapsed: true // Initialize each section to be collapsed
                }));
    
                setItems(itemsWithDates);
    
                setTimeout(() => {
                    textareaRefs.current.forEach(ref => {
                        if (ref) {
                            ref.style.height = 'auto';
                            ref.style.height = `${ref.scrollHeight}px`;
                        }
                    });
                }, 0);
    
                itemsWithDates.forEach(section => {
                    section.items.forEach(subItem => {
                        if (subItem.conditionalItems && subItem.conditionalItems.length > 0) {
                            console.log(`Conditional items for "${subItem.name}":`, subItem.conditionalItems);
                        }
                    });
                });
            } catch (error) {
                console.error('Error fetching options or defaults:', error);
            }
    
            const storedUser = JSON.parse(localStorage.getItem('user'));
            if (storedUser && storedUser.email) {
                setUserEmail(storedUser.email);
            }
        };
        fetchOptionsAndDefaults();
    }, []);

    const adjustTextareaHeights = () => {
        setTimeout(() => {
            textareaRefs.current.forEach(ref => {
                if (ref) {
                    ref.style.height = 'auto';
                    ref.style.height = `${ref.scrollHeight}px`;
                }
            });
        }, 0);
    };



    const handleAddItem = (sectionIndex) => {
        if (items[sectionIndex].newItem) {
            const updatedItems = [...items];
            const newItem = {
                name: updatedItems[sectionIndex].newItem,
                type: updatedItems[sectionIndex].newType,
                description: updatedItems[sectionIndex].newDescription,
                options: updatedItems[sectionIndex].newOptions,
                text_input_type: updatedItems[sectionIndex].newTextInputType || 'plain', // Ensure default value
                conditionalItems: updatedItems[sectionIndex].newType === 'multiple_choice' || updatedItems[sectionIndex].newType === 'checkboxes' ? [] : [],
                locked: false,
                completed_info: { completed_at: '', completed_by: '' },
                edited_info: []
            };
            updatedItems[sectionIndex].items.push(newItem);
            updatedItems[sectionIndex].newItem = '';
            updatedItems[sectionIndex].newType = 'file';
            updatedItems[sectionIndex].newDescription = '';
            updatedItems[sectionIndex].newOptions = [];
            updatedItems[sectionIndex].newTextInputType = 'plain'; // Reset text_input_type here
            updatedItems[sectionIndex].showNewItemFields = false;
            setItems(updatedItems);
            adjustTextareaHeights();
        }
    };


    const handleDeleteItem = (sectionIndex, itemIndex) => {
        const updatedItems = [...items];
        updatedItems[sectionIndex].items.splice(itemIndex, 1);
        setItems(updatedItems);
        adjustTextareaHeights();
    };

    const handleDeleteSection = (sectionIndex) => {
        const updatedItems = items.filter((_, index) => index !== sectionIndex);
        setItems(updatedItems);
        adjustTextareaHeights();
    };

    const handleToggleRequired = (sectionIndex, itemIndex) => {
        const updatedItems = [...items];
        updatedItems[sectionIndex].items[itemIndex].optional = !updatedItems[sectionIndex].items[itemIndex].optional;
        setItems(updatedItems);
    };

    const handleToggleCollapse = (sectionIndex) => {
        const updatedItems = [...items];
        updatedItems[sectionIndex].isCollapsed = !updatedItems[sectionIndex].isCollapsed;
        setItems(updatedItems);
        adjustTextareaHeights();
    };

    const handleToggleNewItemFields = (sectionIndex) => {
        const updatedItems = [...items];
        updatedItems[sectionIndex].showNewItemFields = !updatedItems[sectionIndex].showNewItemFields;
        setItems(updatedItems);
    };

    const handleItemTypeChange = (sectionIndex, itemIndex, value) => {
        const updatedItems = [...items];
        updatedItems[sectionIndex].items[itemIndex].type = value;
        if (value === 'multiple_choice' || value === 'checkboxes') {
            if (!updatedItems[sectionIndex].items[itemIndex].options) {
                updatedItems[sectionIndex].items[itemIndex].options = [];
            }
        } else if (value === 'text' || value === 'multiple_text') {
            if (!updatedItems[sectionIndex].items[itemIndex].text_input_type) {
                updatedItems[sectionIndex].items[itemIndex].text_input_type = 'plain';
            }
        }
        setItems(updatedItems);
    };


    const handleDescriptionChange = (sectionIndex, itemIndex, value) => {
        const updatedItems = [...items];
        updatedItems[sectionIndex].items[itemIndex].description = value;
        setItems(updatedItems);
    };

    const handleNewItemChange = (sectionIndex, value) => {
        const updatedItems = [...items];
        updatedItems[sectionIndex].newItem = value;
        setItems(updatedItems);
    };

    const handleNewTypeChange = (sectionIndex, value) => {
        const updatedItems = [...items];
        updatedItems[sectionIndex].newType = value;
        if (value === 'multiple_choice' || value === 'checkboxes') {
            updatedItems[sectionIndex].newOptions = [];
        } else if (value === 'text' || value === 'multiple_text') {
            updatedItems[sectionIndex].newTextInputType = 'plain';
        }
        setItems(updatedItems);
    };



    const handleNewDescriptionChange = (sectionIndex, value) => {
        const updatedItems = [...items];
        updatedItems[sectionIndex].newDescription = value;
        setItems(updatedItems);
    };

    const handleNewOptionChange = (sectionIndex, value) => {
        const updatedItems = [...items];
        updatedItems[sectionIndex].newOptions = value.split('\n').filter(option => option.trim() !== '');
        setItems(updatedItems);
        adjustTextareaHeights();
    };

    const handleAddConditionalItem = (sectionIndex, itemIndex) => {
        const updatedItems = [...items];
        const newConditionalItem = {
            show_on: updatedItems[sectionIndex].items[itemIndex].options[0] || '',
            item_name: '',
            item_type: 'text',
            item_description: '',
            completed_info: { completed_at: '', completed_by: '' },
            edited_info: []
        };
        if (!updatedItems[sectionIndex].items[itemIndex].conditionalItems) {
            updatedItems[sectionIndex].items[itemIndex].conditionalItems = [];
        }
        updatedItems[sectionIndex].items[itemIndex].conditionalItems.push(newConditionalItem);
        setItems(updatedItems);
    };

    const handleDeleteConditionalItem = (sectionIndex, itemIndex, conditionalIndex) => {
        const updatedItems = [...items];
        updatedItems[sectionIndex].items[itemIndex].conditionalItems.splice(conditionalIndex, 1);
        setItems(updatedItems);
        adjustTextareaHeights();
    };

    const handleConditionalItemChange = (sectionIndex, itemIndex, conditionalIndex, field, value) => {
        const updatedItems = [...items];
        updatedItems[sectionIndex].items[itemIndex].conditionalItems[conditionalIndex][field] = value;

        if ((field === 'item_type' && (value === 'text' || value === 'multiple_text')) && !updatedItems[sectionIndex].items[itemIndex].conditionalItems[conditionalIndex].text_input_type) {
            updatedItems[sectionIndex].items[itemIndex].conditionalItems[conditionalIndex].text_input_type = 'plain';
        }

        setItems(updatedItems);
    };


    const handleConditionalShowOnChange = (sectionIndex, itemIndex, conditionalIndex, value) => {
        const updatedItems = [...items];
        updatedItems[sectionIndex].items[itemIndex].conditionalItems[conditionalIndex].show_on = value;
        setItems(updatedItems);
    };

    const handleDueDateChange = (sectionIndex, value) => {
        const updatedItems = [...items];
        updatedItems[sectionIndex].due_date = value;
        setItems(updatedItems);
    };

    const handleAddContact = () => {
        setContacts([...contacts, { firstName: '', lastName: '', email: '' }]);
    };

    const handleRemoveContact = (index) => {
        if (contacts.length > 1) {
            const updatedContacts = contacts.filter((_, i) => i !== index);
            setContacts(updatedContacts);
        }
    };

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear().toString().slice(-2)}`;
    };

    const handleContactChange = (index, field, value) => {
        const updatedContacts = [...contacts];
        updatedContacts[index][field] = value;
        setContacts(updatedContacts);
    };

    const handleToggleLock = (sectionIndex, itemIndex) => {
        const updatedItems = [...items];
        updatedItems[sectionIndex].items[itemIndex].locked = !updatedItems[sectionIndex].items[itemIndex].locked;
        setItems(updatedItems);
    };

    const handleMoveSection = (sectionIndex, direction) => {
        const updatedItems = [...items];
        const [removedSection] = updatedItems.splice(sectionIndex, 1);
        if (direction === 'up' && sectionIndex > 0) {
            updatedItems.splice(sectionIndex - 1, 0, removedSection);
        } else if (direction === 'down' && sectionIndex < items.length - 1) {
            updatedItems.splice(sectionIndex + 1, 0, removedSection);
        } else {
            updatedItems.splice(sectionIndex, 0, removedSection);
        }
        setItems(updatedItems);
        adjustTextareaHeights();
    };

    const handleSectionNameChange = (sectionIndex, value) => {
        const updatedItems = [...items];
        updatedItems[sectionIndex].name = value;
        setItems(updatedItems);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setShowModal(true);
        setModalStep('confirm');
    };

    const handleConfirm = async () => {
        setModalStep('progress');

        try {
            const flattenedItems = items.flatMap(section =>
                section.items.map(item => {
                    let businessHoursObject = {};

                    if (businessHours[item._id]) {
                        businessHoursObject = Object.entries(businessHours[item._id])
                            .reduce((acc, [day, { open, close }]) => {
                                if (typeof open === 'string' && open.trim() === 'Closed') {
                                    acc[day] = 'Closed';
                                } else if (typeof open === 'string' && open.trim() === '24HR') {
                                    acc[day] = '24HR';
                                } else if (typeof open === 'string' && typeof close === 'string') {
                                    acc[day] = `Open: ${open}, Close: ${close}`;
                                }
                                return acc;
                            }, {});
                    }

                    return {
                        name: `${section.name} - ${item.name}`,
                        type: item.type,
                        description: item.description,
                        options: item.options || [],
                        conditional_items: item.conditionalItems || [],
                        locked: item.locked,
                        optional: item.optional,
                        text_value: text[item._id] || '',
                        business_hours: businessHoursObject,
                        selected_option: multipleChoice[item._id] || '',
                        selected_options: checkboxes[item._id] || [],
                        multiple_text_values: multipleText[item._id] || [],
                        checklist_text_values: checklistText[item._id] || [],
                        contact_info: contactInfo[item._id] || [],
                        ad_schedule: adSchedule[item._id] || [],
                        text_input_type: item.text_input_type || 'plain', // Ensure default value
                        completed_info: (text[item._id] || businessHours[item._id] || multipleChoice[item._id] || checkboxes[item._id] || multipleText[item._id] || contactInfo[item._id] || adSchedule[item._id]) ? {
                            completed_at: new Date(),
                            completed_by: userEmail
                        } : {}
                    };
                })
            );

            const due_dates = items.map(section => ({ section: section.name, due_date: section.due_date }));

            const clientData = {
                name,
                contacts,
                items: flattenedItems,
                due_dates,
                ferociousEmailDistro,
                ferociousProjectManager,
                ferociousAccountStrategist
            };

            const response = await createClient(clientData);
            console.log(clientData);
            setMessage(`Client created successfully. URL: http://testing.mattszypula.com/client/${response.data.client.url}`);
            setClientUrl(`http://testing.mattszypula.com/client/${response.data.client.url}`);

            const clientId = response.data.client._id; // Extract clientId from response


            const emailPayload = {
                clientName: name,
                emailDistro: ferociousEmailDistro,
                portalURL: `http://testing.mattszypula.com/client/${response.data.client.url}`,
                contacts,
                clientId 
            };

            setEmailStatuses(contacts.map(contact => ({ email: contact.email, status: 'pending' })));

            try {
                const emailResponses = await axios.post('https://gq2mehmkfnneudzsztxv6cbp2e0rlbtw.lambda-url.us-east-2.on.aws/', emailPayload, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                emailResponses.data.response.forEach((res, index) => {
                    setEmailStatuses(prevStatuses => {
                        const newStatuses = [...prevStatuses];
                        newStatuses[index].status = res.status === 'sent' ? 'sent' : 'failed';
                        return newStatuses;
                    });
                });
            } catch (error) {
                console.error('Error sending emails:', error);
            }

            contacts.forEach(contact => {
                console.log(`Username: ${contact.email}, Password will be sent to you separately.`);
            });

            await createActivityLog({
                user: userEmail,
                action: `Created ${name} portal`,
                date: new Date().toISOString()
            });
            window.open(`http://testing.mattszypula.com/admin/client-portal/${response.data.client.url}`, '_blank');
        } catch (error) {
            setMessage('Error creating client');
        }
    };






    const closeModal = () => {
        setShowModal(false);
        setModalStep('confirm');
    };

    const toggleSectionItems = (sectionIndex) => {
        setExpandedSectionIndex(prevIndex => prevIndex === sectionIndex ? null : sectionIndex);
    };

    const handleFileChange = (checklistId, fileId) => (e) => {
        setFiles(prevFiles => {
            const updatedFiles = { ...prevFiles };
            if (!updatedFiles[checklistId]) {
                updatedFiles[checklistId] = {};
            }
            updatedFiles[checklistId][fileId] = e.target.files[0];
            return updatedFiles;
        });
    };

    const handleAddFileInput = (checklistId) => {
        const newFileId = uuidv4();
        setFiles(prevFiles => ({
            ...prevFiles,
            [checklistId]: {
                ...(prevFiles[checklistId] || {}),
                [newFileId]: null
            }
        }));
    };

    const handleDeleteFileInput = (checklistId, fileId) => {
        setFiles(prevFiles => {
            const updatedFiles = { ...prevFiles };
            if (updatedFiles[checklistId]) {
                delete updatedFiles[checklistId][fileId];
            }
            return updatedFiles;
        });
    };

    const handleTextChange = (checklistId) => (e) => {
        setText(prevText => ({ ...prevText, [checklistId]: e.target.value }));
    };

    const handleBusinessHoursChange = (checklistId, day, timeType) => (e) => {
        setBusinessHours(prevBusinessHours => {
            const updatedBusinessHours = {
                ...prevBusinessHours,
                [checklistId]: {
                    ...prevBusinessHours[checklistId],
                    [day]: {
                        ...prevBusinessHours[checklistId]?.[day],
                        [timeType]: e.target.value
                    }
                }
            };

            // Check if all 7 days have valid values
            const allDaysFilled = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
                .every(day => {
                    const open = updatedBusinessHours[checklistId]?.[day]?.open;
                    const close = updatedBusinessHours[checklistId]?.[day]?.close;
                    return open?.trim() !== '' && (close?.trim() !== '' || open === 'Closed' || open === '24HR');
                });



            return updatedBusinessHours;
        });
    };

    const handleMultipleChoiceChange = (checklistId) => (e) => {
        const selectedOption = e.target.value;
        setMultipleChoice(prevMultipleChoice => {
            const updatedMultipleChoice = { ...prevMultipleChoice };
            if (updatedMultipleChoice[checklistId] === selectedOption) {
                delete updatedMultipleChoice[checklistId];
            } else {
                updatedMultipleChoice[checklistId] = selectedOption;
            }
            return updatedMultipleChoice;
        });
    };

    const handleClearMultipleChoice = (checklistId) => {
        setMultipleChoice(prevMultipleChoice => {
            const updatedMultipleChoice = { ...prevMultipleChoice };
            delete updatedMultipleChoice[checklistId];
            return updatedMultipleChoice;
        });
    };

    const handleCheckboxChange = (checklistId, option) => (e) => {
        setCheckboxes(prevCheckboxes => {
            const updatedCheckboxes = { ...prevCheckboxes };
            if (!updatedCheckboxes[checklistId]) {
                updatedCheckboxes[checklistId] = [];
            }
            if (e.target.checked) {
                updatedCheckboxes[checklistId].push(option);
            } else {
                updatedCheckboxes[checklistId] = updatedCheckboxes[checklistId].filter(item => item !== option);
            }
            return updatedCheckboxes;
        });
    };

    const handleContactInfoChange = (checklistId, index, field) => (e) => {
        setContactInfo(prevContactInfo => {
            const updatedContactInfo = { ...prevContactInfo };
            if (!updatedContactInfo[checklistId]) {
                updatedContactInfo[checklistId] = [];
            }
            if (!updatedContactInfo[checklistId][index]) {
                updatedContactInfo[checklistId][index] = { contactName: '', contactEmail: '', contactPhone: '' };
            }
            updatedContactInfo[checklistId][index][field] = e.target.value;
            return updatedContactInfo;
        });
    };

    const handleAddContactInfo = (checklistId) => {
        setContactInfo(prevContactInfo => ({
            ...prevContactInfo,
            [checklistId]: [
                ...(prevContactInfo[checklistId] || []),
                { contactName: '', contactEmail: '', contactPhone: '' }
            ]
        }));
    };

    const handleChecklistTextChange = (checklistId, index, field, value) => {
        console.log('handleChecklistTextChange called');

        setChecklistText(prevChecklistText => {
            const updatedChecklistText = { ...prevChecklistText };
            if (!updatedChecklistText[checklistId]) {
                updatedChecklistText[checklistId] = [];
            }
            if (!updatedChecklistText[checklistId][index]) {
                updatedChecklistText[checklistId][index] = { option: '', value: '', checked: false };
            }
            updatedChecklistText[checklistId][index][field] = value;

            // Log the change
            console.log(`Checklist Text Change: checklistId=${checklistId}, index=${index}, field=${field}, value=${value}`);
            console.log(updatedChecklistText);
            return updatedChecklistText;
        });
    };



    const handleAddChecklistText = (checklistId) => {
        setChecklistText(prevChecklistText => ({
            ...prevChecklistText,
            [checklistId]: [
                ...(prevChecklistText[checklistId] || []),
                { checked: false, text: '' }
            ]
        }));
    };

    const handleChecklistTextSubmit = async (checklistId) => {
        try {
            await submitChecklistText({
                checklist_id: checklistId,
                checklist_text_values: checklistText[checklistId],
                user_email: userEmail
            });
            setMessage('Checklist text submitted successfully');
        } catch (error) {
            setMessage('Error submitting checklist text');
        }
    };



    const handleDeleteContactInfo = async (checklistId, index) => {
        try {
            const contact = contactInfo[checklistId][index];
            await deleteContactInfo({
                checklist_id: checklistId,
                contact_name: contact.contactName,
                contact_email: contact.contactEmail,
                contact_phone: contact.contactPhone,
                user_email: userEmail
            });
            setContactInfo(prevContactInfo => {
                const updatedContactInfo = { ...prevContactInfo };
                if (updatedContactInfo[checklistId]) {
                    updatedContactInfo[checklistId].splice(index, 1);
                }
                return updatedContactInfo;
            });
            setMessage('Contact info deleted successfully');
        } catch (error) {
            setMessage('Error deleting contact info');
        }
    };

    const renderInputPlaceholder = (text_input_type) => {
        switch (text_input_type) {
            case 'email':
                return 'Enter email address';
            case 'phone':
                return 'Enter phone number';
            default:
                return 'Enter text';
        }
    };

    const getEndTimes = (startTime) => {
        const times = [];
        let startHour = 0;
        let startPeriod = 'AM';

        if (startTime !== '') {
            const [time, period] = startTime.split(' ');
            startHour = parseInt(time.split(':')[0]);
            startPeriod = period;
        }

        let currentHour = startHour;
        let currentPeriod = startPeriod;

        for (let i = 0; i < 24; i++) {
            currentHour = (currentHour % 12) + 1;
            if (currentHour === 12) {
                currentPeriod = currentPeriod === 'AM' ? 'PM' : 'AM';
            }
            if (currentHour === startHour && currentPeriod === startPeriod) {
                break;
            }
            if (currentPeriod === 'PM' || currentHour !== startHour) {
                times.push(`${currentHour % 12 || 12}:00 ${currentPeriod}`);
            }
            if (currentHour === 12 && currentPeriod === 'AM') {
                break;
            }
        }

        return times;
    };

    const handleMultipleTextChange = (checklistId, index) => (e) => {
        setMultipleText(prevMultipleText => {
            const updatedMultipleText = { ...prevMultipleText };
            if (!updatedMultipleText[checklistId]) {
                updatedMultipleText[checklistId] = [''];
            }
            updatedMultipleText[checklistId][index] = e.target.value;
            return updatedMultipleText;
        });
    };

    const handleAddMultipleText = (checklistId) => {
        setMultipleText(prevMultipleText => ({
            ...prevMultipleText,
            [checklistId]: [
                ...(prevMultipleText[checklistId] || []),
                ''
            ]
        }));
    };

    const handleDeleteMultipleText = (checklistId, index) => {
        setMultipleText(prevMultipleText => {
            const updatedMultipleText = { ...prevMultipleText };
            if (updatedMultipleText[checklistId]) {
                updatedMultipleText[checklistId].splice(index, 1);
            }
            return updatedMultipleText;
        });
    };

    const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    const handleAdScheduleChange = (checklistId, index, field) => (e) => {
        setAdSchedule(prevAdSchedule => {
            const updatedAdSchedule = { ...prevAdSchedule };
            if (!updatedAdSchedule[checklistId]) {
                updatedAdSchedule[checklistId] = daysOfWeek.map(day => ({
                    day, // Initialize the day
                    runAds: false,
                    startTime: '',
                    endTime: ''
                }));
            }

            const daySchedule = { ...updatedAdSchedule[checklistId][index] };
            daySchedule[field] = field === 'runAds' ? e.target.checked : e.target.value;

            updatedAdSchedule[checklistId][index] = daySchedule;
            return updatedAdSchedule;
        });
    };


    const handleAddAdSchedule = (checklistId) => {
        setAdSchedule(prevAdSchedule => ({
            ...prevAdSchedule,
            [checklistId]: [
                ...(prevAdSchedule[checklistId] || []),
                { day: '', startTime: '', endTime: '' }
            ]
        }));
    };

    const handleDeleteAdSchedule = (checklistId, index) => {
        setAdSchedule(prevAdSchedule => {
            const updatedAdSchedule = { ...prevAdSchedule };
            if (updatedAdSchedule[checklistId]) {
                updatedAdSchedule[checklistId].splice(index, 1);
            }
            return updatedAdSchedule;
        });
    };

    const handleUpload = async (checklistId, fileId) => {
        const formData = new FormData();
        formData.append('file', files[checklistId][fileId]);
        formData.append('client_id', 'new-client');
        formData.append('checklist_id', checklistId);
        formData.append('user_email', userEmail);

        try {
            await uploadDocument(formData);
            setMessage('Document uploaded successfully');
        } catch (error) {
            setMessage('Error uploading document');
        }
    };

    const handleTextSubmit = async (checklistId) => {
        try {
            await submitText({
                checklist_id: checklistId,
                text_value: text[checklistId],
                user_email: userEmail
            });
            setMessage('Text submitted successfully');
        } catch (error) {
            setMessage('Error submitting text');
        }
    };

    const handleBusinessHoursSubmit = async (checklistId) => {
        try {
            await submitBusinessHours({
                checklist_id: checklistId,
                business_hours: businessHours[checklistId],
                user_email: userEmail
            });
            setMessage('Business hours submitted successfully');
        } catch (error) {
            setMessage('Error submitting business hours');
        }
    };

    const handleMultipleChoiceSubmit = async (checklistId) => {
        try {
            await submitMultipleChoice({
                checklist_id: checklistId,
                selected_option: multipleChoice[checklistId],
                user_email: userEmail
            });
            setMessage('Multiple choice submitted successfully');
        } catch (error) {
            setMessage('Error submitting multiple choice');
        }
    };

    const handleCheckboxSubmit = async (checklistId) => {
        try {
            await submitCheckboxes({
                checklist_id: checklistId,
                selected_options: checkboxes[checklistId] || [],
                user_email: userEmail
            });
            setMessage('Checkboxes submitted successfully');
        } catch (error) {
            setMessage('Error submitting checkboxes');
        }
    };

    const handleContactInfoSubmit = async (checklistId, index) => {
        try {
            const contact = contactInfo[checklistId][index];
            await addContactInfo({
                checklist_id: checklistId,
                contact_name: contact.contactName,
                contact_email: contact.contactEmail,
                contact_phone: contact.contactPhone,
                user_email: userEmail
            });
            setMessage('Contact info submitted successfully');
        } catch (error) {
            setMessage('Error submitting contact info');
        }
    };

    const handleMultipleTextSubmit = async (checklistId) => {
        try {
            await submitMultipleText({
                checklist_id: checklistId,
                text_values: multipleText[checklistId],
                user_email: userEmail
            });
            setMessage('Multiple text submitted successfully');
        } catch (error) {
            setMessage('Error submitting multiple text');
        }
    };

    const handleAdScheduleSubmit = async (checklistId) => {
        try {
            await submitAdSchedule({
                checklist_id: checklistId,
                ad_schedule: adSchedule[checklistId].map((daySchedule, index) => ({
                    ...daySchedule,
                    day: daysOfWeek[index] // Ensure day is included
                })),
                user_email: userEmail
            });
            setMessage('Ad schedule submitted successfully');
        } catch (error) {
            setMessage('Error submitting ad schedule');
        }
    };


    const renderConditionalItems = (parentId) => {
        return Object.values(conditionalItems)
            .filter(item => item.parent_id === parentId && item.show_on === multipleChoice[parentId])
            .map(condItem => (
                <div key={condItem.item_id} className="question-item conditional-item">
                    <div className="item-flex-container">
                        <div className="item-container">
                            <div className="item-name-container" style={{ marginLeft: condItem.completed_info?.completed_at ? '-28px' : '0' }}>
                                {condItem.completed_info?.completed_at && <img src={checkIcon} alt="Completed" className='completed-checkmark' />}
                                <strong className="item-name">{condItem.item_name}</strong>
                            </div>
                            <p className="item-description">{condItem.item_description}</p>
                        </div>
                        <div className="item-input">
                            {condItem.item_type === 'text' && (
                                <div className="input-container">
                                    <input
                                        type="text"
                                        value={text[condItem.item_id] || ''}
                                        onChange={handleTextChange(condItem.item_id)}
                                        placeholder="Text Input"
                                    />
                                    <button type="button" className="button" onClick={() => handleTextSubmit(condItem.item_id)}>Submit</button>
                                </div>
                            )}
                            {condItem.item_type === 'file' && (
                                <>
                                    {files[condItem.item_id] && Object.keys(files[condItem.item_id]).map((fileId) => (
                                        <div key={fileId}>
                                            <input type="file" onChange={handleFileChange(condItem.item_id, fileId)} />
                                            <button type="button" className="button" onClick={() => handleUpload(condItem.item_id, fileId)}>Upload Document</button>
                                            <button type="button" className="button" onClick={() => handleDeleteFileInput(condItem.item_id, fileId)}>Delete</button>
                                        </div>
                                    ))}
                                    <button type="button" className="button" onClick={() => handleAddFileInput(condItem.item_id)}>+ Add Another File</button>
                                </>
                            )}
                            {condItem.item_type === 'business_hours' && (
                                <div className="business-hours-container">
                                    <table className="business-hours-table">
                                        <thead>
                                            <tr>
                                                <th>Day</th>
                                                <th>Opening Time</th>
                                                <th>Closing Time</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map(day => (
                                                <tr key={day}>
                                                    <td>{day.charAt(0).toUpperCase() + day.slice(1)}</td>
                                                    <td>
                                                        <select
                                                            value={businessHours[condItem._id]?.[day]?.open || ''}
                                                            onChange={(e) => handleBusinessHoursChange(condItem._id, day, 'open')(e)}
                                                        >
                                                            <option value="">Select Opening Time</option>
                                                            <option value="Closed">Closed</option>
                                                            <option value="24HR">24 Hours</option>
                                                            {Array.from({ length: 24 }, (_, hour) => (
                                                                <option key={hour} value={`${hour % 12 || 12}:00 ${hour < 12 ? 'AM' : 'PM'}`}>{`${hour % 12 || 12}:00 ${hour < 12 ? 'AM' : 'PM'}`}</option>
                                                            ))}
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <select
                                                            value={businessHours[condItem._id]?.[day]?.close || ''}
                                                            onChange={(e) => handleBusinessHoursChange(condItem._id, day, 'close')(e)}
                                                            disabled={businessHours[condItem._id]?.[day]?.open === 'Closed' || businessHours[condItem._id]?.[day]?.open === '24HR'}
                                                        >
                                                            <option value="">Select Closing Time</option>
                                                            {getEndTimes(businessHours[condItem._id]?.[day]?.open || '').map((time, idx) => (
                                                                <option key={idx} value={time}>{time}</option>
                                                            ))}
                                                        </select>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                            {condItem.item_type === 'contact_info' && (
                                <>
                                    {contactInfo[condItem.item_id]?.map((contact, index) => (
                                        <div key={index} className="input-container">
                                            <input
                                                type="text"
                                                value={contact.contactName || ''}
                                                onChange={handleContactInfoChange(condItem.item_id, index, 'contactName')}
                                                placeholder="Contact Name"
                                            />
                                            <input
                                                type="email"
                                                value={contact.contactEmail || ''}
                                                onChange={handleContactInfoChange(condItem.item_id, index, 'contactEmail')}
                                                placeholder="Contact Email"
                                            />
                                            <input
                                                type="tel"
                                                value={contact.contactPhone || ''}
                                                onChange={handleContactInfoChange(condItem.item_id, index, 'contactPhone')}
                                                placeholder="Contact Phone"
                                            />
                                            <button type="button" className="button" onClick={() => handleContactInfoSubmit(condItem.item_id, index)}>Submit</button>
                                            <button type="button" className="button" onClick={() => handleDeleteContactInfo(condItem.item_id, index)}>Delete</button>
                                        </div>
                                    ))}
                                    <button type="button" className="button" onClick={() => handleAddContactInfo(condItem.item_id)}>+ Add Another Contact</button>
                                </>
                            )}
                            {condItem.item_type === 'multiple_text' && (
                                <>
                                    {multipleText[condItem.item_id]?.map((textValue, index) => (
                                        <div key={index} className="input-container">
                                            <input
                                                type="text"
                                                value={textValue || ''}
                                                onChange={handleMultipleTextChange(condItem.item_id, index)}
                                                placeholder="Text Input"
                                            />
                                            <button type="button" className="button" onClick={() => handleMultipleTextSubmit(condItem.item_id)}>Submit</button>
                                            <button type="button" className="button" onClick={() => handleDeleteMultipleText(condItem.item_id, index)}>Delete</button>
                                        </div>
                                    ))}
                                    <button type="button" className="button" onClick={() => handleAddMultipleText(condItem.item_id)}>+ Add Another</button>
                                </>
                            )}
                            {condItem.item_type === 'checklist_text' && ( // Add the render logic for checklist_text
                                <>
                                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                        <tbody>
                                            {condItem.options.map((option, index) => (
                                                <tr key={index} style={{ borderBottom: '1px solid transparent' }}>
                                                    <td style={{ padding: '8px', width: '40px' }}>
                                                        <div className="checkbox-option">
                                                            <input
                                                                type="checkbox"
                                                                id={`${condItem._id}-${index}`}
                                                                name={`${condItem._id}-${index}`}
                                                                checked={checklistText[condItem._id]?.[index]?.checked || false}
                                                                onChange={(e) => handleChecklistTextChange(condItem._id, index, 'checked', e.target.checked)}
                                                            />
                                                            <label htmlFor={`${condItem._id}-${index}`}></label>
                                                        </div>
                                                    </td>
                                                    <td style={{ padding: '8px' }}>
                                                        <label htmlFor={`${condItem._id}-${index}`} style={{ marginRight: '8px' }}>{option}</label>
                                                    </td>
                                                    <td style={{ padding: '8px', width: '100%' }}>
                                                        <input
                                                            type="text"
                                                            value={checklistText[condItem._id]?.[index]?.value || ''}
                                                            onChange={(e) => handleChecklistTextChange(condItem._id, index, 'value', e.target.value)}
                                                            placeholder="Text Input"
                                                            style={{ width: '100%' }}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </>
                            )}
                            {condItem.completed_info?.completed_at && (
                                <span className='completed-item-text'>Completed on: {formatDate(condItem.completed_info.completed_at)} by {condItem.completed_info.completed_by}</span>
                            )}
                            {condItem.edited_info?.map((edit, index) => (
                                <span key={index} className='edited-item-text'>Edited on: {formatDate(edit.edited_at)} by {edit.edited_by}</span>
                            ))}
                        </div>
                    </div>
                </div>
            ));
    };


    return (
        <div>
            <NavBar />
            <Header Title="Create" SecondaryTitle="New Client Profile" />
            <div className={styles.adminForm}>
                <form onSubmit={handleSubmit}>
                    <div className={styles.clientInfoHeader}>
                        <div className={styles.icon}></div>
                        <div>
                            <h2>Client Info</h2>
                            <p>The contact emails filled in below will be sent welcome emails and a link to the onboarding portal</p>
                        </div>
                    </div>
                    <hr className={styles.divider} />
                    <div className={styles.formSection}>
                        <div className={styles.twoColumnGrid}>
                            <div className={styles.inputGroup}>
                                <label>Business Name</label>
                                <input
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    className={styles.textbox}
                                    required
                                />
                            </div>
                            <div className={styles.inputGroup}>
                                <label>Ferocious Project Manager (If website is purchased)</label>
                                <CustomDropdown
                                    options={managerOptions}
                                    value={ferociousProjectManager}
                                    onChange={(value) => setFerociousProjectManager(value)}
                                    placeholder="Select Project Manager"
                                />
                            </div>
                            <div className={styles.inputGroup}>
                                <label>Ferocious Email Distro</label>
                                <input
                                    type="text"
                                    value={ferociousEmailDistro}
                                    onChange={(e) => setFerociousEmailDistro(e.target.value)}
                                    className={styles.textbox}
                                    required
                                />
                            </div>
                            <div className={styles.inputGroup}>
                                <label>Ferocious Account Strategist</label>
                                <CustomDropdown
                                    options={strategistOptions}
                                    value={ferociousAccountStrategist}
                                    onChange={(value) => setFerociousAccountStrategist(value)}
                                    placeholder="Select Account Strategist"
                                />
                            </div>
                        </div>
                    </div>

                    <div className={styles.formSection}>
                        <h3>Client Contact Email(s)</h3>
                        {contacts.map((contact, index) => (
                            <div key={index} className={styles.threeColumnGrid}>
                                <div className={styles.inputGroup}>
                                    <label>First Name</label>
                                    <input
                                        type="text"
                                        value={contact.firstName}
                                        onChange={(e) => handleContactChange(index, 'firstName', e.target.value)}
                                        className={styles.textbox}
                                        required
                                    />
                                </div>
                                <div className={styles.inputGroup}>
                                    <label>Last Name</label>
                                    <input
                                        type="text"
                                        value={contact.lastName}
                                        onChange={(e) => handleContactChange(index, 'lastName', e.target.value)}
                                        className={styles.textbox}
                                        required
                                    />
                                </div>
                                <div className={styles.inputGroup}>
                                    <label>Email Address</label>
                                    <input
                                        type="email"
                                        value={contact.email}
                                        onChange={(e) => handleContactChange(index, 'email', e.target.value)}
                                        className={styles.textbox}
                                        required
                                    />
                                </div>
                                {index > 0 && (
                                    <button type="button" onClick={() => handleRemoveContact(index)} className={styles.removeContactButton}>
                                        <span className="material-icons">close</span>
                                    </button>
                                )}
                            </div>
                        ))}
                        <button type="button" onClick={handleAddContact} className={styles.addContactButton}>+ Add More Contacts</button>
                    </div>

                    <div className={styles.clientInfoHeader}>
                        <div className={styles.checklistIcon}></div>
                        <div>
                            <h2>Onboarding Checklist(s)</h2>
                            <p>Review the listed sections and delete any sections/items that do not pertain to the client or add additional items if needed</p>
                        </div>
                    </div>
                    <hr className={styles.divider} />
                    <div className={styles.keySection}>
                        <h3>Key</h3>
                        <div className={styles.keyItem}>
                            <span className={`material-icons ${styles.lockKeyIcon}`}>lock</span>
                            <span>Item can only be submitted once, any further changes require Ferocious approval</span>
                        </div>
                        <div className={styles.keyItem}>
                            <span className={`material-icons ${styles.conditionalKeyIcon}`}>tune</span>
                            <span>Add conditional item that will only show when its 'show on' value is selected (only available for multiple choice questions)</span>
                        </div>
                        <div className={styles.keyItem}>
                            <span className={`material-symbols-outlined ${styles.conditionalKeyIcon}`}>emergency</span>
                            <span>Item is required</span>
                        </div>
                    </div>
                    <hr className={styles.divider} />

                    {items.map((section, sectionIndex) => (
                        <div key={sectionIndex} className={styles.section}>
                            <div className={styles.sectionHeader}>
                                <input
                                    type="text"
                                    value={section.name}
                                    onChange={(e) => handleSectionNameChange(sectionIndex, e.target.value)}
                                    className={styles.sectionNameInput}
                                />
                                <div className={styles.sectionActions}>
                                    <button type="button" className={styles.moveButton} onClick={() => handleMoveSection(sectionIndex, 'up')}>
                                        <span className="material-icons" title='Move section up'>keyboard_arrow_up</span>
                                    </button>
                                    <button type="button" className={styles.moveButton} onClick={() => handleMoveSection(sectionIndex, 'down')}>
                                        <span className="material-icons" title='Move section down'>keyboard_arrow_down</span>
                                    </button>
                                    <p>Due:</p>
                                    <input
                                        type="date"
                                        value={section.due_date || ''}
                                        onChange={(e) => handleDueDateChange(sectionIndex, e.target.value)}
                                        className={`${styles.textbox} ${styles.dateInput}`}
                                    />
                                    <button type="button" className={styles.hideSection} onClick={() => handleToggleCollapse(sectionIndex)}>
                                        {section.isCollapsed ? 'Expand Section' : 'Collapse Section'}
                                    </button>
                                    <button type="button" className={styles.deleteSection} onClick={() => handleDeleteSection(sectionIndex)}>Delete Section</button>
                                </div>
                            </div>
                            <hr className={styles.divider} />

                            {!section.isCollapsed && (
                                <>
                                    <div className={styles.sectionItems}>
                                        <div className={styles.itemRowHeader}>
                                            <span className={styles.itemHeader}>Item Name</span>
                                            <span className={styles.itemHeader}>Item Type</span>
                                            <span className={styles.itemHeader}>Item Description</span>
                                            <span className={styles.itemHeader}>Options</span>
                                            <span className={styles.itemHeader}></span>
                                        </div>
                                        {section.items.map((item, itemIndex) => (
                                            <React.Fragment key={itemIndex}>
                                                <div className={styles.itemRow}>
                                                    <span className={styles.itemName}>{item.name}</span>
                                                    <CustomDropdown
                                                        options={['file', 'text', 'business_hours', 'multiple_choice', 'checkboxes', 'contact_info', 'multiple_text', 'ad_schedule', 'checklist_text']}
                                                        value={item.type}
                                                        onChange={(value) => handleItemTypeChange(sectionIndex, itemIndex, value)}
                                                        placeholder="Select Type"
                                                    />
                                                    <textarea
                                                        value={item.description}
                                                        onChange={(e) => {
                                                            handleDescriptionChange(sectionIndex, itemIndex, e.target.value);
                                                            e.target.style.height = 'auto';
                                                            e.target.style.height = `${e.target.scrollHeight}px`;
                                                        }}
                                                        placeholder="Description (optional)"
                                                        className={styles.textarea}
                                                        rows={1}
                                                        ref={(el) => textareaRefs.current.push(el)}
                                                        style={{ alignSelf: 'start' }}
                                                    />
                                                    {item.type === 'multiple_choice' || item.type === 'checkboxes' || item.type === 'checklist_text' ? (
                                                        <textarea
                                                            placeholder="Enter options, one per line"
                                                            value={item.options.join('\n')}
                                                            onChange={(e) => {
                                                                const updatedOptions = e.target.value.split('\n');
                                                                const updatedItems = [...items];
                                                                updatedItems[sectionIndex].items[itemIndex].options = updatedOptions;
                                                                setItems(updatedItems);
                                                                e.target.style.height = 'auto';
                                                                e.target.style.height = `${e.target.scrollHeight}px`;
                                                            }}
                                                            className={styles.textarea}
                                                            rows={1}
                                                            ref={(el) => textareaRefs.current.push(el)}
                                                            style={{ alignSelf: 'start' }}
                                                        />
                                                    ) : null}
                                                    {item.type === 'text' || item.type === 'multiple_text' ? (
                                                        <CustomDropdown
                                                            options={['plain', 'email', 'phone']}
                                                            value={item.text_input_type || 'plain'}
                                                            onChange={(value) => {
                                                                const updatedItems = [...items];
                                                                updatedItems[sectionIndex].items[itemIndex].text_input_type = value;
                                                                setItems(updatedItems);
                                                            }}
                                                            placeholder="Select Text Input Type"
                                                        />
                                                    ) : null}
                                                    <div className={styles.actionButtons}>
                                                        <button type="button" className={styles.deleteButton} onClick={() => handleDeleteItem(sectionIndex, itemIndex)}>
                                                            <span className="material-icons">close</span>
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className={`${styles.lockButton} ${item.locked ? styles.locked : styles.unlocked}`}
                                                            onClick={() => handleToggleLock(sectionIndex, itemIndex)}
                                                        >
                                                            <span className="material-icons">{item.locked ? 'lock' : 'lock_open'}</span>
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className={`${styles.requiredButton} ${item.optional ? styles.notRequired : styles.required}`}
                                                            onClick={() => handleToggleRequired(sectionIndex, itemIndex)}
                                                        >
                                                            <span className={`material-symbols-outlined ${item.optional ? styles.optionalIcon : styles.requiredIcon}`}>emergency</span>
                                                        </button>
                                                        {item.type === 'multiple_choice' && (
                                                            <button type="button" className={styles.addConditionalButton} onClick={() => handleAddConditionalItem(sectionIndex, itemIndex)}>
                                                                <span className="material-icons">tune</span>
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                                {item.conditionalItems && item.conditionalItems.length > 0 && (
                                                    <div className={styles.conditionalItemsWrapper}>
                                                        {item.conditionalItems.map((conditional, conditionalIndex) => (
                                                            <div key={conditionalIndex} className={styles.conditionalItemRowWrapper}>
                                                                <div className={styles.conditionalItem}>
                                                                    <div className={styles.conditionalItemRow}>
                                                                        <label>{`Conditional Item ${conditionalIndex + 1}`}</label>
                                                                        <input
                                                                            type="text"
                                                                            placeholder="Item Name"
                                                                            value={conditional.item_name}
                                                                            onChange={(e) => handleConditionalItemChange(sectionIndex, itemIndex, conditionalIndex, 'item_name', e.target.value)}
                                                                            className={styles.textbox}
                                                                        />
                                                                    </div>
                                                                    <div className={styles.conditionalItemRow}>
                                                                        <label>Description</label>
                                                                        <input
                                                                            type="text"
                                                                            placeholder="Description"
                                                                            value={conditional.item_description}
                                                                            onChange={(e) => handleConditionalItemChange(sectionIndex, itemIndex, conditionalIndex, 'item_description', e.target.value)}
                                                                            className={styles.textbox}
                                                                        />
                                                                    </div>
                                                                    <div className={styles.conditionalItemRow}>
                                                                        <label>Type</label>
                                                                        <CustomDropdown
                                                                            options={['text', 'file', 'business_hours', 'contact_info', 'multiple_text', 'ad_schedule', 'checklist_text']}
                                                                            value={conditional.item_type}
                                                                            onChange={(value) => handleConditionalItemChange(sectionIndex, itemIndex, conditionalIndex, 'item_type', value)}
                                                                            placeholder="Select Type"
                                                                        />
                                                                    </div>
                                                                    {(conditional.item_type === 'text' || conditional.item_type === 'multiple_text') && (
                                                                        <div className={styles.conditionalItemRow}>
                                                                            <label>Text Type</label>
                                                                            <CustomDropdown
                                                                                options={['plain', 'email', 'phone']}
                                                                                value={conditional.text_input_type || 'plain'}
                                                                                onChange={(value) => handleConditionalItemChange(sectionIndex, itemIndex, conditionalIndex, 'text_input_type', value)}
                                                                                placeholder="Select Text Input Type"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    <div className={styles.conditionalItemRow}>
                                                                        <label>Show On</label>
                                                                        <CustomDropdown
                                                                            options={item.options}
                                                                            value={conditional.show_on}
                                                                            onChange={(value) => handleConditionalShowOnChange(sectionIndex, itemIndex, conditionalIndex, value)}
                                                                            placeholder="Select Option"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <button type="button" className={styles.deleteButton} onClick={() => handleDeleteConditionalItem(sectionIndex, itemIndex, conditionalIndex)}>
                                                                    <span className="material-icons">close</span>
                                                                </button>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}

                                            </React.Fragment>
                                        ))}
                                    </div>
                                    {section.showNewItemFields && (
                                        <div className={styles.newItem}>
                                            <input
                                                type="text"
                                                value={section.newItem}
                                                onChange={(e) => handleNewItemChange(sectionIndex, e.target.value)}
                                                placeholder="New Item"
                                                className={styles.textbox}
                                            />
                                            <CustomDropdown
                                                options={['file', 'text', 'business_hours', 'multiple_choice', 'checkboxes', 'contact_info', 'multiple_text', 'ad_schedule', 'checklist_text']} // Add 'ad_schedule' to options
                                                value={section.newType}
                                                onChange={(value) => handleNewTypeChange(sectionIndex, value)}
                                                placeholder="Select Type"
                                            />
                                            <input
                                                type="text"
                                                value={section.newDescription}
                                                onChange={(e) => handleNewDescriptionChange(sectionIndex, e.target.value)}
                                                placeholder="Description (optional)"
                                                className={styles.textbox}
                                            />
                                            {(section.newType === 'multiple_choice' || section.newType === 'checkboxes') && (
                                                <textarea
                                                    placeholder="Enter options, one per line"
                                                    value={section.newOptions.join('\n')}
                                                    onChange={(e) => handleNewOptionChange(sectionIndex, e.target.value)}
                                                    className={styles.textarea}
                                                />
                                            )}
                                            <div>
                                                <button type="button" className={styles.addItemButton} onClick={() => handleAddItem(sectionIndex)}>Add Item</button>
                                                <button type="button" className={styles.cancelButton} onClick={() => handleToggleNewItemFields(sectionIndex)}>Cancel</button>
                                            </div>
                                        </div>
                                    )}
                                    <button type="button" className={styles.createCustomItemButton} onClick={() => handleToggleNewItemFields(sectionIndex)}>Create Custom Item</button>
                                </>
                            )}
                        </div>
                    ))}

                    <div className={styles.clientInfoHeader}>
                        <div className={styles.knownItemsIcon}></div>
                        <div>
                            <h2>Complete Known Items</h2>
                            <p>If there are any items that are already known for the client please complete it in the sections below</p>
                        </div>
                    </div>
                    <hr className={styles.divider} />

                    <div className={styles.completeKnownItemsButtons}>
                        {items.map((section, sectionIndex) => (
                            <button
                                key={sectionIndex}
                                type="button"
                                onClick={() => toggleSectionItems(sectionIndex)}
                                className={`${styles.toggleItemsButton} ${expandedSectionIndex === sectionIndex ? styles.selectedButton : ''}`}
                            >
                                {section.name}
                            </button>
                        ))}
                    </div>

                    {expandedSectionIndex !== null && (
                        <div className={styles.sectionItems}>
                            {items[expandedSectionIndex].items.map((item, itemIndex) => (
                                <div key={itemIndex} className="question-item">
                                    <div className="item-flex-container">
                                        <div className="item-container">
                                            <div className="item-name-container" style={{ marginLeft: item.completed_info?.completed_at ? '-28px' : '0' }}>
                                                {item.completed_info?.completed_at && <img src={checkIcon} alt="Completed" className='completed-checkmark' />}
                                                <strong className="item-name">{item.name}</strong>
                                            </div>
                                            <p className="item-description">{item.description}</p>
                                        </div>
                                        <div className="item-input">
                                            {item.type === 'file' ? (
                                                <>
                                                    {files[item._id] && Object.keys(files[item._id]).map((fileId) => (
                                                        <div key={fileId}>
                                                            <input type="file" onChange={handleFileChange(item._id, fileId)} />
                                                            <button type="button" className="button" onClick={() => handleUpload(item._id, fileId)}>Upload Document</button>
                                                            <button type="button" className="button" onClick={() => handleDeleteFileInput(item._id, fileId)}>Delete</button>
                                                        </div>
                                                    ))}
                                                    <button type="button" className="button" onClick={() => handleAddFileInput(item._id)}>+ Add Another File</button>
                                                </>
                                            ) : item.type === 'text' ? (
                                                <div className="input-container">
                                                    <input
                                                        type={item.text_input_type === 'email' ? 'email' : item.text_input_type === 'phone' ? 'tel' : 'text'}
                                                        value={text[item._id] || ''}
                                                        onChange={handleTextChange(item._id)}
                                                        placeholder={renderInputPlaceholder(item.text_input_type)}
                                                    />
                                                    <button type="button" className="button" onClick={() => handleTextSubmit(item._id)}>Submit</button>
                                                </div>
                                            ) : item.type === 'business_hours' ? (
                                                <div className="business-hours-container">
                                                    <table className="business-hours-table">
                                                        <thead>
                                                            <tr>
                                                                <th>Day</th>
                                                                <th>Opening Time</th>
                                                                <th>Closing Time</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map(day => (
                                                                <tr key={day}>
                                                                    <td>{day.charAt(0).toUpperCase() + day.slice(1)}</td>
                                                                    <td>
                                                                        <select
                                                                            value={businessHours[item._id]?.[day]?.open || ''}
                                                                            onChange={(e) => handleBusinessHoursChange(item._id, day, 'open')(e)}
                                                                        >
                                                                            <option value="">Select Opening Time</option>
                                                                            <option value="Closed">Closed</option>
                                                                            <option value="24HR">24 Hours</option>
                                                                            {Array.from({ length: 24 }, (_, hour) => (
                                                                                <option key={hour} value={`${hour % 12 || 12}:00 ${hour < 12 ? 'AM' : 'PM'}`}>{`${hour % 12 || 12}:00 ${hour < 12 ? 'AM' : 'PM'}`}</option>
                                                                            ))}
                                                                        </select>
                                                                    </td>
                                                                    <td>
                                                                        <select
                                                                            value={businessHours[item._id]?.[day]?.close || ''}
                                                                            onChange={(e) => handleBusinessHoursChange(item._id, day, 'close')(e)}
                                                                            disabled={businessHours[item._id]?.[day]?.open === 'Closed' || businessHours[item._id]?.[day]?.open === '24HR'}
                                                                        >
                                                                            <option value="">Select Closing Time</option>
                                                                            {getEndTimes(businessHours[item._id]?.[day]?.open || '').map((time, idx) => (
                                                                                <option key={idx} value={time}>{time}</option>
                                                                            ))}
                                                                        </select>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            ) : item.type === 'multiple_choice' ? (
                                                <div className="multiple-choice-container">
                                                    {item.options.map(option => (
                                                        <div key={option} className="multiple-choice-option">
                                                            <div>
                                                                <input
                                                                    type="radio"
                                                                    id={`${item._id}-${option}`}
                                                                    name={item._id}
                                                                    value={option}
                                                                    checked={multipleChoice[item._id] === option}
                                                                    onChange={handleMultipleChoiceChange(item._id)}
                                                                />
                                                                <label htmlFor={`${item._id}-${option}`}>{option}</label>
                                                            </div>
                                                        </div>
                                                    ))}
                                                    <button type="button" className="button" onClick={() => handleClearMultipleChoice(item._id)}>Clear Choice</button>
                                                </div>
                                            ) : item.type === 'checkboxes' ? (
                                                <div className="checkbox-container">
                                                    {item.options.map(option => (
                                                        <div key={option} className="checkbox-option">
                                                            <input
                                                                type="checkbox"
                                                                id={`${item._id}-${option}`}
                                                                name={item._id}
                                                                value={option}
                                                                checked={checkboxes[item._id]?.includes(option) || false}
                                                                onChange={handleCheckboxChange(item._id, option)}
                                                            />
                                                            <label htmlFor={`${item._id}-${option}`}>{option}</label>
                                                        </div>
                                                    ))}
                                                    <button type="button" className="button" onClick={() => handleCheckboxSubmit(item._id)}>Submit</button>
                                                </div>
                                            ) : item.type === 'contact_info' ? (
                                                <>
                                                    {contactInfo[item._id]?.map((contact, index) => (
                                                        <div key={index} className="input-container">
                                                            <input
                                                                type="text"
                                                                value={contact.contactName || ''}
                                                                onChange={handleContactInfoChange(item._id, index, 'contactName')}
                                                                placeholder="Contact Name"
                                                            />
                                                            <input
                                                                type="email"
                                                                value={contact.contactEmail || ''}
                                                                onChange={handleContactInfoChange(item._id, index, 'contactEmail')}
                                                                placeholder="Contact Email"
                                                            />
                                                            <input
                                                                type="tel"
                                                                value={contact.contactPhone || ''}
                                                                onChange={handleContactInfoChange(item._id, index, 'contactPhone')}
                                                                placeholder="Contact Phone"
                                                            />
                                                            <button type="button" className="button" onClick={() => handleContactInfoSubmit(item._id, index)}>Submit</button>
                                                            <button type="button" className="button" onClick={() => handleDeleteContactInfo(item._id, index)}>Delete</button>
                                                        </div>
                                                    ))}
                                                    <button type="button" className="button" onClick={() => handleAddContactInfo(item._id)}>+ Add Another Contact</button>
                                                </>
                                            ) : item.type === 'multiple_text' ? (
                                                <>
                                                    {multipleText[item._id]?.map((textValue, index) => (
                                                        <div key={index} className="input-container">
                                                            <input
                                                                type={item.text_input_type === 'email' ? 'email' : item.text_input_type === 'phone' ? 'tel' : 'text'}
                                                                value={textValue || ''}
                                                                onChange={handleMultipleTextChange(item._id, index)}
                                                                placeholder={renderInputPlaceholder(item.text_input_type)}
                                                            />
                                                            <button type="button" className="button" onClick={() => handleMultipleTextSubmit(item._id)}>Submit</button>
                                                            <button type="button" className="button" onClick={() => handleDeleteMultipleText(item._id, index)}>Delete</button>
                                                        </div>
                                                    ))}
                                                    <button type="button" className="button" onClick={() => handleAddMultipleText(item._id)}>+ Add Another</button>
                                                </>
                                            ) : item.type === 'ad_schedule' ? (
                                                <div className={styles.adScheduleContainer}>
                                                    <table className={styles.adScheduleTable}>
                                                        <thead>
                                                            <tr>
                                                                <th>Day of Week</th>
                                                                <th>Run Ads?</th>
                                                                <th>Start Time</th>
                                                                <th>End Time</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {daysOfWeek.map((day, index) => (
                                                                <tr key={index}>
                                                                    <td>{day}</td>
                                                                    <td>
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={adSchedule[item._id]?.[index]?.runAds || false}
                                                                            onChange={(e) => handleAdScheduleChange(item._id, index, 'runAds')(e)}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <select
                                                                            value={adSchedule[item._id]?.[index]?.startTime || ''}
                                                                            onChange={(e) => handleAdScheduleChange(item._id, index, 'startTime')(e)}
                                                                            disabled={!adSchedule[item._id]?.[index]?.runAds}
                                                                        >
                                                                            <option value="">Select Start Time</option>
                                                                            <option value="24 Hours">24 Hours</option>
                                                                            {Array.from({ length: 24 }, (_, hour) => (
                                                                                <option key={hour} value={`${hour % 12 || 12}:00 ${hour < 12 ? 'AM' : 'PM'}`}>{`${hour % 12 || 12}:00 ${hour < 12 ? 'AM' : 'PM'}`}</option>
                                                                            ))}
                                                                        </select>
                                                                    </td>
                                                                    <td>
                                                                        <select
                                                                            value={adSchedule[item._id]?.[index]?.endTime || ''}
                                                                            onChange={(e) => handleAdScheduleChange(item._id, index, 'endTime')(e)}
                                                                            disabled={!adSchedule[item._id]?.[index]?.runAds || adSchedule[item._id]?.[index]?.startTime === '24 Hours'}
                                                                        >
                                                                            <option value="">Select End Time</option>
                                                                            {Array.from({ length: 24 }, (_, hour) => (
                                                                                <option key={hour} value={`${hour % 12 || 12}:00 ${hour < 12 ? 'AM' : 'PM'}`}>{`${hour % 12 || 12}:00 ${hour < 12 ? 'AM' : 'PM'}`}</option>
                                                                            ))}
                                                                        </select>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            ) : item.type === 'checklist_text' ? (
                                                <>
                                                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                                        <tbody>
                                                            {item.options.map((option, index) => (
                                                                <tr key={index} style={{ borderBottom: '1px solid transparent' }}>
                                                                    <td style={{ padding: '8px', width: '40px' }}>
                                                                        <div className="checkbox-option">
                                                                            <input
                                                                                type="checkbox"
                                                                                id={`${item._id}-${index}`}
                                                                                name={`${item._id}-${index}`}
                                                                                checked={checklistText[item._id]?.[index]?.checked || false}
                                                                                onChange={(e) => handleChecklistTextChange(item._id, index, 'checked', e.target.checked)}
                                                                            />
                                                                            <label htmlFor={`${item._id}-${index}`}></label>
                                                                        </div>
                                                                    </td>
                                                                    <td style={{ padding: '8px' }}>
                                                                        <label htmlFor={`${item._id}-${index}`} style={{ marginRight: '8px' }}>{option}</label>
                                                                    </td>
                                                                    <td style={{ padding: '8px', width: '100%' }}>
                                                                        <input
                                                                            type="text"
                                                                            value={checklistText[item._id]?.[index]?.value || ''}
                                                                            onChange={(e) => handleChecklistTextChange(item._id, index, 'value', e.target.value)}
                                                                            placeholder="Text Input"
                                                                            style={{ width: '100%' }}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>

                                                </>


                                            ) : null}
                                            {item.completed_info?.completed_at && <span className='completed-item-text'>Completed on: {formatDate(item.completed_info.completed_at)} by {item.completed_info.completed_by}</span>}
                                            {item.edited_info?.map((edit, index) => (
                                                <span key={index} className='edited-item-text'>Edited on: {formatDate(edit.edited_at)} by {edit.edited_by}</span>
                                            ))}
                                        </div>
                                    </div>
                                    {renderConditionalItems(item._id)}
                                </div>
                            ))}
                        </div>
                    )}

                    <button type="submit" className={styles.createClientButton}>
                        <span className={styles.buttonText}>Create Client</span>
                        <span className={`material-icons ${styles.buttonArrow}`}>arrow_forward</span>
                    </button>

                    {message && <p>{message}</p>}
                </form>
            </div>
            {showModal && (
                <div className={styles.modal}>
                    <div className={styles.modalContent}>
                        <img src={glassesLogo} alt="Ferocious Media" className={styles.logo} />
                        {modalStep === 'confirm' ? (
                            <>
                                <h2>Heads Up!</h2>
                                <h4>This will send a welcome email to:</h4>
                                <ul>
                                    {contacts.map((contact, index) => (
                                        <li key={index}>{contact.email}</li>
                                    ))}
                                    <li>{ferociousEmailDistro}</li>
                                </ul>
                                <div className={styles.buttonGroup}>
                                    <button className={styles.closeModal} onClick={closeModal}>Cancel</button>
                                    <button className={styles.confirmModal} onClick={handleConfirm}>Confirm</button>
                                </div>
                            </>
                        ) : (
                            <>
                                <h2>Email Progress</h2>
                                <h4>Please wait to see emails send</h4>
                                <table className={styles.emailTable}>
                                    <tbody>
                                        {emailStatuses.map((status, index) => (
                                            <tr key={index}>
                                                <td>{status.email}</td>
                                                <td>
                                                    <span className={status.status === 'sent' ? styles.greenDot : styles.redDot}></span>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <button className={styles.closeModal} onClick={closeModal}>Close</button>
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default AdminForm;

