import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getClientById, getClientUsers, removeCompanyFromUser, addClientUser, getUserById } from '../api';
import NavBar from './NavBar';
import axios from 'axios';
import styles from './ClientUsers.module.css';

const ClientUsers2 = () => {
    const { clientId } = useParams();
    const [users, setUsers] = useState([]);
    const [clientName, setClientName] = useState('');
    const [clientUrl, setClientUrl] = useState('');
    const [emailDistro, setEmailDistro] = useState('');
    const [error, setError] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState('');
    const [userToDelete, setUserToDelete] = useState(null);
    const [newUser, setNewUser] = useState({ firstName: '', lastName: '', email: '' });
    const [deleteMessage, setDeleteMessage] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [modalMessage, setModalMessage] = useState('');
    const [modalStep, setModalStep] = useState('confirm');
    const [emailStatus, setEmailStatus] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [editedUsers, setEditedUsers] = useState({});

    useEffect(() => {
        const fetchClientData = async () => {
            try {
                console.log(`Fetching users for client ID: ${clientId}`);
                const userResponse = await getClientUsers(clientId);
                if (userResponse.data && Array.isArray(userResponse.data.users)) {
                    setUsers(userResponse.data.users);
                } else {
                    setError('Failed to load users');
                }

                console.log(`Fetching client details for client ID: ${clientId}`);
                const clientResponse = await getClientById(clientId);
                if (clientResponse.data && clientResponse.data.client) {
                    setClientName(clientResponse.data.client.name);
                    setClientUrl(clientResponse.data.client.url);
                    setEmailDistro(clientResponse.data.client.ferociousEmailDistro);
                    console.log('Client ID:', clientId);
                    console.log('Client URL:', clientResponse.data.client.url);
                } else {
                    setError('Failed to load client name');
                }
            } catch (err) {
                console.error('Error fetching client data:', err);
                setError('Failed to load data');
            }
        };

        const user = JSON.parse(localStorage.getItem('user') || sessionStorage.getItem('user'));
        if (user && user.email) {
            setUserEmail(user.email);
        }

        fetchClientData();
    }, [clientId]);

    const logClientAction = async (message) => {
        try {
            await axios.post('https://my-crm-app-be05fb8e206a.herokuapp.com/logs', {
                user: userEmail,
                clientId,
                action: message
            });
        } catch (error) {
            console.error('Error logging action:', error);
        }
    };

    const handleDelete = async () => {
        try {
            const userToDeleteDetails = users.find(user => user._id === userToDelete);
            await removeCompanyFromUser(clientId, userToDelete);
            setUsers(users.filter(user => user._id !== userToDelete));
            setShowModal(false);

            // Log the deletion of the user
            const logMessage = `Deleted ${userToDeleteDetails.firstName} ${userToDeleteDetails.lastName} (${userToDeleteDetails.email})`;
            await logClientAction(logMessage);
        } catch (err) {
            console.error('Error deleting user:', err);
            setError('Failed to delete user');
        }
    };

    const openDeleteModal = async (userId) => {
        setUserToDelete(userId);
        setModalType('delete');
        try {
            const response = await getUserById(userId);
            const user = response.data;
            if (user.companyIds.length > 1) {
                setDeleteMessage('This will remove the user from this company. They are still a user of other companies');
            } else {
                setDeleteMessage('This will delete the user from the database as they have no other associated companies.');
            }
            setShowModal(true);
        } catch (err) {
            console.error('Error fetching user details:', err);
            setError('Failed to fetch user details');
        }
    };

    const closeDeleteModal = () => {
        setShowModal(false);
        setUserToDelete(null);
        setModalType('');
    };

    const handleAddUserChange = (e) => {
        const { name, value } = e.target;
        setNewUser(prevState => ({ ...prevState, [name]: value }));
    };

    const handleAddUser = (e) => {
        e.preventDefault();
        setModalMessage(`${newUser.email} will be added to ${clientName} and receive a welcome email. Please confirm.`);
        setModalType('add');
        setShowModal(true);
        setModalStep('confirm');
    };

    const handleConfirm = async () => {
        if (modalType === 'add') {
            setModalStep('progress');
            try {
                const response = await addClientUser(clientId, newUser);
                setUsers([...users, response.data.user]);
                setNewUser({ firstName: '', lastName: '', email: '' });

                try {
                    const emailResponse = await axios.post('https://gq2mehmkfnneudzsztxv6cbp2e0rlbtw.lambda-url.us-east-2.on.aws/', {
                        clientName,
                        emailDistro,
                        portalURL: `https://testing.mattszypula.com/client/${clientUrl}`,
                        contacts: [{ firstName: newUser.firstName, email: newUser.email }],
                        clientId
                    }, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });

                    console.log('Email response:', emailResponse.data);
                    if (emailResponse.data.message === 'All emails sent successfully!') {
                        setEmailStatus('Email sent successfully');
                    } else {
                        setEmailStatus('Failed to send email');
                    }
                } catch (emailErr) {
                    console.error('Error sending email:', emailErr);
                    setEmailStatus('Failed to send email');
                }

                // Log the addition of the new user
                const logMessage = `Added ${newUser.firstName} ${newUser.lastName} (${newUser.email})`;
                await logClientAction(logMessage);

            } catch (err) {
                console.error('Error adding user:', err);
                setEmailStatus('Failed to add user');
            }
        } else if (modalType === 'delete') {
            await handleDelete();
        }
    };

    const closeModal = () => {
        setShowModal(false);
        setModalStep('confirm');
        setEmailStatus('');
        setModalType('');
    };

    const handleUserChange = (e, userId) => {
        const { name, value } = e.target;
        setEditedUsers(prevEditedUsers => ({
            ...prevEditedUsers,
            [userId]: {
                ...prevEditedUsers[userId],
                [name]: value
            }
        }));
    };

    const toggleEditMode = () => {
        setIsEditing(!isEditing);
    };

    const saveUserChanges = async (userId) => {
        const editedUser = editedUsers[userId];
        if (editedUser) {
            try {
                const response = await axios.put(`http://localhost:3000/client/${clientId}/users/${userId}`, {
                    firstName: editedUser.firstName,
                    lastName: editedUser.lastName
                });
                setUsers(prevUsers =>
                    prevUsers.map(user =>
                        user._id === userId ? response.data.user : user
                    )
                );
                setEditedUsers(prevEditedUsers => {
                    const updatedEditedUsers = { ...prevEditedUsers };
                    delete updatedEditedUsers[userId];
                    return updatedEditedUsers;
                });
            } catch (error) {
                console.error('Error saving user changes:', error);
            }
        }
    };

    if (error) {
        return <div className={styles.error}>{error}</div>;
    }

    return (
        <div>
            <NavBar userEmail={userEmail} clientName={clientName} clientUrl={clientUrl} />
            <div className={styles.container}>
                <h1>Client Users for <span className={styles.secondaryTitle}>{clientName}</span></h1>
                <form onSubmit={handleAddUser} className={styles.addUserForm}>
                    <h3>Add New User</h3>
                    <div className={styles.inputGroup}>
                        <label>First Name</label>
                        <input type="text" name="firstName" value={newUser.firstName} onChange={handleAddUserChange} className={styles.textbox} required />
                    </div>
                    <div className={styles.inputGroup}>
                        <label>Last Name</label>
                        <input type="text" name="lastName" value={newUser.lastName} onChange={handleAddUserChange} className={styles.textbox} required />
                    </div>
                    <div className={styles.inputGroup}>
                        <label>Email</label>
                        <input type="email" name="email" value={newUser.email} onChange={handleAddUserChange} className={styles.textbox} required />
                    </div>
                    <button type="submit" className={styles.addButton}>Add User</button>
                </form>
                <div className={styles.editButtonContainer}>
                    <button onClick={toggleEditMode} className={`${styles.editButton} ${isEditing ? styles.editMode : ''}`}>
                        <span className="material-symbols-outlined">{isEditing ? 'close' : 'edit'}</span>
                    </button>
                </div>
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Email</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map(user => (
                            <tr key={user._id}>
                                <td>
                                    {isEditing ? (
                                        <input
                                            type="text"
                                            name="firstName"
                                            value={editedUsers[user._id]?.firstName || user.firstName}
                                            onChange={(e) => handleUserChange(e, user._id)}
                                            className={styles.textbox}
                                        />
                                    ) : (
                                        user.firstName
                                    )}
                                </td>
                                <td>
                                    {isEditing ? (
                                        <input
                                            type="text"
                                            name="lastName"
                                            value={editedUsers[user._id]?.lastName || user.lastName}
                                            onChange={(e) => handleUserChange(e, user._id)}
                                            className={styles.textbox}
                                        />
                                    ) : (
                                        user.lastName
                                    )}
                                </td>
                                <td>{user.email}</td>
                                <td>
                                    {isEditing ? (
                                        <button onClick={() => saveUserChanges(user._id)} className={styles.saveButton}>
                                            <span className="material-symbols-outlined saveIcon">check</span>
                                        </button>
                                    ) : (
                                        <button onClick={() => openDeleteModal(user._id)} className={styles.deleteButton}>Delete</button>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                {showModal && (
                    <div className={styles.modal}>
                        <div className={styles.modalContent}>
                            {modalStep === 'confirm' ? (
                                <>
                                    <h3>{modalType === 'add' ? modalMessage : deleteMessage}</h3>
                                    <div className={styles.modalActions}>
                                        <button onClick={handleConfirm} className={styles.confirmButton}>Confirm</button>
                                        <button onClick={closeModal} className={styles.cancelButton}>Cancel</button>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <h3>Email Status</h3>
                                    <p>{emailStatus}</p>
                                    <button onClick={closeModal} className={styles.closeButton}>Close</button>
                                </>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ClientUsers2;
