import React, { useState, useEffect, useCallback } from 'react';
import {
    getClientData, uploadDocument, addClientLog, deleteContactInfo, submitText,
    submitBusinessHours, submitMultipleChoice, submitCheckboxes, addContactInfo,
    deleteDocument, submitMultipleText, submitAdSchedule, submitChecklistText
} from '../api';
import { useParams } from 'react-router-dom';
import ClientNavBar from './ClientNavBar';
import Footer from './Footer';
import { v4 as uuidv4 } from 'uuid';
import './ClientView.css';
import checkIcon from '../assets/icons/check_circle_40dp_FILL1_wght500_GRAD0_opsz40.svg';
import UnsavedChangesModal from './UnsavedChangesModal'; // Import the modal
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ToastifyCustom.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './CustomQuill.css'; // Import the custom CSS file
import confetti from 'canvas-confetti';



const ClientView = () => {
    const { url } = useParams();
    const [clientData, setClientData] = useState({});
    const [files, setFiles] = useState({});
    const [text, setText] = useState({});
    const [businessHours, setBusinessHours] = useState({});
    const [multipleChoice, setMultipleChoice] = useState({});
    const [checkboxes, setCheckboxes] = useState({});
    const [contactInfo, setContactInfo] = useState({});
    const [multipleText, setMultipleText] = useState({});
    const [conditionalItems, setConditionalItems] = useState({});
    const [message, setMessage] = useState('');
    const [completedItems, setCompletedItems] = useState(0);
    const [openSection, setOpenSection] = useState(null);
    const [userEmail, setUserEmail] = useState('');
    const [userName, setUserName] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false); // Add state for modal
    const [isEditing, setIsEditing] = useState({}); // Add state for tracking editing
    const [progress, setProgress] = useState({});
    const [adSchedule, setAdSchedule] = useState({});
    const [checklistText, setChecklistText] = useState({});

    const [confettiDisplayed, setConfettiDisplayed] = useState({});


    const modules = {
        toolbar: [
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }],
            ['link', 'image'],
            ['clean']
        ]
    };


    const fetchClientData = useCallback(async () => {
        try {
            const response = await getClientData(url);
            setClientData(response.data);
            console.log(response.data);
            const textValues = {};
            const businessHoursValues = {};
            const multipleChoiceValues = {};
            const checkboxValues = {};
            const contactInfoValues = {};
            const multipleTextValues = {};
            const adScheduleValues = {};
            const checklistTextValues = {};
            const conditionalItemsMap = {};

            response.data.checklists.forEach(item => {
                if (item.type === 'text') {
                    textValues[item._id] = item.text_value || '';
                } else if (item.type === 'business_hours') {
                    const businessHours = item.business_hours || {};
                    businessHoursValues[item._id] = {};
                    ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].forEach(day => {
                        const times = businessHours[day] ? businessHours[day].split(', ') : [];
                        businessHoursValues[item._id][day] = {
                            open: times[0]?.replace('Open: ', '') || '',
                            close: times[1]?.replace('Close: ', '') || ''
                        };
                    });
                } else if (item.type === 'multiple_choice') {
                    multipleChoiceValues[item._id] = item.selected_option || '';
                    item.conditional_items.forEach(condItem => {
                        const conditionalItem = response.data.checklists.find(ci => ci._id === condItem.item_id);
                        if (conditionalItem) {
                            conditionalItemsMap[conditionalItem._id] = {
                                ...conditionalItem,
                                parent_id: item._id,
                                show_on: condItem.show_on
                            };
                        }
                    });
                } else if (item.type === 'checkboxes') {
                    checkboxValues[item._id] = item.selected_options || [];
                } else if (item.type === 'contact_info') {
                    contactInfoValues[item._id] = item.contact_info || [];
                } else if (item.type === 'multiple_text') {
                    multipleTextValues[item._id] = item.multiple_text_values.length > 0 ? item.multiple_text_values : [''];
                } else if (item.type === 'ad_schedule') {
                    adScheduleValues[item._id] = item.ad_schedule || [];
                } else if (item.type === 'checklist_text') {
                    checklistTextValues[item._id] = item.checklist_text_values || [];
                }
            });

            setText(textValues);
            setBusinessHours(businessHoursValues);
            setMultipleChoice(multipleChoiceValues);
            setCheckboxes(checkboxValues);
            setContactInfo(contactInfoValues);
            setMultipleText(multipleTextValues);
            setAdSchedule(adScheduleValues);
            setChecklistText(checklistTextValues);
            setConditionalItems(conditionalItemsMap);

            const initialCompletedItems = response.data.checklists.filter(item =>
                !item.optional && (
                    item.type === 'text' ? item.text_value?.trim() :
                        item.type === 'business_hours' ? Object.values(businessHoursValues[item._id]).some(day => day.open?.trim() && day.close?.trim()) :
                            item.type === 'multiple_choice' ? item.selected_option?.trim() :
                                item.type === 'checkboxes' ? item.selected_options.length > 0 :
                                    item.type === 'contact_info' ? item.contact_info.length > 0 :
                                        item.type === 'multiple_text' ? item.multiple_text_values.length > 0 :
                                            item.type === 'ad_schedule' ? item.ad_schedule.length > 0 :
                                                item.type === 'checklist_text' ? item.checklist_text_values.length > 0 :
                                                    isDocumentUploaded(item._id)
                )
            ).length;
            setCompletedItems(initialCompletedItems);

        } catch (error) {
            console.error('Error fetching client data:', error);
            setMessage('Error fetching client data');
        }
    }, [url]);




    const getEndTimes = (startTime) => {
        const times = [];
        let startHour = 0;
        let startPeriod = 'AM';

        if (startTime !== '') {
            const [time, period] = startTime.split(' ');
            startHour = parseInt(time.split(':')[0]);
            startPeriod = period;
        }

        let currentHour = startHour;
        let currentPeriod = startPeriod;

        for (let i = 0; i < 24; i++) {
            currentHour = (currentHour % 12) + 1;
            if (currentHour === 12) {
                currentPeriod = currentPeriod === 'AM' ? 'PM' : 'AM';
            }
            if (currentHour === startHour && currentPeriod === startPeriod) {
                break;
            }
            if (currentPeriod === 'PM' || currentHour !== startHour) {
                times.push(`${currentHour % 12 || 12}:00 ${currentPeriod}`);
            }
            if (currentHour === 12 && currentPeriod === 'AM') {
                break;
            }
        }

        return times;
    };






    useEffect(() => {
        fetchClientData();
    }, [fetchClientData]);



    useEffect(() => {
        const handleBeforeUnload = async (event) => {
            if (unsavedChanges) {
                await saveUnsavedChanges();
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [unsavedChanges, isEditing, text, businessHours, multipleChoice, checkboxes, contactInfo, multipleText, adSchedule]);


    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user') || sessionStorage.getItem('user'));
        console.log(user);
        if (user && user.email) {
            setUserEmail(user.email);
            if (user.firstName && user.lastName) {
                setUserName(`${user.firstName} ${user.lastName}`);
            } else {
                setUserName(user.name);
            }
        }

        if (!sessionStorage.getItem('loggedVisit') && clientData.client?._id) {
            logClientUserVisit();
            sessionStorage.setItem('loggedVisit', 'true');
        }
    }, [clientData.client]);

    useEffect(() => {
        if (!clientData.checklists) return;

        const groupedChecklists = clientData.checklists.reduce((acc, item) => {
            if (!item.parent_item_id) {
                const [section, itemName] = item.item_name.split(' - ');
                if (!acc[section]) acc[section] = [];
                acc[section].push({ ...item, itemName });
            }
            return acc;
        }, {});

        console.log('Grouped Checklists:', groupedChecklists);

        const newSectionStatus = {};

        Object.keys(groupedChecklists).forEach(section => {
            const completed = isSectionCompleted(section, groupedChecklists);
            newSectionStatus[section] = completed;
        });

        console.log('Section completion status updated:', newSectionStatus);
    }, [clientData.checklists, clientData.client]);

    const logClientUserVisit = async () => {
        const user = JSON.parse(localStorage.getItem('user') || sessionStorage.getItem('user'));
        if (user && user.token) {
            try {
                await addClientLog({
                    user: user.email,
                    clientId: clientData.client._id,
                    action: `${user.email} visited portal`
                });
            } catch (error) {
                console.error('Error logging visit:', error);
            }
        } else {
            console.error('User data not found in storage');
        }
    };

    const isDocumentUploaded = useCallback((checklistId) => {
        return clientData.documents?.some(doc => doc.checklist_id === checklistId);
    }, [clientData.documents]);

    const handleFileChange = (checklistId, fileId) => (e) => {
        const file = e.target.files[0];
        setFiles(prevFiles => {
            const updatedFiles = { ...prevFiles };
            if (!updatedFiles[checklistId]) {
                updatedFiles[checklistId] = {};
            }
            updatedFiles[checklistId][fileId] = file;
            setUnsavedChanges(true);
            return updatedFiles;
        });
    };


    const handleAddFileInput = (checklistId) => {
        const newFileId = uuidv4();
        setFiles(prevFiles => ({
            ...prevFiles,
            [checklistId]: {
                ...(prevFiles[checklistId] || {}),
                [newFileId]: null
            }
        }));
        setUnsavedChanges(true);
    };

    const handleChecklistTextChange = (checklistId, index, field, value) => {
        setChecklistText(prevChecklistText => {
            const updatedChecklistText = { ...prevChecklistText };
            if (!updatedChecklistText[checklistId]) {
                updatedChecklistText[checklistId] = [];
            }
            if (!updatedChecklistText[checklistId][index]) {
                updatedChecklistText[checklistId][index] = { option: '', value: '', checked: false };
            }
            if (field === 'checked' && !value) {
                updatedChecklistText[checklistId][index].value = '';
            }
            updatedChecklistText[checklistId][index][field] = value;

            setIsEditing(prevIsEditing => ({ ...prevIsEditing, [checklistId]: true }));
            setUnsavedChanges(true);
            return updatedChecklistText;
        });
    };





    const handleChecklistTextSubmit = async (checklistId) => {
        try {
            const checklistItem = clientData.checklists.find(item => item._id === checklistId);
            if (checklistItem.locked && checklistItem.completed_info?.completed_at) {
                handleLockedItem();
                return;
            }

            const valuesToSubmit = {
                checklist_id: checklistId,
                checklist_text_values: checklistText[checklistId],
                user_email: userEmail
            };

            await submitChecklistText(valuesToSubmit);

            // Save parent item when conditional item is completed
            if (conditionalItems[checklistId]?.parent_id) {
                const parentItemId = conditionalItems[checklistId].parent_id;
                await submitMultipleChoice({
                    checklist_id: parentItemId,
                    selected_option: multipleChoice[parentItemId],
                    user_email: userEmail
                });
            }

            await addClientLog({
                user: userEmail,
                clientId: clientData.client._id,
                action: `Submitted ${checklistItem.item_name}`
            });
            setMessage('Checklist text submitted successfully');
            fetchClientData();
            setUnsavedChanges(false);
        } catch (error) {
            setMessage('Error submitting checklist text');
        }
    };







    const handleDeleteFileInput = (checklistId, fileId) => {
        setFiles(prevFiles => {
            const updatedFiles = { ...prevFiles };
            if (updatedFiles[checklistId]) {
                delete updatedFiles[checklistId][fileId];
            }
            setUnsavedChanges(true);
            return updatedFiles;
        });
    };

    const handleTextChange = (checklistId) => (e) => {
        const value = e.target.value;
        setText(prevText => ({ ...prevText, [checklistId]: value }));
        setUnsavedChanges(true);
        setIsEditing(prevEditing => ({ ...prevEditing, [checklistId]: value.trim() !== '' }));
    
        const toolbar = document.getElementById(`toolbar-${checklistId}`);
        if (toolbar) {
            toolbar.classList.add('show');
        }
    };

    const handleBlur = (checklistId) => {
        const toolbar = document.getElementById(`toolbar-${checklistId}`);
        if (toolbar) {
            toolbar.classList.remove('show');
        }
    };



    const handleAdScheduleChange = (checklistId, index, field) => (e) => {
        setAdSchedule(prevAdSchedule => {
            const updatedAdSchedule = {
                ...prevAdSchedule,
                [checklistId]: prevAdSchedule[checklistId] ? [...prevAdSchedule[checklistId]] : []
            };
            if (!updatedAdSchedule[checklistId][index]) {
                updatedAdSchedule[checklistId][index] = { day: '', runAds: false, startTime: '', endTime: '' };
            }
            if (field === 'runAds') {
                updatedAdSchedule[checklistId][index][field] = e.target.checked;
            } else {
                updatedAdSchedule[checklistId][index][field] = e.target.value;
            }
            updatedAdSchedule[checklistId][index].day = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'][index];
            console.log('Updated Ad Schedule before submit:', updatedAdSchedule);
            setIsEditing(prevIsEditing => ({ ...prevIsEditing, [checklistId]: true }));
            setUnsavedChanges(true);
            return updatedAdSchedule;
        });
    };






    const handleAdScheduleSubmit = async (checklistId) => {
        try {
            const checklistItem = clientData.checklists.find(item => item._id === checklistId);
            if (checklistItem.locked && checklistItem.completed_info?.completed_at) {
                handleLockedItem();
                return;
            }
    
            await submitAdSchedule({
                checklist_id: checklistId,
                ad_schedule: adSchedule[checklistId],
                user_email: userEmail
            });
    
            // Save parent item when conditional item is completed
            if (conditionalItems[checklistId]?.parent_id) {
                const parentItemId = conditionalItems[checklistId].parent_id;
                await submitMultipleChoice({
                    checklist_id: parentItemId,
                    selected_option: multipleChoice[parentItemId],
                    user_email: userEmail
                });
            }
    
            await addClientLog({
                user: userEmail,
                clientId: clientData.client._id,
                action: `Submitted ${checklistItem.item_name}`
            });
    
            // Update the completion info in the state
            const updatedClientData = { ...clientData };
            const updatedChecklists = updatedClientData.checklists.map(item => {
                if (item._id === checklistId) {
                    const editedInfo = {
                        edited_at: new Date().toISOString(),
                        edited_by: userEmail
                    };
    
                    if (item.completed_info?.completed_at) {
                        return {
                            ...item,
                            edited_info: item.edited_info ? [...item.edited_info, editedInfo] : [editedInfo]
                        };
                    } else {
                        return {
                            ...item,
                            completed_info: {
                                completed_at: new Date().toISOString(),
                                completed_by: userEmail
                            }
                        };
                    }
                }
                return item;
            });
            updatedClientData.checklists = updatedChecklists;
            setClientData(updatedClientData);
    
            setMessage('Ad schedule submitted successfully');
            setUnsavedChanges(false);
        } catch (error) {
            setMessage('Error submitting ad schedule');
        }
    };
    
    






    const handleBusinessHoursChange = (checklistId, day, timeType) => (e) => {
        setBusinessHours(prevBusinessHours => {
            const updatedBusinessHours = {
                ...prevBusinessHours,
                [checklistId]: {
                    ...prevBusinessHours[checklistId],
                    [day]: {
                        ...prevBusinessHours[checklistId]?.[day],
                        [timeType]: e.target.value
                    }
                }
            };

            // Check if all 7 days have valid values
            const allDaysFilled = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
                .every(day => {
                    const open = updatedBusinessHours[checklistId]?.[day]?.open;
                    const close = updatedBusinessHours[checklistId]?.[day]?.close;
                    return open?.trim() !== '' && (close?.trim() !== '' || open === 'Closed' || open === '24HR');
                });

            setIsEditing(prevIsEditing => ({
                ...prevIsEditing,
                [checklistId]: allDaysFilled // Only allow editing if all days are filled appropriately
            }));
            setUnsavedChanges(true);

            return updatedBusinessHours;
        });
    };






    const handleMultipleChoiceChange = (checklistId) => (e) => {
        const selectedOption = e.target.value;
        setMultipleChoice(prevMultipleChoice => ({
            ...prevMultipleChoice,
            [checklistId]: selectedOption
        }));
        setIsEditing(prevIsEditing => ({ ...prevIsEditing, [checklistId]: true }));
        setUnsavedChanges(true);
    };

    const handleCheckboxChange = (checklistId, option) => (e) => {
        setCheckboxes(prevCheckboxes => {
            const updatedCheckboxes = { ...prevCheckboxes };
            if (!updatedCheckboxes[checklistId]) {
                updatedCheckboxes[checklistId] = [];
            }
            if (e.target.checked) {
                updatedCheckboxes[checklistId].push(option);
            } else {
                updatedCheckboxes[checklistId] = updatedCheckboxes[checklistId].filter(item => item !== option);
            }
            setIsEditing(prevIsEditing => ({ ...prevIsEditing, [checklistId]: true }));
            setUnsavedChanges(true);
            return updatedCheckboxes;
        });
    };

    const handleContactInfoChange = (checklistId, index, field) => (e) => {
        setContactInfo(prevContactInfo => {
            const updatedContactInfo = { ...prevContactInfo };
            if (!updatedContactInfo[checklistId]) {
                updatedContactInfo[checklistId] = [];
            }
            if (!updatedContactInfo[checklistId][index]) {
                updatedContactInfo[checklistId][index] = { contactName: '', contactEmail: '', contactPhone: '' };
            }
            updatedContactInfo[checklistId][index][field] = e.target.value;

            const hasFullContact = updatedContactInfo[checklistId].some(contact =>
                contact.contactName.trim() &&
                contact.contactEmail.trim() &&
                contact.contactPhone.trim()
            );

            setIsEditing(prevIsEditing => ({ ...prevIsEditing, [checklistId]: hasFullContact }));
            setUnsavedChanges(true);
            return updatedContactInfo;
        });
    };


    const handleAddContactInfo = (checklistId) => {
        setContactInfo(prevContactInfo => ({
            ...prevContactInfo,
            [checklistId]: [
                ...(prevContactInfo[checklistId] || []),
                { contactName: '', contactEmail: '', contactPhone: '' }
            ]
        }));
        setIsEditing(prevIsEditing => ({ ...prevIsEditing, [checklistId]: true }));
        setUnsavedChanges(true);
    };

    const handleDeleteContactInfo = async (checklistId, index) => {
        try {
            const contact = contactInfo[checklistId][index];
            await deleteContactInfo({
                checklist_id: checklistId,
                contact_name: contact.contactName,
                contact_email: contact.contactEmail,
                contact_phone: contact.contactPhone,
                user_email: userEmail
            });
            setContactInfo(prevContactInfo => {
                const updatedContactInfo = { ...prevContactInfo };
                if (updatedContactInfo[checklistId]) {
                    updatedContactInfo[checklistId].splice(index, 1);
                }
                setUnsavedChanges(true);
                return updatedContactInfo;
            });
            setMessage('Contact info deleted successfully');
        } catch (error) {
            setMessage('Error deleting contact info');
        }
    };

    const getSubmittedFiles = (checklistId) => {
        return clientData.documents?.filter(doc => doc.checklist_id === checklistId) || [];
    };


    const handleMultipleTextChange = (checklistId, index) => (e) => {
        const value = e.target.value;
        setMultipleText(prevMultipleText => {
            const updatedMultipleText = { ...prevMultipleText };
            if (!updatedMultipleText[checklistId]) {
                updatedMultipleText[checklistId] = [''];
            }
            updatedMultipleText[checklistId][index] = value;
            setUnsavedChanges(true);
    
            // Update isEditing state based on the presence of text
            const hasText = updatedMultipleText[checklistId].some(textValue => textValue.trim() !== '');
            setIsEditing(prevIsEditing => ({ ...prevIsEditing, [checklistId]: hasText }));
    
            return updatedMultipleText;
        });
        adjustTextareaHeight(e.target);
    };

    const handleAddMultipleText = (checklistId) => {
        setMultipleText(prevMultipleText => ({
            ...prevMultipleText,
            [checklistId]: [
                ...(prevMultipleText[checklistId] || []),
                ''
            ]
        }));
        setIsEditing(prevIsEditing => ({ ...prevIsEditing, [checklistId]: true }));
        setUnsavedChanges(true);
    };

    const handleDeleteMultipleText = (checklistId, index) => {
        setMultipleText(prevMultipleText => {
            const updatedMultipleText = { ...prevMultipleText };
            if (updatedMultipleText[checklistId]) {
                updatedMultipleText[checklistId].splice(index, 1);
            }
            setIsEditing(prevIsEditing => ({ ...prevIsEditing, [checklistId]: true }));
            setUnsavedChanges(true);
            return updatedMultipleText;
        });
    };

    const handleUpload = async (checklistId, fileId) => {
        const formData = new FormData();
        formData.append('file', files[checklistId][fileId]);
        formData.append('client_id', clientData.client._id);
        formData.append('checklist_id', checklistId);
        formData.append('user_email', userEmail);

        try {
            await uploadDocument(formData, (event) => {
                const percentCompleted = Math.round((event.loaded * 100) / event.total);
                setProgress(prevProgress => ({
                    ...prevProgress,
                    [checklistId]: {
                        ...prevProgress[checklistId],
                        [fileId]: percentCompleted,
                    }
                }));
            });

            setMessage('Document uploaded successfully');

            // Clear the file entry after successful upload
            setFiles(prevFiles => {
                const updatedFiles = { ...prevFiles };
                if (updatedFiles[checklistId]) {
                    delete updatedFiles[checklistId][fileId];
                }
                return updatedFiles;
            });

            setProgress(prevProgress => {
                const updatedProgress = { ...prevProgress };
                if (updatedProgress[checklistId]) {
                    delete updatedProgress[checklistId][fileId];
                }
                return updatedProgress;
            });

            fetchClientData();
            setUnsavedChanges(false);
        } catch (error) {
            setMessage('Error uploading document');
        }
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const isValidPhone = (phone) => {
        // Updated phone regex to match standard US phone numbers
        const phoneRegex = /^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/;
        return phoneRegex.test(phone);
    };

    const validateInput = (checklistItem) => {
        if (checklistItem.type === 'text') {
            const textValue = text[checklistItem._id];
            if (checklistItem.text_input_type === 'email') {
                return isValidEmail(textValue);
            } else if (checklistItem.text_input_type === 'phone') {
                return isValidPhone(textValue);
            }
        } else if (checklistItem.type === 'multiple_text') {
            return multipleText[checklistItem._id].every(textValue => {
                if (checklistItem.text_input_type === 'email') {
                    return isValidEmail(textValue);
                } else if (checklistItem.text_input_type === 'phone') {
                    return isValidPhone(textValue);
                }
                return true;
            });
        }
        return true;
    };

    const saveUnsavedChanges = async () => {
        try {
            const savePromises = [];

            Object.keys(isEditing).forEach(checklistId => {
                if (isEditing[checklistId]) {
                    const checklistItem = clientData.checklists.find(item => item._id === checklistId);

                    // Skip invalid inputs
                    if (checklistItem.type === 'text') {
                        const textValue = text[checklistId];
                        if ((checklistItem.text_input_type === 'email' && !isValidEmail(textValue)) ||
                            (checklistItem.text_input_type === 'phone' && !isValidPhone(textValue))) {
                            return;
                        }
                    } else if (checklistItem.type === 'multiple_text') {
                        for (const textValue of multipleText[checklistId]) {
                            if ((checklistItem.text_input_type === 'email' && !isValidEmail(textValue)) ||
                                (checklistItem.text_input_type === 'phone' && !isValidPhone(textValue))) {
                                return;
                            }
                        }
                    }

                    if (checklistItem.type === 'text') {
                        savePromises.push(submitText({
                            checklist_id: checklistId,
                            text_value: text[checklistId],
                            user_email: userEmail
                        }));
                    } else if (checklistItem.type === 'business_hours') {
                        const formattedBusinessHours = {};
                        ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].forEach(day => {
                            const open = businessHours[checklistId]?.[day]?.open || '';
                            const close = businessHours[checklistId]?.[day]?.close || '';
                            formattedBusinessHours[day] = `Open: ${open}, Close: ${close}`;
                        });
                        savePromises.push(submitBusinessHours({
                            checklist_id: checklistId,
                            business_hours: formattedBusinessHours,
                            user_email: userEmail
                        }));
                    } else if (checklistItem.type === 'multiple_choice') {
                        savePromises.push(submitMultipleChoice({
                            checklist_id: checklistId,
                            selected_option: multipleChoice[checklistId],
                            user_email: userEmail
                        }));
                    } else if (checklistItem.type === 'checkboxes') {
                        savePromises.push(submitCheckboxes({
                            checklist_id: checklistId,
                            selected_options: checkboxes[checklistId] || [],
                            user_email: userEmail
                        }));
                    } else if (checklistItem.type === 'contact_info') {
                        contactInfo[checklistId].forEach((contact, index) => {
                            savePromises.push(addContactInfo({
                                checklist_id: checklistId,
                                contact_name: contact.contactName,
                                contact_email: contact.contactEmail,
                                contact_phone: contact.contactPhone,
                                user_email: userEmail
                            }));
                        });
                    } else if (checklistItem.type === 'multiple_text') {
                        savePromises.push(submitMultipleText({
                            checklist_id: checklistId,
                            text_values: multipleText[checklistId],
                            user_email: userEmail
                        }));
                    } else if (checklistItem.type === 'ad_schedule') {
                        savePromises.push(submitAdSchedule({
                            checklist_id: checklistId,
                            ad_schedule: adSchedule[checklistId],
                            user_email: userEmail
                        }));
                    } else if (checklistItem.type === 'checklist_text') {
                        savePromises.push(submitChecklistText({
                            checklist_id: checklistId,
                            checklist_text_values: checklistText[checklistId],
                            user_email: userEmail
                        }));
                    }
                }
            });

            await Promise.all(savePromises);
            setUnsavedChanges(false);
        } catch (error) {
            console.error('Error saving unsaved changes:', error);
        }
    };







    const handleLockedItem = () => {
        setModalMessage('This is a significant item and requires notifying Ferocious to make changes. Please contact Ferocious Media with any new changes to this item.');
        setShowModal(true);
    };

    const handleDeleteDocument = async (documentId) => {
        try {
            // Add your delete document logic here, for example:
            await deleteDocument(documentId); // Ensure you have a deleteDocument API call set up
            setMessage('Document deleted successfully');
            fetchClientData();
        } catch (error) {
            setMessage('Error deleting document');
        }
    };


    const handleTextSubmit = async (checklistId) => {
        try {
            const checklistItem = clientData.checklists.find(item => item._id === checklistId);
            if (checklistItem.locked && checklistItem.completed_info?.completed_at) {
                handleLockedItem();
                return;
            }
    
            // Validation logic
            const textValue = text[checklistId];
            if (checklistItem.text_input_type === 'email' && !isValidEmail(textValue)) {
                toast.error('Invalid email format');
                return;
            }
            if (checklistItem.text_input_type === 'phone' && !isValidPhone(textValue)) {
                toast.error('Invalid phone number format');
                return;
            }
    
            await submitText({
                checklist_id: checklistId,
                text_value: textValue,
                user_email: userEmail
            });
    
            // Save parent item when conditional item is completed
            if (conditionalItems[checklistId]?.parent_id) {
                const parentItemId = conditionalItems[checklistId].parent_id;
                await submitMultipleChoice({
                    checklist_id: parentItemId,
                    selected_option: multipleChoice[parentItemId],
                    user_email: userEmail
                });
            }
    
            await addClientLog({
                user: userEmail,
                clientId: clientData.client._id,
                action: `Submitted ${checklistItem.item_name}`
            });
    
            // Update the completion info in the state
            const updatedClientData = { ...clientData };
            const updatedChecklists = updatedClientData.checklists.map(item => {
                if (item._id === checklistId) {
                    const editedInfo = {
                        edited_at: new Date().toISOString(),
                        edited_by: userEmail
                    };
    
                    if (item.completed_info?.completed_at) {
                        return {
                            ...item,
                            edited_info: item.edited_info ? [...item.edited_info, editedInfo] : [editedInfo]
                        };
                    } else {
                        return {
                            ...item,
                            completed_info: {
                                completed_at: new Date().toISOString(),
                                completed_by: userEmail
                            }
                        };
                    }
                }
                return item;
            });
            updatedClientData.checklists = updatedChecklists;
            setClientData(updatedClientData);
    
            setMessage('Text submitted successfully');
            setUnsavedChanges(false);
        } catch (error) {
            setMessage('Error submitting text');
        }
    };
    
    
    



    const handleBusinessHoursSubmit = async (checklistId) => {
        try {
            const checklistItem = clientData.checklists.find(item => item._id === checklistId);
            if (checklistItem.locked && checklistItem.completed_info?.completed_at) {
                handleLockedItem();
                return;
            }
    
            const formattedBusinessHours = {};
            ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].forEach(day => {
                const open = businessHours[checklistId]?.[day]?.open || '';
                const close = businessHours[checklistId]?.[day]?.close || '';
                formattedBusinessHours[day] = `Open: ${open}, Close: ${close}`;
            });
    
            await submitBusinessHours({
                checklist_id: checklistId,
                business_hours: formattedBusinessHours,
                user_email: userEmail
            });
    
            // Save parent item when conditional item is completed
            if (conditionalItems[checklistId]?.parent_id) {
                const parentItemId = conditionalItems[checklistId].parent_id;
                await submitMultipleChoice({
                    checklist_id: parentItemId,
                    selected_option: multipleChoice[parentItemId],
                    user_email: userEmail
                });
            }
    
            await addClientLog({
                user: userEmail,
                clientId: clientData.client._id,
                action: `Submitted ${checklistItem.item_name}`
            });
    
            // Update the completion info in the state
            const updatedClientData = { ...clientData };
            const updatedChecklists = updatedClientData.checklists.map(item => {
                if (item._id === checklistId) {
                    const editedInfo = {
                        edited_at: new Date().toISOString(),
                        edited_by: userEmail
                    };
    
                    if (item.completed_info?.completed_at) {
                        return {
                            ...item,
                            edited_info: item.edited_info ? [...item.edited_info, editedInfo] : [editedInfo]
                        };
                    } else {
                        return {
                            ...item,
                            completed_info: {
                                completed_at: new Date().toISOString(),
                                completed_by: userEmail
                            }
                        };
                    }
                }
                return item;
            });
            updatedClientData.checklists = updatedChecklists;
            setClientData(updatedClientData);
    
            setMessage('Business hours submitted successfully');
            setUnsavedChanges(false);
        } catch (error) {
            setMessage('Error submitting business hours');
        }
    };
    
    



    const handleMultipleChoiceSubmit = async (checklistId) => {
        try {
            const checklistItem = clientData.checklists.find(item => item._id === checklistId);
            if (checklistItem.locked && checklistItem.completed_info?.completed_at) {
                handleLockedItem();
                return;
            }
            await submitMultipleChoice({
                checklist_id: checklistId,
                selected_option: multipleChoice[checklistId],
                user_email: userEmail
            });
    
            // Save parent item when conditional item is completed
            if (conditionalItems[checklistId]?.parent_id) {
                const parentItemId = conditionalItems[checklistId].parent_id;
                await submitMultipleChoice({
                    checklist_id: parentItemId,
                    selected_option: multipleChoice[parentItemId],
                    user_email: userEmail
                });
            }
    
            await addClientLog({
                user: userEmail,
                clientId: clientData.client._id,
                action: `Submitted ${checklistItem.item_name}`
            });
    
            // Update the completion info in the state
            const updatedClientData = { ...clientData };
            const updatedChecklists = updatedClientData.checklists.map(item => {
                if (item._id === checklistId) {
                    const editedInfo = {
                        edited_at: new Date().toISOString(),
                        edited_by: userEmail
                    };
    
                    if (item.completed_info?.completed_at) {
                        return {
                            ...item,
                            edited_info: item.edited_info ? [...item.edited_info, editedInfo] : [editedInfo]
                        };
                    } else {
                        return {
                            ...item,
                            completed_info: {
                                completed_at: new Date().toISOString(),
                                completed_by: userEmail
                            }
                        };
                    }
                }
                return item;
            });
            updatedClientData.checklists = updatedChecklists;
            setClientData(updatedClientData);
    
            setMessage('Multiple choice submitted successfully');
            setUnsavedChanges(false);
        } catch (error) {
            setMessage('Error submitting multiple choice');
        }
    };
    
    


    const handleCheckboxSubmit = async (checklistId) => {
        try {
            const checklistItem = clientData.checklists.find(item => item._id === checklistId);
            if (checklistItem.locked && checklistItem.completed_info?.completed_at) {
                handleLockedItem();
                return;
            }
            await submitCheckboxes({
                checklist_id: checklistId,
                selected_options: checkboxes[checklistId] || [],
                user_email: userEmail
            });
            await addClientLog({
                user: userEmail,
                clientId: clientData.client._id,
                action: `Submitted ${checklistItem.item_name}`
            });
    
            // Update the completion info in the state
            const updatedClientData = { ...clientData };
            const updatedChecklists = updatedClientData.checklists.map(item => {
                if (item._id === checklistId) {
                    const editedInfo = {
                        edited_at: new Date().toISOString(),
                        edited_by: userEmail
                    };
    
                    if (item.completed_info?.completed_at) {
                        return {
                            ...item,
                            edited_info: item.edited_info ? [...item.edited_info, editedInfo] : [editedInfo]
                        };
                    } else {
                        return {
                            ...item,
                            completed_info: {
                                completed_at: new Date().toISOString(),
                                completed_by: userEmail
                            }
                        };
                    }
                }
                return item;
            });
            updatedClientData.checklists = updatedChecklists;
            setClientData(updatedClientData);
    
            setMessage('Checkboxes submitted successfully');
            setUnsavedChanges(false);
        } catch (error) {
            setMessage('Error submitting checkboxes');
        }
    };
    
    

    const handleContactInfoSubmit = async (checklistId, index) => {
        try {
            const checklistItem = clientData.checklists.find(item => item._id === checklistId);
            if (checklistItem.locked && checklistItem.completed_info?.completed_at) {
                handleLockedItem();
                return;
            }
    
            const contact = contactInfo[checklistId][index];
            await addContactInfo({
                checklist_id: checklistId,
                contact_name: contact.contactName,
                contact_email: contact.contactEmail,
                contact_phone: contact.contactPhone,
                user_email: userEmail
            });
    
            // Save parent item when conditional item is completed
            if (conditionalItems[checklistId]?.parent_id) {
                const parentItemId = conditionalItems[checklistId].parent_id;
                await submitMultipleChoice({
                    checklist_id: parentItemId,
                    selected_option: multipleChoice[parentItemId],
                    user_email: userEmail
                });
            }
    
            await addClientLog({
                user: userEmail,
                clientId: clientData.client._id,
                action: `Submitted ${checklistItem.item_name}`
            });
    
            // Update the completion info in the state
            const updatedClientData = { ...clientData };
            const updatedChecklists = updatedClientData.checklists.map(item => {
                if (item._id === checklistId) {
                    const editedInfo = {
                        edited_at: new Date().toISOString(),
                        edited_by: userEmail
                    };
    
                    if (item.completed_info?.completed_at) {
                        return {
                            ...item,
                            edited_info: item.edited_info ? [...item.edited_info, editedInfo] : [editedInfo]
                        };
                    } else {
                        return {
                            ...item,
                            completed_info: {
                                completed_at: new Date().toISOString(),
                                completed_by: userEmail
                            }
                        };
                    }
                }
                return item;
            });
            updatedClientData.checklists = updatedChecklists;
            setClientData(updatedClientData);
    
            setMessage('Contact info submitted successfully');
            setUnsavedChanges(false);
        } catch (error) {
            setMessage('Error submitting contact info');
        }
    };
    
    


    const handleAddAdSchedule = (checklistId) => {
        setAdSchedule(prevAdSchedule => ({
            ...prevAdSchedule,
            [checklistId]: [
                ...(prevAdSchedule[checklistId] || []),
                { day: '', runAds: false, startTime: '', endTime: '' }
            ]
        }));
        setIsEditing(prevIsEditing => ({ ...prevIsEditing, [checklistId]: true }));
        setUnsavedChanges(true);
    };


    const handleMultipleTextSubmit = async (checklistId) => {
        try {
            const checklistItem = clientData.checklists.find(item => item._id === checklistId);
            if (checklistItem.locked && checklistItem.completed_info?.completed_at) {
                handleLockedItem();
                return;
            }
    
            // Validation logic
            for (const textValue of multipleText[checklistId]) {
                if (checklistItem.text_input_type === 'email' && !isValidEmail(textValue)) {
                    toast.error('Invalid email format');
                    return;
                }
                if (checklistItem.text_input_type === 'phone' && !isValidPhone(textValue)) {
                    toast.error('Invalid phone number format');
                    return;
                }
            }
    
            await submitMultipleText({
                checklist_id: checklistId,
                text_values: multipleText[checklistId],
                user_email: userEmail
            });
    
            // Save parent item when conditional item is completed
            if (conditionalItems[checklistId]?.parent_id) {
                const parentItemId = conditionalItems[checklistId].parent_id;
                await submitMultipleChoice({
                    checklist_id: parentItemId,
                    selected_option: multipleChoice[parentItemId],
                    user_email: userEmail
                });
            }
    
            await addClientLog({
                user: userEmail,
                clientId: clientData.client._id,
                action: `Submitted ${checklistItem.item_name}`
            });
    
            // Update the completion info in the state
            const updatedClientData = { ...clientData };
            const updatedChecklists = updatedClientData.checklists.map(item => {
                if (item._id === checklistId) {
                    const editedInfo = {
                        edited_at: new Date().toISOString(),
                        edited_by: userEmail
                    };
    
                    if (item.completed_info?.completed_at) {
                        return {
                            ...item,
                            edited_info: item.edited_info ? [...item.edited_info, editedInfo] : [editedInfo]
                        };
                    } else {
                        return {
                            ...item,
                            completed_info: {
                                completed_at: new Date().toISOString(),
                                completed_by: userEmail
                            }
                        };
                    }
                }
                return item;
            });
            updatedClientData.checklists = updatedChecklists;
            setClientData(updatedClientData);
    
            setMessage('Multiple text submitted successfully');
            setUnsavedChanges(false);
        } catch (error) {
            setMessage('Error submitting multiple text');
        }
    };
    
    


    const calculateDaysUntilDue = (dueDate) => {
        const currentDate = new Date();
        const due = new Date(dueDate);
        const timeDiff = due - currentDate;
        const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
        return daysDiff;
    };

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear().toString().slice(-2)}`;
    };

    const calculateProgress = () => {
        const totalItems = clientData.checklists?.length || 0;
        if (totalItems === 0) return 0;
        return (completedItems / totalItems) * 100;
    };

    const calculateSectionProgress = (section, groupedChecklists) => {
        const sectionItems = (groupedChecklists[section] || []).filter(item => item.type !== 'contact_info');
        if (sectionItems.length === 0) return 0;
    
        const optionalItems = sectionItems.filter(item => !item.optional);
        if (optionalItems.length === 0) return 0;
    
        const completedSectionItems = optionalItems.filter(item =>
            item.type === 'text' ? item.completed_info?.completed_at :
            item.type === 'business_hours' ? item.completed_info?.completed_at :
            item.type === 'multiple_choice' ? item.completed_info?.completed_at :
            item.type === 'checkboxes' ? item.completed_info?.completed_at :
            item.type === 'contact_info' ? item.completed_info?.completed_at :
            item.type === 'multiple_text' ? item.completed_info?.completed_at :
            item.type === 'ad_schedule' ? item.completed_info?.completed_at :
            item.type === 'checklist_text' ? item.checklist_text_values.some(v => v.checked && v.value?.trim() && item.completed_info?.completed_at) :
            isDocumentUploaded(item._id)
        ).length;
    
        return (completedSectionItems / optionalItems.length) * 100;
    };
    
    






    const isSectionCompleted = (section, groupedChecklists) => {
        const completed = groupedChecklists[section].every(item =>
            item.type === 'text' ? item.completed_info?.completed_at :
            item.type === 'business_hours' ? item.completed_info?.completed_at :
            item.type === 'multiple_choice' ? item.completed_info?.completed_at :
            item.type === 'checkboxes' ? item.completed_info?.completed_at :
            item.type === 'contact_info' ? item.completed_info?.completed_at :
            item.type === 'multiple_text' ? item.completed_info?.completed_at :
            item.type === 'ad_schedule' ? item.completed_info?.completed_at :
            item.type === 'checklist_text' ? item.checklist_text_values.some(v => v.checked && v.value?.trim() && item.completed_info?.completed_at) :
            isDocumentUploaded(item._id)
        );
    
        const clientID = clientData.client?._id; // Assuming you have clientData available in the scope
        const confettiKey = `confetti_${clientID}_${section}`;
        const confettiPlayed = JSON.parse(localStorage.getItem(confettiKey));
    
        if (completed && !confettiPlayed) {
            const end = Date.now() + (3 * 1000); // Play confetti for 3 seconds
            const colors = ['#bb00bb', '#660066', '#ff66ff', '#cc33cc', '#990099'];
    
            (function frame() {
                confetti({
                    particleCount: 2,
                    angle: 60,
                    spread: 55,
                    origin: { x: 0 },
                    colors: colors
                });
                confetti({
                    particleCount: 2,
                    angle: 120,
                    spread: 55,
                    origin: { x: 1 },
                    colors: colors
                });
    
                if (Date.now() < end) {
                    requestAnimationFrame(frame);
                }
            })();
    
            setConfettiDisplayed(prevState => ({
                ...prevState,
                [section]: true
            }));
    
            // Save the confetti state to local storage
            localStorage.setItem(confettiKey, JSON.stringify(true));
        }
    
        return completed;
    };
    
    
    
    
    




    const handleSectionToggle = (section) => {
        const newSection = openSection === section ? null : section;
        setOpenSection(newSection);

        // Scroll to section-questions if opening a new section
        if (newSection) {
            setTimeout(() => {
                const sectionQuestionsElement = document.querySelector('.section-questions');
                if (sectionQuestionsElement) {
                    const offset = 100; // Adjust this value as needed
                    const elementPosition = sectionQuestionsElement.getBoundingClientRect().top;
                    const offsetPosition = elementPosition + window.scrollY - offset;

                    window.scrollTo({
                        top: offsetPosition,
                        behavior: 'smooth'
                    });
                }
            }, 0); // No delay needed here
        }
    };



    const adjustTextareaHeight = (textarea) => {
        textarea.rows = Math.max(1, Math.floor(textarea.scrollHeight / 20)); // Assuming 20px per line height
    };

    useEffect(() => {
        if (openSection) {
            const textareas = document.querySelectorAll('textarea');
            textareas.forEach(adjustTextareaHeight);
        }
    }, [openSection]);

    useEffect(() => {
        if (openSection) {
            const sectionQuestionsElement = document.querySelector('.checklist-header');
            if (sectionQuestionsElement) {
                sectionQuestionsElement.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [openSection]);


    const groupedChecklists = clientData.checklists?.reduce((acc, item) => {
        if (!item.parent_item_id) {
            const [section, itemName] = item.item_name.split(' - ');
            if (!acc[section]) acc[section] = [];
            acc[section].push({ ...item, itemName });
        }
        return acc;
    }, {}) || {};

    const renderConditionalItems = (parentId) => {
        return Object.values(conditionalItems)
            .filter(item => item.parent_id === parentId && item.show_on === multipleChoice[parentId])
            .map(condItem => {
                return (
                    <div key={condItem._id} className="question-item conditional-item">
                        <div className="item-flex-container">
                            <div className="item-container">
                                <div className="item-name-container" style={{ marginLeft: condItem.completed_info?.completed_at ? '-28px' : '0' }}>
                                    {condItem.completed_info?.completed_at && <img src={checkIcon} alt="Completed" className='completed-checkmark' />}
                                    <strong className="item-name">{condItem.item_name}</strong>
                                </div>
                                <p className="item-description">{condItem.description}</p>
                            </div>
                            <div className="item-input">
                                {condItem.type === 'text' && (
                                    <div className="input-container">
                                        {condItem.text_input_type === 'plain' ? (
                                            <div className="quill-container">
                                                <div className="quill-wrapper">
                                                    <ReactQuill
                                                        value={text[condItem._id] || ''}
                                                        onChange={(value) => handleTextChange(condItem._id)({ target: { value } })}
                                                        modules={{ toolbar: `#toolbar-${condItem._id}` }}
                                                        placeholder="Text Input"
                                                    />
                                                    {isEditing[condItem._id] && <button className="button" onClick={() => handleTextSubmit(condItem._id)}>Submit</button>}
                                                </div>
                                                <div
                                                    id={`toolbar-${condItem._id}`}
                                                    className={`ql-toolbar ql-snow toolbar ${isEditing[condItem._id] ? 'visible' : ''}`}
                                                >
                                                    <span className="ql-formats">
                                                        <button className="ql-header" value="1"></button>
                                                        <button className="ql-header" value="2"></button>
                                                        <button className="ql-bold"></button>
                                                        <button className="ql-italic"></button>
                                                    </span>
                                                    <span className="ql-formats">
                                                        <button className="ql-list" value="ordered"></button>
                                                        <button className="ql-list" value="bullet"></button>
                                                    </span>
                                                    <span className="ql-formats">
                                                        <button className="ql-link"></button>
                                                        <button className="ql-image"></button>
                                                    </span>
                                                    <span className="ql-formats">
                                                        <button className="ql-clean"></button>
                                                    </span>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="textarea-wrapper">
                                                <textarea
                                                    value={text[condItem._id] || ''}
                                                    onChange={handleTextChange(condItem._id)}
                                                    placeholder={
                                                        condItem.text_input_type === 'phone' ? 'Phone Input' :
                                                            condItem.text_input_type === 'email' ? 'Email Input' :
                                                                'Text Input'
                                                    }
                                                    type={
                                                        condItem.text_input_type === 'phone' ? 'tel' :
                                                            condItem.text_input_type === 'email' ? 'email' :
                                                                'text'
                                                    }
                                                    rows={1}
                                                />
                                                {isEditing[condItem._id] && <button className="button" onClick={() => handleTextSubmit(condItem._id)}>Submit</button>}
                                            </div>
                                        )}
                                    </div>
                                )}
                                {condItem.type === 'file' && (
                                    <>
                                        {getSubmittedFiles(condItem._id).map((file) => (
                                            <div key={file._id} className='fileContainer' style={{ display: 'flex', alignItems: 'center' }}>
                                                {/* <a href={file.file_url} target="_blank" rel="noopener noreferrer" download>{file.file_name || 'Download Document'}</a> */}
                                                <a href={file.file_url} target="_blank" rel="noopener noreferrer" download>{file.file_name || 'Download Document'}</a>
                                                <span
                                                    className="deleteDocBtn material-icons"
                                                    style={{ marginLeft: '10px', fontSize: '15px', fontWeight: '600', cursor: 'pointer' }}
                                                    onClick={() => handleDeleteDocument(file._id)}
                                                >
                                                    close
                                                </span>
                                            </div>
                                        ))}
                                        {files[condItem._id] && Object.keys(files[condItem._id]).map((fileId) => (
                                            <div key={fileId} className='contact-input-container'>
                                                <input className='fileUpload' id={`fileInput-${condItem._id}-${fileId}`} type="file" onChange={handleFileChange(condItem._id, fileId)} />
                                                <label className="custom-file-upload" htmlFor={`fileInput-${condItem._id}-${fileId}`}>
                                                    <span className="material-symbols-outlined">upload</span>
                                                    Choose File
                                                </label>
                                                {files[condItem._id][fileId] && (
                                                    <span className={`file-name ${files[condItem._id][fileId] ? 'visible' : ''}`}>{files[condItem._id][fileId].name}</span>
                                                )}
                                                {progress[condItem._id] && progress[condItem._id][fileId] !== undefined ? (
                                                    <div className="upload-progress-container">
                                                        <div className="upload-progress-bar">
                                                            <div className="upload-progress" style={{ width: `${progress[condItem._id][fileId]}%` }}></div>
                                                        </div>
                                                        <span className="progress-percentage">
                                                            {progress[condItem._id][fileId] === 100 ? 99 : progress[condItem._id][fileId]}%
                                                        </span>
                                                    </div>
                                                ) : (
                                                    <button className="uploadBtn" onClick={() => handleUpload(condItem._id, fileId)} disabled={!files[condItem._id][fileId]}>Submit Document</button>
                                                )}
                                                <button className="deleteBtn" onClick={() => handleDeleteFileInput(condItem._id, fileId)}>Delete</button>
                                            </div>
                                        ))}
                                        <button className="button" onClick={() => handleAddFileInput(condItem._id)}>
                                            {getSubmittedFiles(condItem._id).length > 0 || (files[condItem._id] && Object.keys(files[condItem._id]).length > 0) ? "+ Add Additional File" : "+ Add File"}
                                        </button>
                                    </>
                                )}
                                {condItem.type === 'business_hours' && (
                                    <div className="business-hours-container">
                                        <table className="business-hours-table">
                                            <thead>
                                                <tr>
                                                    <th>Day</th>
                                                    <th>Opening Time</th>
                                                    <th>Closing Time</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map(day => (
                                                    <tr key={day}>
                                                        <td>{day.charAt(0).toUpperCase() + day.slice(1)}</td>
                                                        <td>
                                                            <select
                                                                value={businessHours[condItem._id]?.[day]?.open || ''}
                                                                onChange={(e) => handleBusinessHoursChange(condItem._id, day, 'open')(e)}
                                                            >
                                                                <option value="">Select Opening Time</option>
                                                                <option value="Closed">Closed</option>
                                                                <option value="24HR">24 Hours</option>
                                                                {Array.from({ length: 24 }, (_, hour) => (
                                                                    <option key={hour} value={`${hour % 12 || 12}:00 ${hour < 12 ? 'AM' : 'PM'}`}>{`${hour % 12 || 12}:00 ${hour < 12 ? 'AM' : 'PM'}`}</option>
                                                                ))}
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <select
                                                                value={businessHours[condItem._id]?.[day]?.close || ''}
                                                                onChange={(e) => handleBusinessHoursChange(condItem._id, day, 'close')(e)}
                                                                disabled={businessHours[condItem._id]?.[day]?.open === 'Closed' || businessHours[condItem._id]?.[day]?.open === '24HR'}
                                                            >
                                                                <option value="">Select Closing Time</option>
                                                                {getEndTimes(businessHours[condItem._id]?.[day]?.open || '').map((time, idx) => (
                                                                    <option key={idx} value={time}>{time}</option>
                                                                ))}
                                                            </select>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        {isEditing[condItem._id] && <button className="button" onClick={() => handleBusinessHoursSubmit(condItem._id)}>Submit</button>}
                                    </div>
                                )}
                                {condItem.type === 'contact_info' && (
                                    <>
                                        {contactInfo[condItem._id]?.map((contact, index) => (
                                            <div key={index} className="contact-input-container">
                                                <input
                                                    type="text"
                                                    value={contact.contactName || ''}
                                                    onChange={handleContactInfoChange(condItem._id, index, 'contactName')}
                                                    placeholder="Contact Name"
                                                />
                                                <input
                                                    type="email"
                                                    value={contact.contactEmail || ''}
                                                    onChange={handleContactInfoChange(condItem._id, index, 'contactEmail')}
                                                    placeholder="Contact Email"
                                                />
                                                <input
                                                    type="tel"
                                                    value={contact.contactPhone || ''}
                                                    onChange={handleContactInfoChange(condItem._id, index, 'contactPhone')}
                                                    placeholder="Contact Phone"
                                                />
                                                {isEditing[condItem._id] && <button className="button" onClick={() => handleContactInfoSubmit(condItem._id, index)}>Submit</button>}
                                                <button className="deleteBtn" onClick={() => handleDeleteContactInfo(condItem._id, index)}>Delete</button>
                                            </div>
                                        ))}
                                        <button className="button" onClick={() => handleAddContactInfo(condItem._id)}>+ Add Another Contact</button>
                                    </>
                                )}
                                {condItem.type === 'multiple_text' && (
                                    <>
                                        {multipleText[condItem._id]?.map((textValue, index) => (
                                            <div key={index} className="contact-input-container">
                                                <textarea
                                                    value={textValue || ''}
                                                    onChange={handleMultipleTextChange(condItem._id, index)}
                                                    placeholder={
                                                        condItem.text_input_type === 'phone' ? 'Phone Input' :
                                                            condItem.text_input_type === 'email' ? 'Email Input' :
                                                                'Text Input'
                                                    }
                                                    type={
                                                        condItem.text_input_type === 'phone' ? 'tel' :
                                                            condItem.text_input_type === 'email' ? 'email' :
                                                                'text'
                                                    }
                                                    rows={1}
                                                />
                                                {isEditing[condItem._id] && <button className="button" onClick={() => handleMultipleTextSubmit(condItem._id)}>Submit</button>}
                                                <button className="deleteBtn" onClick={() => handleDeleteMultipleText(condItem._id, index)}>Delete</button>
                                            </div>
                                        ))}
                                        <button className="button" onClick={() => handleAddMultipleText(condItem._id)}>+ Add Another</button>
                                    </>
                                )}
                                {condItem.type === 'ad_schedule' && (
                                    <div className="adScheduleContainer">
                                        <table className="adScheduleTable">
                                            <thead>
                                                <tr>
                                                    <th>Day of Week</th>
                                                    <th>Run Ads?</th>
                                                    <th>Start Time</th>
                                                    <th>End Time</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day, index) => (
                                                    <tr key={index}>
                                                        <td>{day}</td>
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                checked={adSchedule[condItem._id]?.[index]?.runAds || false}
                                                                onChange={(e) => handleAdScheduleChange(condItem._id, index, 'runAds')(e)}
                                                            />
                                                        </td>
                                                        <td>
                                                            <select
                                                                value={adSchedule[condItem._id]?.[index]?.startTime || ''}
                                                                onChange={(e) => handleAdScheduleChange(condItem._id, index, 'startTime')(e)}
                                                                disabled={!adSchedule[condItem._id]?.[index]?.runAds}
                                                            >
                                                                <option value="">Select Start Time</option>
                                                                <option value="24 Hours">24 Hours</option>
                                                                {Array.from({ length: 24 }, (_, hour) => (
                                                                    <option key={hour} value={`${hour % 12 || 12}:00 ${hour < 12 ? 'AM' : 'PM'}`}>{`${hour % 12 || 12}:00 ${hour < 12 ? 'AM' : 'PM'}`}</option>
                                                                ))}
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <select
                                                                value={adSchedule[condItem._id]?.[index]?.endTime || ''}
                                                                onChange={(e) => handleAdScheduleChange(condItem._id, index, 'endTime')(e)}
                                                                disabled={!adSchedule[condItem._id]?.[index]?.runAds || adSchedule[condItem._id]?.[index]?.startTime === '24 Hours'}
                                                            >
                                                                <option value="">Select End Time</option>
                                                                {getEndTimes(adSchedule[condItem._id]?.[index]?.startTime || '').map((time, idx) => (
                                                                    <option key={idx} value={time}>{time}</option>
                                                                ))}
                                                            </select>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        {isEditing[condItem._id] && <button className="button" onClick={() => handleAdScheduleSubmit(condItem._id)}>Submit</button>}
                                    </div>
                                )}
                                {condItem.type === 'checklist_text' && (
                                    <>
                                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                            <tbody>
                                                {condItem.options.map((option, index) => (
                                                    <tr key={index} style={{ borderBottom: '1px solid transparent' }}>
                                                        <td style={{ padding: '8px', width: '40px' }}>
                                                            <div className="checkbox-option">
                                                                <input
                                                                    type="checkbox"
                                                                    id={`${condItem._id}-${index}`}
                                                                    name={`${condItem._id}-${index}`}
                                                                    checked={checklistText[condItem._id]?.[index]?.checked || false}
                                                                    onChange={(e) => handleChecklistTextChange(condItem._id, index, 'checked', e.target.checked)}
                                                                />
                                                                <label htmlFor={`${condItem._id}-${index}`}></label>
                                                            </div>
                                                        </td>
                                                        <td style={{ padding: '8px' }}>
                                                            <label htmlFor={`${condItem._id}-${index}`} style={{ marginRight: '8px' }}>{option}</label>
                                                        </td>
                                                        <td style={{ padding: '8px', width: '100%' }}>
                                                            <input
                                                                type="text"
                                                                value={checklistText[condItem._id]?.[index]?.value || ''}
                                                                onChange={(e) => handleChecklistTextChange(condItem._id, index, 'value', e.target.value)}
                                                                placeholder="Text Input"
                                                                style={{
                                                                    width: '100%',
                                                                    backgroundColor: checklistText[condItem._id]?.[index]?.checked ? 'white' : '#e0e0e0',
                                                                    cursor: checklistText[condItem._id]?.[index]?.checked ? 'text' : 'not-allowed'
                                                                }}
                                                                disabled={!checklistText[condItem._id]?.[index]?.checked}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        {isEditing[condItem._id] && <button className="button" onClick={() => handleChecklistTextSubmit(condItem._id)}>Submit</button>}
                                    </>
                                )}
                                {condItem.completed_info?.completed_at && (
                                    <span className='completed-item-text'>Completed on: {formatDate(condItem.completed_info.completed_at)} by {condItem.completed_info.completed_by}</span>
                                )}
                                {condItem.edited_info?.map((edit, index) => (
                                    <span key={index} className='edited-item-text'>Edited on: {formatDate(edit.edited_at)} by {edit.edited_by}</span>
                                ))}
                            </div>
                        </div>
                    </div>
                );
            });
    };

    useEffect(() => {
        window.onSIApiReady = function () {
            console.log("Social Intents API is ready");
        };
    }, []);


    const handleChatClick = (e) => {
        e.preventDefault();
        if (window.SI_API && typeof window.SI_API.setChatInfo === 'function' && typeof window.SI_API.showPopup === 'function') {
            if (clientData.client && clientData.client.name) {
                window.SI_API.setChatInfo(userName, userEmail, clientData.client.name, clientData.client.name, "");
                console.log("Name: ", userName, "Email: ", userEmail, "Business Name: ", clientData.client.name, "Question: ", "");
                window.SI_API.showPopup();
            } else {
                console.error("Client data is not ready");
            }
        } else {
            console.error("Social Intents API is not ready");
        }
    };








    // Function to sort sections
    const sortSections = (sections) => {
        return sections.sort((a, b) => {
            const dueDateA = clientData.client?.due_dates?.find(d => d.section === a)?.due_date || '';
            const dueDateB = clientData.client?.due_dates?.find(d => d.section === b)?.due_date || '';

            const isCompletedA = isSectionCompleted(a, groupedChecklists);
            const isCompletedB = isSectionCompleted(b, groupedChecklists);

            if (isCompletedA && !isCompletedB) {
                return 1;
            } else if (!isCompletedA && isCompletedB) {
                return -1;
            }

            if (dueDateA && dueDateB) {
                const dateComparison = new Date(dueDateA) - new Date(dueDateB);
                if (dateComparison !== 0) {
                    return dateComparison;
                } else {
                    return a.localeCompare(b);
                }
            } else if (dueDateA) {
                return -1;
            } else if (dueDateB) {
                return 1;
            } else {
                return a.localeCompare(b);
            }
        });
    };

    const sortedSections = sortSections(Object.keys(groupedChecklists));

    return (
        <div className="client-view">
            <ClientNavBar userEmail={userEmail} clientName={clientData.client?.name} clientUrl={clientData.client?._id} userName={userName} />
            <ToastContainer className="custom-toast-container" />
            <div className="hero">
                <div className="hollow-circle"></div>
                <div className="hollow-circle-bottom"></div>
                <div className="hollow-circle-right"></div>
                <div className="hollow-circle-top"></div>
                <div className="circle"></div>
                <div className="circle2"></div>
                <div className="dot-array"></div>
                <div className="dot-array2"></div>
                <div className="hero-content">
                    <div className="title">
                        <div className="title_container">
                            <h1><span className='welcomeText'>Welcome To Ferocious,</span><br />
                                <span className="secondaryText">{clientData.client?.name}!</span></h1>
                        </div>
                    </div>
                    <div className="body-text">
                        <p>
                            {userName.split(' ')[0]}, in order to make the transition to Ferocious as simple and seamless as possible, we have put together this onboarding checklist specifically for {clientData.client?.name}. Please work through the items below and complete as many as possible.
                        </p>
                        <p>
                            This onboarding checklist is designed to help {clientData.client?.name} easily transition to Ferocious. Complete as many items as you can, and feel free to save your progress and return later.
                        </p>
                        <p>
                            If you have any questions, please email <a href={`mailto:${clientData.client?.ferociousEmailDistro}`}>{clientData.client?.ferociousEmailDistro}</a> or <a href="#" onClick={handleChatClick}>CHAT WITH US</a>
                        </p>
                    </div>
                </div>
            </div>
            <h4 className='instructions'>Please select a section below to begin adding required items.</h4>
            <h4 className='instructionsSecondLine'><i>NOTE:</i> Be SURE to hit the SUBMIT BUTTON to save your work after each line!</h4>
            <div className="checklist-sections">
                {sortedSections.map((section, index) => {
                    const dueDateInfo = clientData.client?.due_dates?.find(d => d.section === section);
                    const sectionCompleted = isSectionCompleted(section, groupedChecklists);
                    const isOpen = openSection === section;
                    const sectionProgress = calculateSectionProgress(section, groupedChecklists);
                    return (
                        <div
                            key={section}
                            className={`card ${isOpen ? 'open selected' : ''} ${sectionCompleted ? 'complete' : ''}`}
                            onClick={() => handleSectionToggle(section)}
                            style={{ animationDelay: `${index * 0.1}s` }} // Add staggered delay
                        >
                            <div className="card-content">
                                {sectionCompleted && (
                                    <div className="checkmark-circle">
                                        <span className="material-icons">check</span>
                                    </div>
                                )}
                                <h3>{section}</h3>
                                <p className="completion">{sectionProgress.toFixed(2)}% Complete</p>
                                <div className="progress-bar">
                                    <div className="progress" style={{ width: `${sectionProgress}%` }}></div>
                                </div>
                                {sectionCompleted ? (
                                    <p className="completed-date">Completed on {formatDate(new Date())}</p>
                                ) : dueDateInfo ? (
                                    <p className="due-date">Due: {formatDate(dueDateInfo.due_date)}</p>
                                ) : null}
                            </div>
                        </div>
                    );
                })}
            </div>

            {openSection && (
                <div className="section-questions">
                    <h4 className='checklist-header'>{openSection} Questions</h4>
                    <div className="question-list">
                        {groupedChecklists[openSection].flatMap((item) => {
                            const items = [
                                <div key={item._id} className="question-item">
                                    <div className="item-flex-container">
                                        <div className="item-container">
                                            <div className="item-name-container" style={{ marginLeft: item.completed_info?.completed_at ? '-28px' : '0' }}>
                                                {item.completed_info?.completed_at && <img src={checkIcon} alt="Completed" className='completed-checkmark' />}
                                                <strong className="item-name">{item.itemName}{!item.optional && ' *'}</strong> {/* Add asterisk for non-optional items */}
                                            </div>
                                            <p className="item-description">{item.description}</p>
                                        </div>
                                        <div className="item-input">
                                            {item.type === 'file' ? (
                                                <>
                                                    {getSubmittedFiles(item._id).map((file) => (
                                                        <div key={file._id} className='fileContainer' style={{ display: 'flex', alignItems: 'center' }}>
                                                            {/* <a href={file.file_url} target="_blank" rel="noopener noreferrer" download>{file.file_name || 'Download Document'}</a> */}
                                                            <a href={file.file_url} target="_blank" rel="noopener noreferrer" download>{file.file_name || 'Download Document'}</a>
                                                            <span
                                                                className="deleteDocBtn material-icons"
                                                                style={{ marginLeft: '10px', fontSize: '15px', fontWeight: '600', cursor: 'pointer' }}
                                                                onClick={() => handleDeleteDocument(file._id)}
                                                            >
                                                                close
                                                            </span>
                                                        </div>
                                                    ))}
                                                    {files[item._id] && Object.keys(files[item._id]).map((fileId) => (
                                                        <div key={fileId} className='contact-input-container'>
                                                            <input className='fileUpload' id={`fileInput-${item._id}-${fileId}`} type="file" onChange={handleFileChange(item._id, fileId)} />
                                                            <label className="custom-file-upload" htmlFor={`fileInput-${item._id}-${fileId}`}>
                                                                <span className="material-symbols-outlined">upload</span>
                                                                Choose File
                                                            </label>
                                                            {files[item._id][fileId] && (
                                                                <span className={`file-name ${files[item._id][fileId] ? 'visible' : ''}`}>{files[item._id][fileId].name}</span>
                                                            )}
                                                            {progress[item._id] && progress[item._id][fileId] !== undefined ? (
                                                                <div className="upload-progress-container">
                                                                    <div className="upload-progress-bar">
                                                                        <div className="upload-progress" style={{ width: `${progress[item._id][fileId]}%` }}></div>
                                                                    </div>
                                                                    <span className="progress-percentage">
                                                                        {progress[item._id][fileId] === 100 ? 99 : progress[item._id][fileId]}%
                                                                    </span>
                                                                </div>
                                                            ) : (
                                                                <button className="uploadBtn" onClick={() => handleUpload(item._id, fileId)} disabled={!files[item._id][fileId]}>Submit Document</button>
                                                            )}
                                                            <button className="deleteBtn" onClick={() => handleDeleteFileInput(item._id, fileId)}>Delete</button>
                                                        </div>
                                                    ))}
                                                    <button className="button" onClick={() => handleAddFileInput(item._id)}>
                                                        {getSubmittedFiles(item._id).length > 0 || (files[item._id] && Object.keys(files[item._id]).length > 0) ? "+ Add Additional File" : "+ Add File"}
                                                    </button>                                                </>
                                            ) : item.type === 'text' ? (
                                                <div className="input-container">
                                                    {item.text_input_type === 'plain' ? (
                                                        <div className="quill-container">
                                                            <div className="quill-wrapper">
                                                                <ReactQuill
                                                                    value={text[item._id] || ''}
                                                                    onChange={(value) => handleTextChange(item._id)({ target: { value } })}
                                                                    modules={{ toolbar: `#toolbar-${item._id}` }}
                                                                    placeholder="Text Input"
                                                                />
                                                                {isEditing[item._id] && <button className="button" onClick={() => handleTextSubmit(item._id)}>Submit</button>}
                                                            </div>
                                                            <div
                                                                id={`toolbar-${item._id}`}
                                                                className={`ql-toolbar ql-snow toolbar ${isEditing[item._id] ? 'visible' : ''}`}
                                                            >
                                                                <span className="ql-formats">
                                                                    <button className="ql-bold"></button>
                                                                    <button className="ql-italic"></button>
                                                                </span>
                                                                <span className="ql-formats">
                                                                    <button className="ql-list" value="ordered"></button>
                                                                    <button className="ql-list" value="bullet"></button>
                                                                </span>
                                                                <span className="ql-formats">
                                                                    <button className="ql-link"></button>
                                                                    <button className="ql-image"></button>
                                                                </span>
                                                                <span className="ql-formats">
                                                                    <button className="ql-clean"></button>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className='textarea-wrapper'>
                                                            <textarea
                                                                value={text[item._id] || ''}
                                                                onChange={handleTextChange(item._id)}
                                                                placeholder={
                                                                    item.text_input_type === 'phone' ? 'Phone Input' :
                                                                        item.text_input_type === 'email' ? 'Email Input' :
                                                                            'Text Input'
                                                                }
                                                                type={
                                                                    item.text_input_type === 'phone' ? 'tel' :
                                                                        item.text_input_type === 'email' ? 'email' :
                                                                            'text'
                                                                }
                                                                rows={1}
                                                            />
                                                            {isEditing[item._id] && <button className="button" onClick={() => handleTextSubmit(item._id)}>Submit</button>}
                                                        </div>
                                                    )}
                                                </div>
                                            ) : item.type === 'business_hours' ? (
                                                <div className="business-hours-container">
                                                    <table className="business-hours-table">
                                                        <thead>
                                                            <tr>
                                                                <th>Day</th>
                                                                <th>Opening Time</th>
                                                                <th>Closing Time</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map(day => (
                                                                <tr key={day}>
                                                                    <td>{day.charAt(0).toUpperCase() + day.slice(1)}</td>
                                                                    <td>
                                                                        <select
                                                                            value={businessHours[item._id]?.[day]?.open || ''}
                                                                            onChange={(e) => handleBusinessHoursChange(item._id, day, 'open')(e)}
                                                                        >
                                                                            <option value="">Select Opening Time</option>
                                                                            <option value="Closed">Closed</option>
                                                                            <option value="24HR">24 Hours</option>
                                                                            {Array.from({ length: 24 }, (_, hour) => (
                                                                                <option key={hour} value={`${hour % 12 || 12}:00 ${hour < 12 ? 'AM' : 'PM'}`}>{`${hour % 12 || 12}:00 ${hour < 12 ? 'AM' : 'PM'}`}</option>
                                                                            ))}
                                                                        </select>
                                                                    </td>
                                                                    <td>
                                                                        <select
                                                                            value={businessHours[item._id]?.[day]?.close || ''}
                                                                            onChange={(e) => handleBusinessHoursChange(item._id, day, 'close')(e)}
                                                                            disabled={businessHours[item._id]?.[day]?.open === 'Closed' || businessHours[item._id]?.[day]?.open === '24HR'}
                                                                        >
                                                                            <option value="">Select Closing Time</option>
                                                                            {getEndTimes(businessHours[item._id]?.[day]?.open || '').map((time, idx) => (
                                                                                <option key={idx} value={time}>{time}</option>
                                                                            ))}
                                                                        </select>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                    {isEditing[item._id] && <button className="button" onClick={() => handleBusinessHoursSubmit(item._id)}>Submit</button>}
                                                </div>

                                            ) : item.type === 'multiple_choice' ? (
                                                <div className="multiple-choice-container">
                                                    {item.options.map(option => (
                                                        <div key={option} className="multiple-choice-option">
                                                            <div>
                                                                <input
                                                                    type="radio"
                                                                    id={`${item._id}-${option}`}
                                                                    name={item._id}
                                                                    value={option}
                                                                    checked={multipleChoice[item._id] === option}
                                                                    onChange={handleMultipleChoiceChange(item._id)}
                                                                    className={item.completed_info?.completed_at ? 'submitted' : ''}
                                                                />
                                                                <label htmlFor={`${item._id}-${option}`}>{option}</label>
                                                            </div>
                                                        </div>
                                                    ))}
                                                    {isEditing[item._id] && <button className="button" onClick={() => handleMultipleChoiceSubmit(item._id)}>Submit</button>}
                                                </div>
                                            ) : item.type === 'checkboxes' ? (
                                                <div className="checkbox-container">
                                                    {item.options.map(option => (
                                                        <div key={option} className="checkbox-option">
                                                            <input
                                                                type="checkbox"
                                                                id={`${item._id}-${option}`}
                                                                name={item._id}
                                                                value={option}
                                                                checked={checkboxes[item._id]?.includes(option) || false}
                                                                onChange={handleCheckboxChange(item._id, option)}
                                                            />
                                                            <label htmlFor={`${item._id}-${option}`}>{option}</label>
                                                        </div>
                                                    ))}
                                                    {isEditing[item._id] && <button className="button" onClick={() => handleCheckboxSubmit(item._id)}>Submit</button>}
                                                </div>
                                            ) : item.type === 'contact_info' ? (
                                                <>
                                                    {contactInfo[item._id]?.map((contact, index) => (
                                                        <div key={index} className="contact-input-container">
                                                            <input
                                                                type="text"
                                                                value={contact.contactName || ''}
                                                                onChange={handleContactInfoChange(item._id, index, 'contactName')}
                                                                placeholder="Contact Name"
                                                            />
                                                            <input
                                                                type="email"
                                                                value={contact.contactEmail || ''}
                                                                onChange={handleContactInfoChange(item._id, index, 'contactEmail')}
                                                                placeholder="Contact Email"
                                                            />
                                                            <input
                                                                type="tel"
                                                                value={contact.contactPhone || ''}
                                                                onChange={handleContactInfoChange(item._id, index, 'contactPhone')}
                                                                placeholder="Contact Phone"
                                                            />
                                                            {isEditing[item._id] && <button className="button" onClick={() => handleContactInfoSubmit(item._id, index)}>Submit</button>}
                                                            <button className="deleteBtn" onClick={() => handleDeleteContactInfo(item._id, index)}>Delete</button>
                                                        </div>
                                                    ))}
                                                    <button className="button" onClick={() => handleAddContactInfo(item._id)}>+ Add Another Contact</button>
                                                </>
                                            ) : item.type === 'multiple_text' ? (
                                                <>
                                                    {multipleText[item._id]?.map((textValue, index) => (
                                                        <div key={index} className="contact-input-container">
                                                            <textarea
                                                                value={textValue || ''}
                                                                onChange={handleMultipleTextChange(item._id, index)}
                                                                placeholder={
                                                                    item.text_input_type === 'phone' ? 'Phone Input' :
                                                                        item.text_input_type === 'email' ? 'Email Input' :
                                                                            'Text Input'
                                                                }
                                                                type={
                                                                    item.text_input_type === 'phone' ? 'tel' :
                                                                        item.text_input_type === 'email' ? 'email' :
                                                                            'text'
                                                                }
                                                                rows={1}
                                                            />
                                                            {isEditing[item._id] && <button className="button" onClick={() => handleMultipleTextSubmit(item._id)}>Submit</button>}
                                                            <button className="deleteBtn" onClick={() => handleDeleteMultipleText(item._id, index)}>Delete</button>
                                                        </div>
                                                    ))}
                                                    <button className="button" onClick={() => handleAddMultipleText(item._id)}>+ Add Another</button>
                                                </>
                                            ) : item.type === 'ad_schedule' ? (
                                                <div className="adScheduleContainer">
                                                    <table className="adScheduleTable">
                                                        <thead>
                                                            <tr>
                                                                <th>Day of Week</th>
                                                                <th>Run Ads?</th>
                                                                <th>Start Time</th>
                                                                <th>End Time</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day, index) => (
                                                                <tr key={index}>
                                                                    <td>{day}</td>
                                                                    <td>
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={!!adSchedule[item._id]?.[index]?.runAds}
                                                                            onChange={(e) => handleAdScheduleChange(item._id, index, 'runAds')(e)}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <select
                                                                            value={adSchedule[item._id]?.[index]?.startTime || ''}
                                                                            onChange={(e) => handleAdScheduleChange(item._id, index, 'startTime')(e)}
                                                                            disabled={!adSchedule[item._id]?.[index]?.runAds}
                                                                        >
                                                                            <option value="">Select Start Time</option>
                                                                            <option value="24 Hours">24 Hours</option>
                                                                            {Array.from({ length: 24 }, (_, hour) => (
                                                                                <option key={hour} value={`${hour % 12 || 12}:00 ${hour < 12 ? 'AM' : 'PM'}`}>{`${hour % 12 || 12}:00 ${hour < 12 ? 'AM' : 'PM'}`}</option>
                                                                            ))}
                                                                        </select>
                                                                    </td>
                                                                    <td>
                                                                        <select
                                                                            value={adSchedule[item._id]?.[index]?.endTime || ''}
                                                                            onChange={(e) => handleAdScheduleChange(item._id, index, 'endTime')(e)}
                                                                            disabled={!adSchedule[item._id]?.[index]?.runAds || adSchedule[item._id]?.[index]?.startTime === '24 Hours'}
                                                                        >
                                                                            <option value="">Select End Time</option>
                                                                            {getEndTimes(adSchedule[item._id]?.[index]?.startTime || '').map((time, idx) => (
                                                                                <option key={idx} value={time}>{time}</option>
                                                                            ))}
                                                                        </select>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                    {isEditing[item._id] && <button className="button" onClick={() => handleAdScheduleSubmit(item._id)}>Submit</button>}
                                                </div>
                                            ) : item.type === 'checklist_text' ? (
                                                <>
                                                    <div className='checklist-text-container'>
                                                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                                            <tbody>
                                                                {item.options.map((option, index) => (
                                                                    <tr key={index} style={{ borderBottom: '1px solid transparent' }}>
                                                                        <td style={{ padding: '8px', width: '40px' }}>
                                                                            <div className="checkbox-option">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    id={`${item._id}-${index}`}
                                                                                    name={`${item._id}-${index}`}
                                                                                    checked={checklistText[item._id]?.[index]?.checked || false}
                                                                                    onChange={(e) => handleChecklistTextChange(item._id, index, 'checked', e.target.checked)}
                                                                                />
                                                                                <label htmlFor={`${item._id}-${index}`}></label>
                                                                            </div>
                                                                        </td>
                                                                        <td style={{ padding: '8px' }}>
                                                                            <label htmlFor={`${item._id}-${index}`} style={{ marginRight: '8px' }}>{option}</label>
                                                                        </td>
                                                                        <td style={{ padding: '8px', width: '100%' }}>
                                                                            <input
                                                                                type="text"
                                                                                value={checklistText[item._id]?.[index]?.value || ''}
                                                                                onChange={(e) => handleChecklistTextChange(item._id, index, 'value', e.target.value)}
                                                                                placeholder="Text Input"
                                                                                style={{
                                                                                    width: '100%',
                                                                                    backgroundColor: checklistText[item._id]?.[index]?.checked ? 'white' : '#e0e0e0',
                                                                                    cursor: checklistText[item._id]?.[index]?.checked ? 'text' : 'not-allowed'
                                                                                }}
                                                                                disabled={!checklistText[item._id]?.[index]?.checked}
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                        <div className='checklist-text-container'>
                                                            {isEditing[item._id] && <button className="submitButton" onClick={() => handleChecklistTextSubmit(item._id)}>Submit</button>}
                                                        </div>
                                                    </div>
                                                </>
                                            ) : null}

                                            {item.completed_info?.completed_at && <span className='completed-item-text'>Completed on: {formatDate(item.completed_info.completed_at)} by {item.completed_info.completed_by}</span>}
                                            {item.edited_info?.map((edit, index) => (
                                                <span key={index} className='edited-item-text'>Edited on: {formatDate(edit.edited_at)} by {edit.edited_by}</span>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            ];

                            // Insert conditional items right after their parent
                            const conditionalItemsToRender = renderConditionalItems(item._id);
                            if (conditionalItemsToRender.length > 0) {
                                items.push(...conditionalItemsToRender);
                            }

                            return items;
                        })}
                    </div>
                </div>
            )}

            {/* <h3>Uploaded Documents</h3>
            <ul>
                {clientData.documents?.map((doc) => (
                    <li key={doc._id} style={{ display: 'flex', alignItems: 'center' }}>
                        <a href={doc.file_url} target="_blank" rel="noopener noreferrer" download>
                            {doc.file_name || 'Download Document'}
                        </a>
                        <span
                            className="material-icons"
                            style={{ marginLeft: '10px', cursor: 'pointer' }}
                            onClick={() => handleDeleteDocument(doc._id)}
                        >
                            close
                        </span>
                    </li>
                ))}
            </ul> */}
            {message && <p>{message}</p>}

            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={() => setShowModal(false)}>&times;</span>
                        <p>{modalMessage}</p>
                    </div>
                </div>
            )}
            <UnsavedChangesModal
                show={showUnsavedChangesModal}
                onClose={() => setShowUnsavedChangesModal(false)}
            />

            <Footer clientName={clientData.client?.name} />
        </div>
    );
};

export default ClientView;


