import React, { useState, useEffect } from 'react';
import styles from './EmailComponent.module.css'; // Import the CSS module
import NavBar from './NavBar';
import Header from './Header';
import defaultImage from '../assets/blank_profile_pic.png';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import glassesLogo from '../assets/logos/FerociousGlasses.png';


import { getAllClients, getClientById, getTeamMembers, getOptions, createActivityLog, addEmailHistory, getEmailHistory } from '../api'; // Adjust the path if needed

const EmailComponent = () => {
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [allTeamMembers, setAllTeamMembers] = useState([]);
    const [isPurchasingWebsite, setIsPurchasingWebsite] = useState(false);
    const [strategistOptions, setStrategistOptions] = useState([]); // Define state variable
    const [managerOptions, setManagerOptions] = useState([]); // Define state variable
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [confirmMessage, setConfirmMessage] = useState('');
    const [emailPayload, setEmailPayload] = useState(null);
    const [emailHistory, setEmailHistory] = useState([]);

    const [clientInfo, setClientInfo] = useState({
        clientName: '',
        distro: '',
        contactEmails: ['']
    });

    const [personnel, setPersonnel] = useState(
        [...Array(11)].map(() => ({ name: '', title: '', imageUrl: '' }))
    );

    useEffect(() => {
        const fetchOptions = async () => {
            try {
                const response = await getOptions();
                console.log(response.data);

                setStrategistOptions(response.strategists);
                setManagerOptions(response.managers);
                console.log(managerOptions);
            } catch (error) {
                console.error('Error fetching options:', error);
            }
        };

        fetchOptions();
    }, []);

    useEffect(() => {
        if (selectedClient && !selectedClient.__isNew__) {
            // Fetch email history for the selected client
            const fetchEmailHistory = async () => {
                try {
                    const response = await getEmailHistory(selectedClient.value);
                    setEmailHistory(response.data);
                    console.log(emailHistory);
                } catch (error) {
                    console.error('Error fetching email history:', error);
                }
            };
            fetchEmailHistory();
        }
    }, [selectedClient]);

    useEffect(() => {
        // Fetch all clients and set them in state
        const fetchClients = async () => {
            try {
                const response = await getAllClients();
                const clientOptions = response.data.map(client => ({
                    value: client._id,
                    label: client.name
                }));
                setClients(clientOptions);
            } catch (error) {
                console.error('Error fetching clients:', error);
            }
        };

        fetchClients();
    }, []);

    useEffect(() => {
        // Fetch all team members for the dropdown
        const fetchTeamMembers = async () => {
            try {
                const response = await getTeamMembers();
                console.log('Team Members:', response.data); // Log the team members response for debugging
                const teamMembers = response.data.map(member => ({
                    value: member._id,
                    label: `${member.firstName} ${member.lastName}`,
                    title: member.title,
                    imageUrl: member.profilePicURL
                }));
                setAllTeamMembers(teamMembers);
                console.log('All Team Members:', teamMembers);
            } catch (error) {
                console.error('Error fetching team members:', error);
            }
        };

        fetchTeamMembers();
    }, []);

    const handleRemoveContact = (index) => {
        const newContactEmails = clientInfo.contactEmails.filter((_, i) => i !== index);
        if (newContactEmails.length === 0) {
            newContactEmails.push('');
        }
        setClientInfo((prevInfo) => ({
            ...prevInfo,
            contactEmails: newContactEmails
        }));
    };

    const ConfirmModal = ({ message, onConfirm, onCancel }) => (
        <div className={styles.modal}>
            <div className={styles.modalContent}>
                <img src={glassesLogo} alt="Ferocious Media" className={styles.glassesLogo} />
                <h2>Heads Up!</h2>
                <h4>This will send a welcome email to:</h4>
                <div dangerouslySetInnerHTML={{ __html: message }} />
                <div className={styles.buttonGroup}>
                    <button className={styles.closeModal} onClick={onCancel}>Cancel</button>
                    <button className={styles.confirmModal} onClick={onConfirm}>Confirm</button>
                </div>
            </div>
        </div>
    );

    const Modal = ({ message, onClose }) => (
        <div className={styles.modal}>
            <div className={styles.modalContent}>
                <span className={styles.close} onClick={onClose}>&times;</span>
                <p>{message}</p>
            </div>
        </div>
    );


    const handleSendEmail = async () => {

        const user = JSON.parse(localStorage.getItem('user'));
        const userEmail = user ? user.email : '';

        if (!userEmail) {
            setModalMessage('You need to be signed in to send emails.');
            setShowModal(true);
            return;
        }

        const payload = {
            clientName: clientInfo.clientName,
            distro: clientInfo.distro,
            isPurchasingWebsite: isPurchasingWebsite,
            accountManagerName: clientInfo.ferociousAccountStrategist,
            accountManagerEmail: strategistOptions.find(s => s.name === clientInfo.ferociousAccountStrategist)?.email || '',
            projectManagerName: isPurchasingWebsite ? clientInfo.ferociousProjectManager : '',
            projectManagerEmail: isPurchasingWebsite ? managerOptions.find(m => m.name === clientInfo.ferociousProjectManager)?.email || '' : '',
            contacts: clientInfo.contactEmails.map(email => ({ email })),
            person1Name: isPurchasingWebsite ? (personnel[2]?.name || '') : (personnel[0]?.name || ''),
            person1Title: isPurchasingWebsite ? (personnel[2]?.title || '') : (personnel[0]?.title || ''),
            person1URL: isPurchasingWebsite ? (personnel[2]?.imageUrl || '') : (personnel[0]?.imageUrl || ''),
            person2Name: isPurchasingWebsite ? (personnel[3]?.name || '') : (personnel[1]?.name || ''),
            person2Title: isPurchasingWebsite ? (personnel[3]?.title || '') : (personnel[1]?.title || ''),
            person2URL: isPurchasingWebsite ? (personnel[3]?.imageUrl || '') : (personnel[1]?.imageUrl || ''),
            person3Name: isPurchasingWebsite ? (personnel[4]?.name || '') : (personnel[2]?.name || ''),
            person3Title: isPurchasingWebsite ? (personnel[4]?.title || '') : (personnel[2]?.title || ''),
            person3URL: isPurchasingWebsite ? (personnel[4]?.imageUrl || '') : (personnel[2]?.imageUrl || ''),
            person4Name: isPurchasingWebsite ? (personnel[5]?.name || '') : (personnel[3]?.name || ''),
            person4Title: isPurchasingWebsite ? (personnel[5]?.title || '') : (personnel[3]?.title || ''),
            person4URL: isPurchasingWebsite ? (personnel[5]?.imageUrl || '') : (personnel[3]?.imageUrl || ''),
            person5Name: isPurchasingWebsite ? (personnel[6]?.name || '') : (personnel[4]?.name || ''),
            person5Title: isPurchasingWebsite ? (personnel[6]?.title || '') : (personnel[4]?.title || ''),
            person5URL: isPurchasingWebsite ? (personnel[6]?.imageUrl || '') : (personnel[4]?.imageUrl || ''),
            person6Name: isPurchasingWebsite ? (personnel[7]?.name || '') : (personnel[5]?.name || ''),
            person6Title: isPurchasingWebsite ? (personnel[7]?.title || '') : (personnel[5]?.title || ''),
            person6URL: isPurchasingWebsite ? (personnel[7]?.imageUrl || '') : (personnel[5]?.imageUrl || ''),
            person7Name: isPurchasingWebsite ? (personnel[8]?.name || '') : (personnel[6]?.name || ''),
            person7Title: isPurchasingWebsite ? (personnel[8]?.title || '') : (personnel[6]?.title || ''),
            person7URL: isPurchasingWebsite ? (personnel[8]?.imageUrl || '') : (personnel[6]?.imageUrl || ''),
            person8Name: isPurchasingWebsite ? (personnel[9]?.name || '') : (personnel[7]?.name || ''),
            person8Title: isPurchasingWebsite ? (personnel[9]?.title || '') : (personnel[7]?.title || ''),
            person8URL: isPurchasingWebsite ? (personnel[9]?.imageUrl || '') : (personnel[7]?.imageUrl || ''),
            person9Name: isPurchasingWebsite ? (personnel[10]?.name || '') : (personnel[8]?.name || ''),
            person9Title: isPurchasingWebsite ? (personnel[10]?.title || '') : (personnel[8]?.title || ''),
            person9URL: isPurchasingWebsite ? (personnel[10]?.imageUrl || '') : (personnel[8]?.imageUrl || ''),
            person10Name: isPurchasingWebsite ? (personnel[0]?.name || '') : (personnel[9]?.name || ''),
            person10Title: isPurchasingWebsite ? (personnel[0]?.title || '') : (personnel[9]?.title || ''),
            person10URL: isPurchasingWebsite ? (personnel[0]?.imageUrl || '') : (personnel[9]?.imageUrl || ''),
            person11Name: isPurchasingWebsite ? (personnel[1]?.name || '') : (personnel[10]?.name || ''),
            person11Title: isPurchasingWebsite ? (personnel[1]?.title || '') : (personnel[10]?.title || ''),
            person11URL: isPurchasingWebsite ? (personnel[1]?.imageUrl || '') : (personnel[10]?.imageUrl || ''),
        };

        console.log(payload.contacts);

        setEmailPayload(payload);


        const recipients = [
            clientInfo.distro,
            ...clientInfo.contactEmails,
        ].filter(email => email)
            .map(email => `<li>${email}</li>`)
            .join('');

        setConfirmMessage(`${recipients}`);
        setShowConfirmModal(true);

    };

    const sendEmail = async () => {
        try {
            const response = await fetch('https://7oebwbksllkwducjlti5kqy4gy0xlfrw.lambda-url.us-east-2.on.aws/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(emailPayload)
            });

            const recipients = [
                clientInfo.distro,
                ...clientInfo.contactEmails
            ].filter(email => email); // Filter out any empty or invalid emails

            const emailHistoryPayload = {
                sentBy: JSON.parse(localStorage.getItem('user')).email,
                timestamp: new Date().toISOString(),
                sentTo: recipients
            };

            if (response.ok) {
                const clientId = selectedClient ? selectedClient.value : null; // Check if selectedClient is set
                console.log(clientId);
                if (clientId) {
                    const emailHistory = await addEmailHistory(clientId, emailHistoryPayload); // Add the email history if clientId is available
                    console.log(emailHistory);
                }
                //TODO: maybe make this a specific client log?
                await createActivityLog({
                    user: JSON.parse(localStorage.getItem('user')).email,
                    action: `Sent team member introduction email to ${clientInfo.clientName}`,
                    date: new Date().toISOString()
                });
                setModalMessage('Email sent successfully');
                setShowModal(true);
            } else {
                setModalMessage('Error sending email');
                setShowModal(true);
            }
        } catch (error) {
            setModalMessage('Error sending email: ' + error.message);
            setShowModal(true);
        }
    };

    const handleConfirmSendEmail = () => {
        setShowConfirmModal(false);
        sendEmail();
    };

    const handleCancelSendEmail = () => {
        setShowConfirmModal(false);
    };


    const handleCheckboxChange = (e) => {
        setIsPurchasingWebsite(e.target.checked);
    };

    const handleClientChange = async (selectedOption) => {
        setSelectedClient(selectedOption);
        if (!selectedOption) {
            // Clear client info when deselected
            setClientInfo({
                clientName: '',
                distro: '',
                ferociousAccountStrategist: '',
                ferociousProjectManager: '',
                contactEmails: ['']
            });
            setSelectedClient(null);
            return;
        }

        // Check if the selected client is a custom client
        const isCustomClient = selectedOption.__isNew__;

        if (isCustomClient) {
            setClientInfo({
                clientName: selectedOption.label,
                distro: '',
                ferociousAccountStrategist: '',
                ferociousProjectManager: '',
                contactEmails: ['']
            });
        } else {
            try {
                const response = await getClientById(selectedOption.value);
                const client = response.data.client;
                console.log('Client Data:', client);

                const newClientInfo = {
                    clientName: client.name || '',
                    distro: client.ferociousEmailDistro || '',
                    ferociousAccountStrategist: client.ferociousAccountStrategist || '',
                    ferociousProjectManager: client.ferociousProjectManager || '',
                    contactEmails: client.contacts && client.contacts.length > 0
                        ? client.contacts.map(contact => contact.email || '')
                        : ['']
                };

                console.log('New Client Info:', newClientInfo);

                // Find and log the email of the account manager
                const accountManager = strategistOptions.find(option => option.name === client.ferociousAccountStrategist);
                console.log(strategistOptions);
                if (accountManager) {
                    console.log('Account Manager Email:', accountManager.email);
                }

                // If purchasing a website, find and log the email of the project manager
                if (isPurchasingWebsite) {
                    const projectManager = managerOptions.find(option => option.name === client.ferociousProjectManager);
                    if (projectManager) {
                        console.log('Project Manager Email:', projectManager.email);
                    }
                }

                setClientInfo(newClientInfo);
            } catch (error) {
                console.error('Error fetching client data:', error);
            }
        }
    };

    const handleClientInfoChange = (e) => {
        const { name, value } = e.target;
        setClientInfo((prevInfo) => ({
            ...prevInfo,
            [name]: value
        }));
    };

    const handlePersonnelChange = (index, field, value) => {
        setPersonnel(prevPersonnel => {
            const updatedPersonnel = prevPersonnel.map((person, i) =>
                i === index ? { ...person, [field]: value } : person
            );
            console.log(`Updating personnel at index ${index}, setting ${field} to ${value}`);
            console.log('Updated personnel:', updatedPersonnel);
            return updatedPersonnel;
        });
    };



    const handleAddContact = () => {
        setClientInfo((prevInfo) => ({
            ...prevInfo,
            contactEmails: [...prevInfo.contactEmails, '']
        }));
    };

    const handleContactEmailChange = (index, value) => {
        const newContactEmails = clientInfo.contactEmails.map((email, i) =>
            i === index ? value : email
        );
        setClientInfo((prevInfo) => ({
            ...prevInfo,
            contactEmails: newContactEmails
        }));
    };

    return (
        <>
            <NavBar />
            <div style={{ display: 'flex', height: 'fit-content', backgroundColor: '#ccc' }}>
                <div className={styles.leftDiv}>
                    <Header Title="Send" SecondaryTitle="Team Intro Email" />
                    <div className={styles.emailInfo}>
                        <div className={styles.clientInfo}>
                            <h2>Client Info</h2>
                            {selectedClient && !selectedClient.__isNew__ && (
                                <div className={styles.emailHistory}>
                                    <h2>Client Email Send History</h2>
                                    {emailHistory.length > 0 ? (
                                        <ul>
                                            {emailHistory.map((history, index) => (
                                                <li key={index} className={styles.emailHistoryItem}>
                                                    Email sent to {history.sentTo.join(', ')} on <b>{new Date(history.timestamp).toLocaleString('en-US', { timeZone: 'America/New_York' })}</b> EST by {history.sentBy}
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <p>No email history available.</p>
                                    )}
                                </div>
                            )}
                            <label>Client Name:</label>
                            <CreatableSelect
                                options={clients}
                                onChange={handleClientChange}
                                value={selectedClient}
                                placeholder="Select or enter a client"
                                isClearable
                                isSearchable
                                onCreateOption={(inputValue) => {
                                    const newClient = { label: inputValue, value: inputValue, __isNew__: true };
                                    setClients((prevClients) => [...prevClients, newClient]);
                                    handleClientChange(newClient);
                                }}
                            />
                            <div className={styles.checkboxContainer}>
                                <input
                                    type="checkbox"
                                    className={styles.checkboxOption}
                                    name="isPurchasingWebsite"
                                    onChange={handleCheckboxChange}
                                />
                                <label style={{ fontWeight: 'bold' }}> Client IS purchasing website</label>
                            </div>
                            <label>Distro:</label>
                            <input
                                type="text"
                                name="distro"
                                value={clientInfo.distro || ''}
                                onChange={handleClientInfoChange}
                            />
                            <label>Account Manager:</label>
                            <input
                                type="text"
                                name="ferociousAccountStrategist"
                                value={clientInfo.ferociousAccountStrategist || ''}
                                onChange={handleClientInfoChange}
                                readOnly={!selectedClient || !selectedClient.__isNew__}
                            />
                            {isPurchasingWebsite && (
                                <div>
                                    <label>Project Manager:</label>
                                    <input
                                        type="text"
                                        name="ferociousProjectManager"
                                        value={clientInfo.ferociousProjectManager || ''}
                                        onChange={handleClientInfoChange}
                                        readOnly={!selectedClient || !selectedClient.__isNew__}
                                    />
                                </div>
                            )}
                            {clientInfo.contactEmails.map((email, index) => (
                                <div key={index} className={styles.contactRow}>
                                    <label>Contact {index + 1} Email:</label>
                                    <div className={styles.contactInputRow}>
                                        <input
                                            type="email"
                                            value={email || ''}
                                            onChange={(e) => handleContactEmailChange(index, e.target.value)}
                                        />
                                        {clientInfo.contactEmails.length > 1 && (
                                            <button onClick={() => handleRemoveContact(index)} className={styles.removeButton}>
                                                <span className="material-icons" title='Remove Contact'>remove</span>
                                            </button>
                                        )}
                                    </div>
                                </div>
                            ))}


                            <button onClick={handleAddContact}>Add Another Contact</button>

                        </div>
                        <div className={styles.ferociousPersonnel}>
                            <h2>Ferocious Personnel</h2>
                            {[0, 1, 2, 3, 4, 5, 6, 7, 8].map(index => (
                                <div key={index} className={styles.personnelRow}>
                                    <Select
                                        options={allTeamMembers}
                                        onChange={(selectedOption) => {
                                            if (selectedOption) {
                                                console.log('Selected Option:', selectedOption); // Log the selected option for debugging
                                                handlePersonnelChange(index, 'name', selectedOption.label);
                                                handlePersonnelChange(index, 'title', selectedOption.title);
                                                handlePersonnelChange(index, 'imageUrl', selectedOption.imageUrl);
                                            } else {
                                                handlePersonnelChange(index, 'name', '');
                                                handlePersonnelChange(index, 'title', '');
                                                handlePersonnelChange(index, 'imageUrl', '');
                                            }
                                        }}
                                        value={allTeamMembers.find(member => member.label === personnel[index].name) || null}
                                        placeholder={`#${index + 1} Person`}
                                        className={styles.dropDown}
                                        isClearable
                                        isSearchable
                                    />
                                    <input
                                        type="text"
                                        placeholder="Title"
                                        value={personnel[index].title || ''}
                                        onChange={(e) => handlePersonnelChange(index, 'title', e.target.value)}
                                        readOnly
                                        className={styles.readOnlyInput}
                                    />
                                    <input
                                        type="text"
                                        placeholder="Image URL"
                                        value={personnel[index].imageUrl || ''}
                                        onChange={(e) => handlePersonnelChange(index, 'imageUrl', e.target.value)}
                                        readOnly
                                        className={styles.readOnlyInput}
                                    />
                                </div>
                            ))}
                            {isPurchasingWebsite && (
                                <>
                                    {[9, 10].map(index => (
                                        <div key={index} className={styles.personnelRow}>
                                            <Select
                                                options={allTeamMembers}
                                                onChange={(selectedOption) => {
                                                    if (selectedOption) {
                                                        console.log('Selected Option:', selectedOption); // Log the selected option for debugging
                                                        handlePersonnelChange(index, 'name', selectedOption.label);
                                                        handlePersonnelChange(index, 'title', selectedOption.title);
                                                        handlePersonnelChange(index, 'imageUrl', selectedOption.imageUrl);
                                                    } else {
                                                        handlePersonnelChange(index, 'name', '');
                                                        handlePersonnelChange(index, 'title', '');
                                                        handlePersonnelChange(index, 'imageUrl', '');
                                                    }
                                                }}
                                                value={allTeamMembers.find(member => member.label === personnel[index].name) || null}
                                                placeholder={`#${index + 1} Person`}
                                                className={styles.dropDown}
                                                isClearable
                                                isSearchable
                                            />
                                            <input
                                                type="text"
                                                placeholder="Title"
                                                value={personnel[index].title || ''}
                                                onChange={(e) => handlePersonnelChange(index, 'title', e.target.value)}
                                                readOnly
                                                className={styles.readOnlyInput}
                                            />
                                            <input
                                                type="text"
                                                placeholder="Image URL"
                                                value={personnel[index].imageUrl || ''}
                                                onChange={(e) => handlePersonnelChange(index, 'imageUrl', e.target.value)}
                                                readOnly
                                                className={styles.readOnlyInput}
                                            />
                                        </div>
                                    ))}
                                </>
                            )}
                        </div>
                        <button className={styles.sendEmailButton} onClick={handleSendEmail}>
                            <span className={styles.buttonText}>Send Email</span>
                            <span className={`material-icons ${styles.buttonArrow}`}>arrow_forward</span>
                        </button>
                    </div>
                </div>
                <div style={{ width: '50%', backgroundColor: '#f5f5f5', overflow: 'auto', marginTop: '90px' }}>
                    <div style={{ backgroundColor: '#f5f5f5', padding: '16px' }}>
                        <div style={{ background: '#fffffe', margin: '0 auto', minWidth: '614px', maxWidth: '614px' }}>
                            <div style={{ padding: '16px', textAlign: 'center' }}>
                                <img src="https://e.hypermatic.com/2199204a5a013ca45ba7cf251fc7d4d6.png" alt="" style={{ width: '132px' }} />
                            </div>
                        </div>
                        <div style={{ background: '#a33491 url("https://e.hypermatic.com/c16278e651be82291d9ac02808936093.png") center center / cover no-repeat', margin: '0 auto', minWidth: '614px', maxWidth: '614px' }}>
                            <div style={{ padding: '32px 32px 20px 32px', textAlign: 'left' }}>
                                <h1 style={{ fontFamily: "'proxima-nova','Arial',sans-serif", fontWeight: 700, color: '#ffffff', fontSize: '54px', lineHeight: '107%', marginTop: '0px' }}>Introducing your Ferocious Team!</h1>
                                <h2 style={{ fontFamily: "'proxima-nova','Arial',sans-serif", fontWeight: 600, color: '#ffffff', fontSize: '24px', lineHeight: '120%', paddingTop: '0px', marginTop: '10px' }}>{clientInfo.clientName || 'Client Name'}</h2>
                            </div>
                        </div>


                        {isPurchasingWebsite && (
                            <div style={{ background: '#000000', margin: '0 auto', maxWidth: '550px', minWidth: '550px', padding: '15px 32px 32px 32px', textAlign: 'left', paddingBottom: '10px' }}>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <div style={{ textAlign: 'center', width: '33%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <img src={isPurchasingWebsite ? (personnel[0].imageUrl || defaultImage) : (personnel[9].imageUrl || defaultImage)} alt="" style={{
                                            width: '180px',
                                            marginBottom: '10px',
                                            border: 'none',
                                            boxShadow: 'none', // Adjust the glow effect here
                                            borderRadius: '190px' // Optional: if you want rounded corners
                                        }} />
                                        <h3 style={{ fontFamily: "'proxima-nova','Arial',sans-serif", fontWeight: 700, color: '#ffffff', fontSize: '18px', lineHeight: '150%', marginTop: '0px', marginBottom: '0px' }}>{isPurchasingWebsite ? (personnel[0].name || 'Person 1 Name') : (personnel[9].name || 'Person 10 Name')}</h3>
                                        <p style={{ fontFamily: "'proxima-nova','Arial',sans-serif", fontWeight: 500, color: '#ffffff', fontSize: '14px', lineHeight: '150%', marginTop: '0px', maxWidth: '180px' }}>{isPurchasingWebsite ? (personnel[0].title || 'Person 1 Title') : (personnel[9].title || 'Person 10 Title')}</p>
                                    </div>
                                    <div style={{ textAlign: 'center', width: '33%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '30px' }}>
                                        <img
                                            src={isPurchasingWebsite ? (personnel[1].imageUrl || defaultImage) : (personnel[10].imageUrl || defaultImage)}
                                            alt=""
                                            style={{
                                                width: '180px',
                                                marginBottom: '10px',
                                                border: 'none',
                                                boxShadow: 'none', // Adjust the glow effect here
                                                borderRadius: '190px' // Optional: if you want rounded corners
                                            }}
                                        />
                                        <h3 style={{ fontFamily: "'proxima-nova','Arial',sans-serif", fontWeight: 700, color: '#ffffff', fontSize: '18px', lineHeight: '150%', marginTop: '0px', marginBottom: '0px' }}>{isPurchasingWebsite ? (personnel[1].name || 'Person 2 Name') : (personnel[10].name || 'Person 11 Name')}</h3>
                                        <p style={{ fontFamily: "'proxima-nova','Arial',sans-serif", fontWeight: 500, color: '#ffffff', fontSize: '14px', lineHeight: '150%', marginTop: '0px', maxWidth: '180px' }}>{isPurchasingWebsite ? (personnel[1].title || 'Person 2 Title') : (personnel[10].title || 'Person 11 Title')}</p>
                                    </div>
                                </div>
                            </div>
                        )}





                        <div style={{ background: '#000000', margin: '0 auto', maxWidth: '550px', minWidth: '550px', padding: '15px 32px 32px 32px', textAlign: 'left' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ textAlign: 'center', width: '33%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <img
                                        src={isPurchasingWebsite ? (personnel[2].imageUrl || defaultImage) : (personnel[0].imageUrl || defaultImage)}
                                        alt=""
                                        style={{
                                            width: '180px',
                                            marginBottom: '10px',
                                            border: 'none',
                                            boxShadow: 'none', // Adjust the glow effect here
                                            borderRadius: '190px' // Optional: if you want rounded corners
                                        }}
                                    />
                                    <h3 style={{ fontFamily: "'proxima-nova','Arial',sans-serif", fontWeight: 700, color: '#ffffff', fontSize: '18px', lineHeight: '150%', marginTop: '0px', marginBottom: '0px' }}>{isPurchasingWebsite ? (personnel[2].name || 'Person 3 Name') : (personnel[0].name || 'Person 1 Name')}                                    </h3>
                                    <p style={{ fontFamily: "'proxima-nova','Arial',sans-serif", fontWeight: 500, color: '#ffffff', fontSize: '14px', lineHeight: '150%', marginTop: '0px', maxWidth: '180px' }}>{isPurchasingWebsite ? (personnel[2].title || 'Person 3 Title') : (personnel[0].title || 'Person 1 Title')}                                    </p>
                                </div>
                                <div style={{ textAlign: 'center', width: '33%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <img src={isPurchasingWebsite ? (personnel[3].imageUrl || defaultImage) : (personnel[1].imageUrl || defaultImage)}
                                        alt=""
                                        style={{
                                            width: '180px',
                                            marginBottom: '10px',
                                            border: 'none',
                                            boxShadow: 'none', // Adjust the glow effect here
                                            borderRadius: '190px' // Optional: if you want rounded corners
                                        }} />
                                    <h3 style={{ fontFamily: "'proxima-nova','Arial',sans-serif", fontWeight: 700, color: '#ffffff', fontSize: '18px', lineHeight: '150%', marginTop: '0px', marginBottom: '0px' }}>{isPurchasingWebsite ? (personnel[3].name || 'Person 4 Name') : (personnel[1].name || 'Person 2 Name')}                                    </h3>
                                    <p style={{ fontFamily: "'proxima-nova','Arial',sans-serif", fontWeight: 500, color: '#ffffff', fontSize: '14px', lineHeight: '150%', marginTop: '0px', maxWidth: '180px' }}>{isPurchasingWebsite ? (personnel[3].title || 'Person 4 Title') : (personnel[1].title || 'Person 2 Title')}                                    </p>
                                </div>
                                <div style={{ textAlign: 'center', width: '33%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <img
                                        src={isPurchasingWebsite ? (personnel[4].imageUrl || defaultImage) : (personnel[2].imageUrl || defaultImage)}
                                        alt=""
                                        style={{
                                            width: '180px',
                                            marginBottom: '10px',
                                            border: 'none',
                                            boxShadow: 'none', // Adjust the glow effect here
                                            borderRadius: '190px' // Optional: if you want rounded corners
                                        }}
                                    />
                                    <h3 style={{ fontFamily: "'proxima-nova','Arial',sans-serif", fontWeight: 700, color: '#ffffff', fontSize: '18px', lineHeight: '150%', marginTop: '0px', marginBottom: '0px' }}>{isPurchasingWebsite ? (personnel[4].name || 'Person 5 Name') : (personnel[2].name || 'Person 3 Name')}                                    </h3>
                                    <p style={{ fontFamily: "'proxima-nova','Arial',sans-serif", fontWeight: 500, color: '#ffffff', fontSize: '14px', lineHeight: '150%', marginTop: '0px', maxWidth: '180px' }}>{isPurchasingWebsite ? (personnel[4].title || 'Person 5 Title') : (personnel[2].title || 'Person 3 Title')}                                    </p>
                                </div>
                            </div>
                        </div>
                        <div style={{ background: '#000000', margin: '0 auto', maxWidth: '550px', minWidth: '550px', padding: '15px 32px 32px 32px', textAlign: 'left' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ textAlign: 'center', width: '33%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <img src={isPurchasingWebsite ? (personnel[5].imageUrl || defaultImage) : (personnel[3].imageUrl || defaultImage)}
                                        alt="" style={{
                                            width: '180px',
                                            marginBottom: '10px',
                                            border: 'none',
                                            boxShadow: 'none', // Adjust the glow effect here
                                            borderRadius: '190px' // Optional: if you want rounded corners
                                        }} />
                                    <h3 style={{ fontFamily: "'proxima-nova','Arial',sans-serif", fontWeight: 700, color: '#ffffff', fontSize: '18px', lineHeight: '150%', marginTop: '0px', marginBottom: '0px' }}>{isPurchasingWebsite ? (personnel[5].name || 'Person 6 Name') : (personnel[3].name || 'Person 4 Name')}                                    </h3>
                                    <p style={{ fontFamily: "'proxima-nova','Arial',sans-serif", fontWeight: 500, color: '#ffffff', fontSize: '14px', lineHeight: '150%', marginTop: '0px', maxWidth: '180px' }}>{isPurchasingWebsite ? (personnel[5].title || 'Person 6 Title') : (personnel[3].title || 'Person 4 Title')}                                    </p>
                                </div>
                                <div style={{ textAlign: 'center', width: '33%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <img src={isPurchasingWebsite ? (personnel[6].imageUrl || defaultImage) : (personnel[4].imageUrl || defaultImage)}
                                        alt="" style={{
                                            width: '180px',
                                            marginBottom: '10px',
                                            border: 'none',
                                            boxShadow: 'none', // Adjust the glow effect here
                                            borderRadius: '190px' // Optional: if you want rounded corners
                                        }} />
                                    <h3 style={{ fontFamily: "'proxima-nova','Arial',sans-serif", fontWeight: 700, color: '#ffffff', fontSize: '18px', lineHeight: '150%', marginTop: '0px', marginBottom: '0px' }}>{isPurchasingWebsite ? (personnel[6].name || 'Person 7 Name') : (personnel[4].name || 'Person 5 Name')}                                    </h3>
                                    <p style={{ fontFamily: "'proxima-nova','Arial',sans-serif", fontWeight: 500, color: '#ffffff', fontSize: '14px', lineHeight: '150%', marginTop: '0px', maxWidth: '180px' }}>{isPurchasingWebsite ? (personnel[6].title || 'Person 7 Title') : (personnel[4].title || 'Person 5 Title')}                                    </p>
                                </div>
                                <div style={{ textAlign: 'center', width: '33%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <img src={isPurchasingWebsite ? (personnel[7].imageUrl || defaultImage) : (personnel[5].imageUrl || defaultImage)}
                                        alt="" style={{
                                            width: '180px',
                                            marginBottom: '10px',
                                            border: 'none',
                                            boxShadow: 'none', // Adjust the glow effect here
                                            borderRadius: '190px' // Optional: if you want rounded corners
                                        }} />
                                    <h3 style={{ fontFamily: "'proxima-nova','Arial',sans-serif", fontWeight: 700, color: '#ffffff', fontSize: '18px', lineHeight: '150%', marginTop: '0px', marginBottom: '0px' }}>{isPurchasingWebsite ? (personnel[7].name || 'Person 8 Name') : (personnel[5].name || 'Person 6 Name')} </h3>
                                    <p style={{ fontFamily: "'proxima-nova','Arial',sans-serif", fontWeight: 500, color: '#ffffff', fontSize: '14px', lineHeight: '150%', marginTop: '0px', maxWidth: '180px' }}>{isPurchasingWebsite ? (personnel[7].title || 'Person 8 Title') : (personnel[5].title || 'Person 6 Title')}                                    </p>
                                </div>
                            </div>
                        </div>
                        <div style={{ background: '#000000', margin: '0 auto', maxWidth: '550px', minWidth: '550px', padding: '15px 32px 32px 32px', textAlign: 'left' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ textAlign: 'center', width: '33%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <img src={isPurchasingWebsite ? (personnel[8].imageUrl || defaultImage) : (personnel[6].imageUrl || defaultImage)}
                                        alt="" style={{
                                            width: '180px',
                                            marginBottom: '10px',
                                            border: 'none',
                                            boxShadow: 'none', // Adjust the glow effect here
                                            borderRadius: '190px' // Optional: if you want rounded corners
                                        }} />
                                    <h3 style={{ fontFamily: "'proxima-nova','Arial',sans-serif", fontWeight: 700, color: '#ffffff', fontSize: '18px', lineHeight: '150%', marginTop: '0px', marginBottom: '0px' }}>{isPurchasingWebsite ? (personnel[8].name || 'Person 9 Name') : (personnel[6].name || 'Person 7 Name')}</h3>
                                    <p style={{ fontFamily: "'proxima-nova','Arial',sans-serif", fontWeight: 500, color: '#ffffff', fontSize: '14px', lineHeight: '150%', marginTop: '0px', maxWidth: '180px' }}>{isPurchasingWebsite ? (personnel[8].title || 'Person 9 Title') : (personnel[6].title || 'Person 7 Title')}                                    </p>
                                </div>
                                <div style={{ textAlign: 'center', width: '33%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <img src={isPurchasingWebsite ? (personnel[9].imageUrl || defaultImage) : (personnel[7].imageUrl || defaultImage)}
                                        alt="" style={{
                                            width: '180px',
                                            marginBottom: '10px',
                                            border: 'none',
                                            boxShadow: 'none', // Adjust the glow effect here
                                            borderRadius: '190px' // Optional: if you want rounded corners
                                        }} />
                                    <h3 style={{ fontFamily: "'proxima-nova','Arial',sans-serif", fontWeight: 700, color: '#ffffff', fontSize: '18px', lineHeight: '150%', marginTop: '0px', marginBottom: '0px' }}>{isPurchasingWebsite ? (personnel[9].name || 'Person 10 Name') : (personnel[7].name || 'Person 8 Name')}</h3>
                                    <p style={{ fontFamily: "'proxima-nova','Arial',sans-serif", fontWeight: 500, color: '#ffffff', fontSize: '14px', lineHeight: '150%', marginTop: '0px', maxWidth: '180px' }}>{isPurchasingWebsite ? (personnel[9].title || 'Person 10 Title') : (personnel[7].title || 'Person 8 Title')}                                    </p>
                                </div>
                                <div style={{ textAlign: 'center', width: '33%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <img src={isPurchasingWebsite ? (personnel[10].imageUrl || defaultImage) : (personnel[8].imageUrl || defaultImage)} alt="" style={{
                                        width: '180px',
                                        marginBottom: '10px',
                                        border: 'none',
                                        boxShadow: 'none', // Adjust the glow effect here
                                        borderRadius: '190px' // Optional: if you want rounded corners
                                    }} />
                                    <h3 style={{ fontFamily: "'proxima-nova','Arial',sans-serif", fontWeight: 700, color: '#ffffff', fontSize: '18px', lineHeight: '150%', marginTop: '0px', marginBottom: '0px' }}>{isPurchasingWebsite ? (personnel[10].name || 'Person 11 Name') : (personnel[8].name || 'Person 9 Name')}</h3>
                                    <p style={{ fontFamily: "'proxima-nova','Arial',sans-serif", fontWeight: 500, color: '#ffffff', fontSize: '14px', lineHeight: '150%', marginTop: '0px', maxWidth: '180px' }}>{isPurchasingWebsite ? (personnel[10].title || 'Person 11 Title') : (personnel[8].title || 'Person 9 Title')}</p>
                                </div>
                            </div>
                        </div>

                        <div style={{ background: '#000000', margin: '0px auto', maxWidth: '550px', padding: '23px 32px 7px 32px', textAlign: 'left' }}>
                            <p style={{ borderTop: 'solid 1px #cccccc', fontSize: '1px', margin: '0px auto', width: '100%' }}></p>
                            <div style={{ textAlign: 'left' }}>
                                <p style={{ fontSize: '17px', fontFamily: "'proxima-nova','Arial',sans-serif", fontWeight: '700', color: '#ffffff', lineHeight: '153%', marginBottom: '4px' }}>
                                    WHAT THIS MEANS FOR {clientInfo.clientName.toUpperCase() || "CLIENT NAME"}
                                </p>
                            </div>
                        </div>
                        <div style={{ background: '#000000', margin: '0px auto', maxWidth: '531px', padding: '5px 32px 37px 50px', textAlign: 'left' }}>
                            <div style={{ textAlign: 'left' }}>
                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                                    <img alt="" width="24" height="24" style={{ display: 'block', marginRight: '8px' }} src="https://e.hypermatic.com/a08aa10212b0c5239725f44c95f139fc.png" />
                                    <p style={{ margin: '0', fontSize: '16px', fontFamily: "'proxima-nova','Arial',sans-serif", fontWeight: '400', color: '#ffffff', lineHeight: '150%' }}>
                                        Dedicated Support from a larger support team
                                    </p>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                                    <img alt="" width="24" height="24" style={{ display: 'block', marginRight: '8px' }} src="https://e.hypermatic.com/a08aa10212b0c5239725f44c95f139fc.png" />
                                    <p style={{ margin: '0', fontSize: '16px', fontFamily: "'proxima-nova','Arial',sans-serif", fontWeight: '400', color: '#ffffff', lineHeight: '150%' }}>
                                        Access to broader expertise across all aspects of digital marketing
                                    </p>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img alt="" width="24" height="24" style={{ display: 'block', marginRight: '8px' }} src="https://e.hypermatic.com/a08aa10212b0c5239725f44c95f139fc.png" />
                                    <p style={{ margin: '0', fontSize: '16px', fontFamily: "'proxima-nova','Arial',sans-serif", fontWeight: '400', color: '#ffffff', lineHeight: '150%' }}>
                                        Continued Personalized Attention
                                    </p>
                                </div>
                            </div>
                        </div>


                        <div style={{ background: '#ffdffa url("https://e.hypermatic.com/e71d9aea93a51dfa11ca5597b8fa502f.png") center center / cover no-repeat', margin: '0 auto', maxWidth: '580px', padding: '22px 16px 16px 16px', textAlign: 'left' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div style={{ textAlign: 'center' }}>
                                    <img src="https://e.hypermatic.com/48bd1d34a82931d327b3c8949fd456cd.png" alt="" style={{ width: '282px' }} />
                                </div>
                                <div style={{ textAlign: 'left', marginLeft: '20px' }}> {/* Adjust the marginLeft value as needed */}
                                    <h3 style={{ fontFamily: "'proxima-nova','Arial',sans-serif", fontWeight: 700, color: '#000000', fontSize: '17px', lineHeight: '124%' }}>GETTING IN TOUCH</h3>
                                    <p style={{ fontFamily: "'proxima-nova','Arial',sans-serif", fontWeight: 500, color: '#545454', fontSize: '14px', lineHeight: '150%' }}>
                                        {isPurchasingWebsite ? (
                                            `Your Website Project Manager, ${clientInfo.ferociousProjectManager ? clientInfo.ferociousProjectManager.split(' ')[0] : 'Project Manager'}, will personally reach out to introduce themselves and set up a first meeting to get your new Website Design, in the next few days and get the ball rolling!`
                                        ) : (
                                            `Your dedicated Digital Account Manager, ${clientInfo.ferociousAccountStrategist ? clientInfo.ferociousAccountStrategist.split(' ')[0] : 'Account Manager'}, will personally reach out to introduce themselves and set up a first meeting, in the next few days and get the ball rolling!`
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>


                        <div style={{ background: '#70469c', margin: '0 auto', maxWidth: '550px', padding: '32px', textAlign: 'left' }}>
                            <h3 style={{ fontFamily: "'proxima-nova','Arial',sans-serif", fontWeight: 700, color: '#ffffff', fontSize: '17px', lineHeight: '112%' }}>OTHER QUESTIONS?</h3>
                            <p style={{ fontFamily: "'proxima-nova','Arial',sans-serif", fontWeight: 500, color: '#eaeaea', fontSize: '14px', lineHeight: '150%' }}>
                                We’re here to help. {isPurchasingWebsite ? (
                                    `During your Website Design, your primary point of contact will be your project manager, ${clientInfo.ferociousProjectManager ? clientInfo.ferociousProjectManager.split(' ')[0] : '[Project Manager First Name]'}, and can be reached at ${managerOptions.find(m => m.name === clientInfo.ferociousProjectManager)?.email || '[Project Manager Email]'}. Also - Please always copy ${clientInfo.distro || '[Distro]'} - which is the fastest way to reach your full team at Ferocious, and ensures we can route your inquiries to the appropriate team member.`
                                ) : (
                                    `Your primary point of contact will be your account manager, ${clientInfo.ferociousAccountStrategist ? clientInfo.ferociousAccountStrategist.split(' ')[0] : '[Account Manager First Name]'}, and can be reached at ${strategistOptions.find(s => s.name === clientInfo.ferociousAccountStrategist)?.email || '[Account Manager Email]'}. Please always copy ${clientInfo.distro || '[Distro]'} - which is the fastest way to reach your team at Ferocious, and ensures we can route your inquiries to the appropriate team member.`
                                )}
                            </p>

                            <p style={{ fontFamily: "'proxima-nova','Arial',sans-serif", fontWeight: 400, color: '#eaeaea', fontSize: '14px', lineHeight: '150%' }}>Thank you for choosing Ferocious Media.  On behalf of your team we look forward to serving you and providing stellar service and results for {clientInfo.clientName || "Client Name"}!</p>
                        </div>
                        <div style={{ background: '#eeeeee', margin: '0 auto', maxWidth: '580px', padding: '16px', textAlign: 'center' }}>
                            <img src="https://e.hypermatic.com/069f0cc9822b916d7331f7d0da61a2d4.png" alt="" style={{ width: '72px' }} />
                            <p style={{ fontFamily: "'proxima-nova','Arial',sans-serif", fontWeight: 500, color: '#4d4d4d', fontSize: '13px', lineHeight: '123%' }}>Ferocious Media</p>
                        </div>

                    </div>
                </div>
            </div>
            {showModal && <Modal message={modalMessage} onClose={() => setShowModal(false)} />}
            {showConfirmModal && (
                <ConfirmModal
                    message={confirmMessage}
                    onConfirm={handleConfirmSendEmail}
                    onCancel={handleCancelSendEmail}
                />
            )}
        </>
    );
};

export default EmailComponent;
