import React, { useState, useRef, useEffect } from 'react';
import styles from './CustomDropdown.module.css';

const CustomDropdown = ({ options, value, onChange, placeholder }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(value || placeholder);
    const dropdownRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        onChange(option);
        setIsOpen(false);
    };

    // Check if options is undefined or null
    if (!options) {
        options = []; // Initialize as empty array to prevent crashing
    }

    return (
        <div className={styles.dropdown} ref={dropdownRef}>
            <div className={styles.dropdownHeader} onClick={() => setIsOpen(!isOpen)}>
                {selectedOption}
                <span className={`${styles.arrow} material-icons`}>
                    {isOpen ? 'arrow_drop_up' : 'arrow_drop_down'}
                </span>
            </div>
            {isOpen && (
                <div className={styles.dropdownList}>
                    {options.map((option, index) => (
                        <div
                            key={index}
                            className={styles.dropdownItem}
                            onClick={() => handleOptionClick(option)}
                        >
                            {option}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CustomDropdown;
